import { from } from "rxjs";
import { apiDelete, apiGet } from "./API";
import { apiRootGet, apiRootPost, apiRootPut, apiRootDelete } from "./RootAPI";

const prefix = "/documents";
const prefixTranslate = "/document-requests/";
const rootPrefixMyContainer = `/my-container`;
const rootPrefixContainer = `/container`;
const rootPrefixUsers = `/users`;

export const getDocuments = (project) => {
  return from(
    apiRootGet(`${prefix}`, {
      "container-id": project,
    }).then(async (res) => {
      return res.sort((a, b) => new Date(b.Created) - new Date(a.Created));
    })
  );
};
export const getDocumentsByIds = (project, documentId) => {
  return from(
    apiRootGet(`${prefix}/${documentId}`, {
      "container-id": project,
    }).then(async (res) => {
      return res.sort((a, b) => new Date(b.Created) - new Date(a.Created));
    })
  );
};
export const deleteDocumentsById = (project, documentId) => {
  return from(
    apiRootDelete(`${prefix}/${documentId}`, null, {
      "container-id": project,
    })
  );
};

export const retryDownload = (project, location) => {
  return from(
    apiRootGet(`${location}`, {
      "container-id": project,
    })
  );
};

export const requestDownload = (project, fileId, lang) => {
  return from(
    apiRootPost(
      `${prefixTranslate}download`,
      {
        targetLanguage: lang,
        fileId: fileId,
      },
      {
        //"Content-Type": "application/octet-stream",
        "container-id": project,
      }
    )
  );
};

export const download = (project, fileName, fileUrl) => {
  return from(
    apiRootGet(`${fileUrl}`, {
      "Content-Type": "application/octet-stream",
      "container-id": project,
    }).then(async (res) => {
      // Process the binary data here
      // For example, you can create a download link to download the blob
      const url = window.URL.createObjectURL(res);
      const a = document.createElement("a");
      a.href = url;
      a.download = fileName; // Replace with a suitable file name and extension
      a.style.display = "none";
      document.body.appendChild(a);
      a.click();
      window.URL.revokeObjectURL(url);
      return res;
    })
  );
};

export const getUsers = () => {
  return from(
    apiRootGet(`${rootPrefixUsers}`).then(async (res) => {
      return res;
    })
  );
};
export const getSharedUsers = (projectId) => {
  return from(
    apiRootGet(`${rootPrefixContainer}/${projectId}/shares`).then(
      async (res) => {
        return res;
      }
    )
  );
};
export const addProject = (title, description) => {
  return from(
    apiRootPost(`${rootPrefixContainer}`, {
      title: title,
      description: description,
    }).then(async (res) => {
      return res;
    })
  );
};
export const updateProject = (projectId, title, description) => {
  return from(
    apiRootPut(`${rootPrefixContainer}/${projectId}`, {
      title: title,
      description: description,
    }).then(async (res) => {
      return res;
    })
  );
};
export const removeProject = (projectId) => {
  return from(
    apiRootDelete(`${rootPrefixContainer}/${projectId}`).then(async (res) => {
      return res;
    })
  );
};

export const shareProject = (projectId, userId, permissionId) => {
  return from(
    apiRootPut(`${rootPrefixContainer}/${projectId}/sharing`, {
      users: userId,
      permissionId: permissionId,
    }).then(async (res) => {
      return res;
    })
  );
};
export const unshareProject = (projectId, userId) => {
  return from(
    apiRootPut(`${rootPrefixContainer}/${projectId}/un-sharing`, {
      userId: userId,
    }).then(async (res) => {
      return res;
    })
  );
};

export const getMyProjects = () => {
  return from(
    apiRootGet(`${rootPrefixMyContainer}/search?page=1&size=9999`).then(
      async (res) => {
        return res;
      }
    )
  );
};

export const getSharedProjects = () => {
  return from(
    apiRootGet(`${rootPrefixMyContainer}/share-with-me?page=1&size=9999`).then(
      async (res) => {
        return res;
      }
    )
  );
};

export const setProjectDefault = (projectId) => {
  
  return from(
    apiRootPut(`${rootPrefixContainer}/${projectId}`, {isDefault: true}).then(async (res) => {
      return res;
    })
  );
};
