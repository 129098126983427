// EditModal.js
import React from 'react';
import { Modal, Form, Input } from 'antd';

const EditModal = ({ visible, onCancel, onOk, form, initialValues }) => {
  const handleOk = async () => {
    try {
      await form.validateFields();
      onOk(form.getFieldsValue());
    } catch (error) {
      console.error("Validation Error:", error);
    }
  };
  const validateSpecialCharacters = (_, value) => {
    const regex = /^[a-zA-Z0-9\s]+$/; // Allow alphanumeric characters and spaces
    if (!regex.test(value)) {
      return Promise.reject('String contains invalid characters.');
    }
    return Promise.resolve();
  };
  return (
    <Modal
      title="Edit project"
      visible={visible}
      onCancel={onCancel}
      onOk={handleOk}
      destroyOnClose
    >
      <Form
        form={form}
        layout="vertical"
        name="form_in_modal"
        initialValues={initialValues}
      >
        <Form.Item
          name="title"
          label="Title"
          rules={[{ required: true, message: "Please enter a title" },
          { validator: validateSpecialCharacters }]}
        >
          <Input placeholder="Input a title" />
        </Form.Item>
        <Form.Item
          name="description"
          label="Description"
        >
          <Input.TextArea placeholder="Input a description"
          rules={[
          { validator: validateSpecialCharacters }]} />
        </Form.Item>
      </Form>
    </Modal>
  );
};

export default EditModal;
