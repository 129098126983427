import {
  Row,
  Col,
  Card,
  Table,
  Modal,
  message,
  Button,
  Tag,
  Form,
  Input,
  Select,
  Statistic,
  Tooltip,
  Space,
} from "antd";
import { setProfile } from "../redux/actions/profileActions";
import { getProfile } from "../services/Profile";
import {
  UsergroupAddOutlined,
  UserOutlined,
  MailOutlined,
  DollarOutlined,
  HistoryOutlined,
} from "@ant-design/icons";
import { getTeams, deleteTeam, inviteTeam } from "./../services/Team";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faUsd, faFileText } from "@fortawesome/free-solid-svg-icons";
import { useEffect, useState, useRef, useCallback } from "react";
import dollarIcon from "./../assets/images/dollar.svg";
import dollarSpendIcon from "./../assets/images/dollar-spend.svg";
import moment from "moment";
import { APP_NAME, PageTitle, SLOGAN } from "../auth";
import ic_user from "../assets/icons/ic-team.png";
import { useDispatch, useSelector } from "react-redux";
// Images
import { useHistory } from "react-router-dom";
import { ROLE } from "../common/const";
import { updateBudget } from "../services/User";
import { switchMap } from "rxjs/operators";

function Team() {
  const history = useHistory();
  const [data, setData] = useState();
  const [refresh, setRefresh] = useState(false);
  const [loading, setLoading] = useState(false);
  const [search, setSearch] = useState();
  const timeoutRef = useRef(null);
  const [budget, setBudget] = useState();
  const [currentBudgetChange, setCurrentBudgetChange] = useState();
  const [budgetChangeStt, setBudgetChangeStt] = useState();
  const [valueStyleBudgetUpdate, setValueStyleBudgetUpdate] = useState({
    color: "black",
  });
  const dispatch = useDispatch();
  const profile = useSelector((state) => state.profile);

  const validateRangeNumber = (_, value, min, max, errorMessage) => {
    if (value) {
      const number = Number(value);
      if (isNaN(number) || number < min || number > max) {
        return Promise.reject(new Error(errorMessage));
      }
    }
    return Promise.resolve();
  };

  const validateEmail = (email) => {
    const re =
      /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    return re.test(String(email).toLowerCase());
  };
  const formatBudget = (number) => {
    return number % 1 === 0 ? number.toFixed(0) : number.toFixed(2);
  };

  const handleOnChangeBudget = (e) => {
    if (timeoutRef.current) {
      clearTimeout(timeoutRef.current);
    }
    timeoutRef.current = setTimeout(() => {
      const updateBudget = Number(e.target.value);
      if (updateBudget < 0 || updateBudget > 10000) {
        setCurrentBudgetChange();
        setValueStyleBudgetUpdate({ color: "black" });
        return;
      } else {
        if (updateBudget < budget) {
          setValueStyleBudgetUpdate({ color: "red" });
          setBudgetChangeStt("down");
          setCurrentBudgetChange((budget - updateBudget));
        } else {
          setValueStyleBudgetUpdate({ color: "green" });
          setCurrentBudgetChange((updateBudget - budget));
        }
      }
    }, 800);
  };

  const [current, setCurrent] = useState();
  useEffect(() => {
    setLoading(true);

    const subscription = getTeams(search?.trim()).subscribe((res) => {
      setData(res.items);
      setLoading(false);
      return () => {
        subscription.unsubscribe();
      };
    });
  }, [refresh, search]);

  useEffect(() => {
    document.title = `${PageTitle.User} - ${APP_NAME}`;
  }, []);

  useEffect(() => {
  }, [profile]);

  const sharedColumns = [
    {
      title: "Username",
      dataIndex: "userName",
      key: "userName",
      render: (userName, record) => {
        return (
          userName && (
            <>
              {<UserOutlined />}
              <span> {userName}</span>
              {/**
              <Button
                type="link"
                onClick={() => {
                  editForm.setFieldsValue({ title: record.title });
                  editForm.setFieldsValue({ description: record.description });
                  setEditModalVisible(true);
                }}
                icon={<EditOutlined style={{ color: "gray", fontSize: 11 }} />}
                size={"large"}
                ghost
              ></Button>
               */}
            </>
          )
        );
      },
    },
    {
      title: "Email",
      dataIndex: "email",
      key: "email",
      render: (email, record, index) => {
        return (
          email && (
            <Tag
              color="#1F88E5"
              style={{ cursor: "pointer" }}
              onClick={() => (window.location = `mailto:${email}`)}
            >
              <MailOutlined /> {email}
            </Tag>
          )
        );
      },
    },
    {
      title: "Role",
      dataIndex: "role",
      key: "role",
      render: (role, record, index) => {
        return <Tag>{ROLE[role]}</Tag>;
      },
    },
    {
      title: "Credit",
      dataIndex: "budget",
      key: "budget",
      render: (budget, record) => (
        <span className="money">
          <img
            src={dollarIcon}
            style={{
              width: 18,
            }}
          />
          <span>
            {budget?.toLocaleString(undefined, { 
              minimumFractionDigits: 2, 
              maximumFractionDigits: 2  })}
          </span>
        </span>
      ),
      sorter: (a, b) => a.budget - b.budget,
    },
    {
      title: "Spend",
      dataIndex: "spended",
      key: "spended",
      render: (spended, record) => (
        <span className="money-spend">
          -
          <img
            src={dollarSpendIcon}
            style={{
              width: 18,
            }}
          />
          <span>
            {spended?.toLocaleString(undefined, { 
              minimumFractionDigits: 2, 
              maximumFractionDigits: 2  })}
          </span>
        </span>
      ),
      sorter: (a, b) => a.spended - b.spended,
    },
    {
      title: "Created Date",
      dataIndex: "createdDate",
      key: "createdDate",
      render: (lastModifiedDate, record) => (
        <span>{new Date(lastModifiedDate).toLocaleString()}</span>
      ),
      sorter: (a, b) =>
        moment.utc(a.createdDate).local() - moment.utc(b.createdDate).local(),
    },
    {
      title: "Action",
      key: "action",
      render: (text, record) => (
        <>
          {record.role == 3 && (
            <Button
              type="link"
              className="money"
              onClick={() => {
                setCurrent({ uuid: record.uuid });
                setCurrentBudgetChange();
                setBudget(record.budget);
                setValueStyleBudgetUpdate({ color: "black" });
                setBudgetChangeStt(null);
              }}
            >
              <Tooltip title="BUDGET">
                {<DollarOutlined style={{ fontSize: 20 }} />}
              </Tooltip>
            </Button>
          )}
          <Button
            type="link"
            ghost
            onClick={() =>
              history.push(`/user/history/team/${record.uuid}/${record.email}`)
            }
          >
            <Tooltip title="HISTORY">
              {<HistoryOutlined style={{ fontSize: 19 }} />}
            </Tooltip>
          </Button>
          {/* <Button
            type="link"
            danger
            onClick={() => handleConfirm(record.uuid, record)}
          >
            <Tooltip title="DELETE">
              {<img src={deleteIcon} width="25" />}
            </Tooltip>
          </Button> */}
        </>
      ),
    },
  ];
  useEffect(() => {
    if (current?.uuid !== undefined) {
      setEditBudgetModalVisible(true);
      editBudgetForm.setFieldsValue({ budget: current.budget });
    }
  }, [current]);

  const handleConfirm = (id, record) => {
    Modal.confirm({
      title: "Delete Project",
      content: `Are you sure you want to delete '${record.title}' user from your team?`,
      onOk: async () => {
        const subscription = deleteTeam(id).subscribe((res) => {
          if (!data?.message) {
            setRefresh(!refresh);
            message.success("delete successful!");
            setLoading(false);
            return () => {
              subscription.unsubscribe();
            };
          }
        });
      },
      onCancel: () => {
        // Handle cancellation if needed
        console.log("Action canceled");
      },
    });
  };

  const [form] = Form.useForm();
  const [editForm] = Form.useForm();
  const [editBudgetForm] = Form.useForm();

  const handleAddOk = async () => {
    try {
      await form.validateFields();
      onSubmit(form.getFieldsValue());
    } catch (error) {
      console.error("Validation Error:", error);
    }
  };
  const [editModalVisible, setEditModalVisible] = useState(false);

  const handleEditOk = async () => {
    try {
      await editForm.validateFields();
      onSubmit(editForm.getFieldsValue());
    } catch (error) {
      console.error("Validation Error:", error);
    }
  };

  const handleEditCancel = () => {
    setEditModalVisible(false);
  };
  const [editBudgetModalVisible, setEditBudgetModalVisible] = useState(false);

  const handleEditBudgetOk = async () => {
    try {
      await editBudgetForm.validateFields();
      onBudgetSubmit(editBudgetForm.getFieldsValue());
    } catch (error) {
      console.error("Validation Error:", error);
    }
  };

  const onBudgetSubmit = (formValues) => {
    let currentChange = currentBudgetChange;
    if (budgetChangeStt == "down") {
      currentChange = -currentChange;
    }

    const subscription = updateBudget(current.uuid, currentChange)
      .pipe(
        switchMap((res) => {
          if (!res?.message) {
            message.success("Add budget successful!");
            editBudgetForm.resetFields();
            setBudget(0);
            setRefresh(!refresh);
            setEditBudgetModalVisible(false);
            setLoading(false);
            return getProfile(); // Chain the getProfile observable
          } 
        })
      )
      .subscribe(
        (profileRes) => {
          if (profileRes.uuid) {
            dispatch(setProfile(profileRes));
          } else {
            alert("Something went wrong!");
          }
        },
      );

    // Make sure to unsubscribe to avoid memory leaks
    return () => {
      subscription.unsubscribe();
    };

    // const subscription = updateBudget(current.uuid, currentChange).subscribe(
    //   (res) => {
    //     if (!res?.message) {
    //       message.success("Add budget successful!");
    //       editBudgetForm.resetFields();
    //       setBudget(0);
    //       setRefresh(!refresh);
    //       setEditBudgetModalVisible(false);
    //       setLoading(false);
    //       return () => {
    //         subscription.unsubscribe();
    //       };
    //     }

    //   }
    // );
    // const profileSubscription = getProfile().subscribe((res) => {
    //   if (res.uuid) {
    //     dispatch(setProfile(res));
    //   } else {
    //     alert("something wrong happen!");
    //   }
    // });
  };

  const handleEditBudgetCancel = () => {
    editBudgetForm.resetFields();
    setBudget(0);
    setCurrent();
    setEditBudgetModalVisible(false);
  };

  const onSubmit = (formValues) => {
    const subscription = inviteTeam(formValues.emails).subscribe((res) => {
      if (!res?.message) {
        message.success("Invite user successfully!");
        form.resetFields();
        setRefresh(!refresh);
        setInviteModalVisible(false);
        setLoading(false);
        return () => {
          subscription.unsubscribe();
        };
      }
    });
  };

  const [inviteModalVisible, setInviteModalVisible] = useState(false);
  const handleInviteCancel = () => {
    setInviteModalVisible(false);
  };

  const handleInviteSubmit = (values) => {
    setInviteModalVisible(false);
  };
  const handleEditSubmit = (values) => {
    setEditModalVisible(false);
  };
  const handleKeyPress = (e) => {
    if (e.key === "Enter") {
      setSearch(e.target.value);
    }
  };
  return (
    <>
      <Card
        className="tabled"
        title={
          <>
            {<img src={ic_user} width={30} />} <b>{PageTitle.User}</b>
          </>
        }
        extra={
          <Space>
            <Input.Search
              placeholder=""
              size="small"
              onPressEnter={handleKeyPress}
              onSearch={(value) => setSearch(value)}
            />

            <Button
              size={"small"}
              type="primary"
              icon={<UsergroupAddOutlined style={{ fontSize: 15 }} />}
              onClick={() => setInviteModalVisible(true)}
            >
              Invite
            </Button>
          </Space>
        }
      >
        <Row gutter={[24, 0]} style={{ margin: 0 }}>
          <Col xs="24" xl={24}>
            <Row gutter={[24, 0]}>
              <Col xs="24" xl={24}>
                <div className="table-responsive">
                  {data && (
                    <Table
                      rowKey={"id"}
                      columns={sharedColumns}
                      dataSource={data}
                      className="ant-border-space"
                      pagination={{
                        defaultPageSize: 10,
                        showSizeChanger: true,
                        total: data.length,
                        showTotal: (total, [x, y]) => (
                          <span>{`${x} - ${y} of ${total}`}</span>
                        ),
                      }}
                      loading={loading}
                    />
                  )}
                </div>
              </Col>
            </Row>
          </Col>
        </Row>
      </Card>
      <Modal
        title="Invite User"
        visible={inviteModalVisible}
        onCancel={handleInviteCancel}
        onOk={handleAddOk}
      >
        <Form onFinish={handleInviteSubmit} layout="vertical" form={form}>
          {/**
          <Form.Item
            name="username"
            label="Username"
            rules={[{ required: true, message: "Please enter the username" }]}
          >
            <Input />
          </Form.Item>
           */}
          <Form.Item
            label={"Emails"}
            name="emails"
            rules={[
              {
                message: "At least one email required.",
                type: "array",
                validator: (_, value) => {
                  if (!value) {
                    return Promise.reject("At least one email required."); // Custom error message for invalid emails
                  }
                  const emailArray = value.map((email) => email.trim());
                  const invalidEmails = emailArray.filter(
                    (email) => !validateEmail(email)
                  );
                  if (invalidEmails.length > 0) {
                    return Promise.reject("Some emails are not valid"); // Custom error message for invalid emails
                  }
                  if (new Set(emailArray).size !== emailArray.length) {
                    return Promise.reject("Duplicate emails."); // Custom error message for invalid emails
                  }
                  return Promise.resolve();
                },
              },
            ]}
          >
            <Select
              placeholder="Input your emails separeted by semicolon."
              mode="tags"
              tokenSeparators={[";"]}
            />
          </Form.Item>
          {/**
          <Form.Item
            name="password"
            label="Password"
            rules={[{ required: true, message: "Please enter the password" }]}
          >
            <Input.Password />
          </Form.Item>
          <Form.Item
            name="confirmPassword"
            label="Confirm Password"
            dependencies={["password"]}
            rules={[
              { required: true, message: "Please confirm your password" },
              ({ getFieldValue }) => ({
                validator(_, value) {
                  if (!value || getFieldValue("password") === value) {
                    return Promise.resolve();
                  }
                  return Promise.reject("The two passwords do not match");
                },
              }),
            ]}
          >
            <Input.Password />
          </Form.Item>
           */}
        </Form>
      </Modal>

      <Modal
        title="Edit User"
        visible={editModalVisible}
        onCancel={handleEditCancel}
        onOk={handleEditOk}
      >
        <Form onFinish={handleEditSubmit} layout="vertical" form={editForm}>
          <Form.Item
            name="username"
            label="Username"
            rules={[{ required: true, message: "Please enter the username" }]}
          >
            <Input />
          </Form.Item>
        </Form>
      </Modal>

      <Modal
        title={
          <>
            <DollarOutlined /> Update Credit
          </>
        }
        visible={editBudgetModalVisible}
        onCancel={handleEditBudgetCancel}
        onOk={handleEditBudgetOk}
        okButtonProps={{
          disabled: currentBudgetChange == 0 || !currentBudgetChange,
        }}
        okText={"Update"}
      >
        <Form
          //onFinish={handleEditBudgetSubmit}
          layout="vertical"
          form={editBudgetForm}
          initialValues={current}
        >
          <Row gutter={[20, 20]}>
            <Col span={12}>
              <Form.Item
                name="budget"
                label={
                  <>
                    <FontAwesomeIcon icon={faUsd} /> Budget
                  </>
                }
                rules={[
                  { required: true, message: "Please input budget" },
                  {
                    validator: (rule, value) =>
                      validateRangeNumber(
                        rule,
                        value,
                        0,
                        10000,
                        "Budget must be between 0 and 10000!"
                      ),
                  },
                ]}
              >
                <Input
                  prefix="$"
                  defaultValue={null}
                  formatter={(value) => `$ ${(+value).toLocaleString()}`}
                  parser={(value) => value.replace(/\$\s?|,/g, "")}
                  style={{
                    width: "100%",
                    margin: 0,
                    padding: "0 8px",
                    borderRadius: "8px",
                  }}
                  className={"money"}
                  onChange={(e) => handleOnChangeBudget(e, budget)}
                  onKeyPress={(e) => {
                    if (!/^\d$/.test(e.key)) {
                      e.preventDefault();
                    }
                  }}
                  min={0}
                  max={10000}
                />
              </Form.Item>
            </Col>
            <Col span={12}>
              <Statistic
                valueStyle={valueStyleBudgetUpdate}
                prefix={`${
                  valueStyleBudgetUpdate.color == "red" ? "-" : "+"
                } $`}
                title={<div style={{ height: "24px" }} />}
                value={formatBudget(currentBudgetChange ?? 0)}
              />
            </Col>
          </Row>
        </Form>
      </Modal>
    </>
  );
}

export default Team;