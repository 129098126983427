import {
  Row,
  Col,
  Card,
  Table,
  Modal,
  Badge,
  message,
  Button,
  Select,
  Tag,
  Form,
  Input,
  Avatar,
  Tooltip,
  List,
} from "antd";
import moment from "moment";

import {
  UsergroupAddOutlined,
  UserOutlined,
  ApiOutlined,
  DownloadOutlined,
  UploadOutlined,
  ForwardOutlined,
  SyncOutlined,
  GlobalOutlined,
  InfoCircleOutlined,
  EyeOutlined,
  CloudDownloadOutlined,
  CloudUploadOutlined,
  DatabaseOutlined,
} from "@ant-design/icons";
import {
  getSharedProjects,
  getDocuments,
  getUsers,
  shareProject,
  unshareProject,
  getSharedUsers,
  addProject,
  requestDownload,
  download,
  retryDownload,
} from "../services/Project";
import projectIcon from "./../assets/images/project.svg";
import shareIcon from "./../assets/images/share.svg";
import { useEffect, useState } from "react";
import { STATUS, EXTENSION_ICONS, COUNTRIES } from "./../common/const";
import { formatBytes } from "./../common/util";
import dollarIcon from "./../assets/images/dollar.svg";
import expiredIcon from "./../assets/images/expired.png";
import { APP_NAME, PageTitle, SLOGAN } from "../auth";
import ic_shared from "../assets/icons/ic-share.png"
function Shared() {
  const [data, setData] = useState();
  const [refresh, setRefresh] = useState(false);
  const [loading, setLoading] = useState(false);
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [selectedRecord, setSelectedRecord] = useState(null);

  const handleOk = () => {
    if (selectedUser && selectedRecord) {
      share(selectedRecord.id, selectedUser);
    } else {
      message.error("Please select user to share.");
    }
  };

  const handleCancel = () => {
    setIsModalVisible(false);
    setSelectedRecord();
    setSelectedUser();
    setSelectedPermission();
  };

  useEffect(() => {
    setLoading(true);

    const subscription = getSharedProjects().subscribe((res) => {
      setData(
        res.items.sort(function (a, b) {
          return new Date(b.lastModified) - new Date(a.lastModified);
        })
      );
      setLoading(false);
      return () => {
        subscription.unsubscribe();
      };
    });
  }, [refresh]);

  const [users, setUsers] = useState();
  useEffect(() => {
    setLoading(true);

    const subscription = getUsers().subscribe((data) => {
      setUsers(data);
      setLoading(false);
      return () => {
        subscription.unsubscribe();
      };
    });
  }, [refresh]);

  useEffect(() => {
    document.title = `${PageTitle.Share} - ${APP_NAME}`;
  }, []); 

  const sharedColumns = [
    {
      title: "Project",
      dataIndex: "title",
      key: "title",
      render: (title) => {
        return (
          title && (
            <>
              {<img src={projectIcon} width={30} />}
              <span> {title}</span>
            </>
          )
        );
      },
    },
    {
      title: "Owner",
      dataIndex: "ownerInfo",
      key: "ownerInfo",
      render: (ownerInfo, record, index) => {
        return (
          <Tag color="default">
            <UserOutlined /> {ownerInfo?.username}
          </Tag>
        );
      },
    },
    {
      title: () => { return <>Cost <Tooltip title={"Total cost of your own translation"}><InfoCircleOutlined style={{fontSize:12,verticalAlign: "text-top",
        color: "#ffcd00"}}/></Tooltip></>},
      dataIndex: "translationCharged",
      key: "translationCharged",
      render: (translationCharged, record) => (
        <Tag color="lime">
          <span className="money">
            <img
              src={dollarIcon}
              style={{
                width: 15,
              }}
            />
            <span>
              {translationCharged>0?translationCharged:0}
            </span>
          </span>
        </Tag>
      ),
    },
    {
      title: "Storage",
      dataIndex: "containerSize",
      key: "containerSize",
      render: (containerSize, record) => (
        <Tag>
          {formatBytes(containerSize, 1) + " "}
          <DatabaseOutlined />
        </Tag>
      ),
    },
    {
      title: "Permission",
      dataIndex: "permissionId",
      key: "permissionId",
      render: (permissionId, record, index) => {
        return (
          <Tag
            color={
              permissionId === 15
                ? "Owner"
                : permissionId === 1
                ? "gray"
                : "#faad14"
            }
          >
            {permissionId === 15 ? (
              "Owner"
            ) : permissionId === 1 ? (
              <>
                <EyeOutlined /> Viewer
              </>
            ) : (
              <>
                <CloudUploadOutlined /> Editor
              </>
            )}
          </Tag>
        );
      },
    },
    {
      title: "Updated",
      dataIndex: "lastModified",
      key: "lastModified",
      render: (lastModified, record) => (
        <span>{moment.utc(lastModified).local().fromNow()}</span>
      ),
    },
  ];

  const share = (id, user) => {
    const subscription = shareProject(
      id,
      user,
      parseInt(selectedPermission, 10)
    ).subscribe((data) => {
      if(!data?.message)
      {
        message.success("Shared Successful!");
        setLoading(false);
        setShareLoading(!shareLoading);
        return () => {
          subscription.unsubscribe();
        };
      }
    });
  };
  const [shareLoading, setShareLoading] = useState();
  const unShare = (id, user) => {
    const subscription = unshareProject(id, user).subscribe((data) => {
      if(!data?.message)
      {
      message.success("Un-Shared Successful!");
      setLoading(false);
      setShareLoading(!shareLoading);
      return () => {
        subscription.unsubscribe();
      };}
    });
  };
  const [listShared, setListShared] = useState();
  const [selectedPrj, setSelectedPrj] = useState();
  const [selectedPrjId, setSelectedPrjId] = useState();
  useEffect(() => {
    if (selectedPrjId) {
      const subscription = getSharedUsers(selectedPrjId).subscribe((data) => {
        setListShared(data);
        setSelectedRecord(selectedPrj);
        setIsModalVisible(true);
        setLoading(false);
        return () => {
          subscription.unsubscribe();
        };
      });
    }
  }, [shareLoading]);

  const [selectedUser, setSelectedUser] = useState();
  const [selectedPermission, setSelectedPermission] = useState();

  const [form] = Form.useForm();

  const handleAddOk = async () => {
    try {
      await form.validateFields();
      onSubmit(form.getFieldsValue());
    } catch (error) {
      console.error("Validation Error:", error);
    }
  };
  const [modalVisible, setModalVisible] = useState(false);

  const handleAddCancel = () => {
    setModalVisible(false);
  };

  const onSubmit = (formValues) => {
    const subscription = addProject(
      formValues.title,
      formValues.description
    ).subscribe((data) => {
      if(!data?.message)
      {
        message.success("Create successful!");
        form.resetFields();
        setModalVisible(false);
        setRefresh(!refresh);
        setLoading(false);
        return () => {
          subscription.unsubscribe();
        };
      }
    });
  };
  const [expandedRows, setExpandedRows] = useState([]);

  const handleRowClick = (record) => {
    const isRowExpanded = expandedRows.includes(record.id);
    const newExpandedRows = isRowExpanded
      ? expandedRows.filter((rowKey) => rowKey !== record.id)
      : [record.id];
    setSelectedProject(newExpandedRows[0]);
    setExpandedRows(newExpandedRows);
  };

  const [selectedProject, setSelectedProject] = useState();
  const [subData, setSubData] = useState();
  useEffect(() => {
    if (selectedProject) {
      setLoading(true);

      const subscription = getDocuments(selectedProject).subscribe((res) => {
        setSubData(
          res.sort(function (a, b) {
            return new Date(b.Created) - new Date(a.Created);
          })
        );
        setLoading(false);
        return () => {
          subscription.unsubscribe();
        };
      });
    }
  }, [selectedProject]);

  const handleDownload = (fileId, lang) => {
    setLoading(true);
    const subscription = requestDownload(
      selectedProject,
      fileId,
      lang
    ).subscribe((data) => {
      if(data?.fileUrl)
        {
          downloadWithFilename(data.fileUrl, data.fileName);
          //downloadBlob(data.fileUrl);
        }
        
        setLoading(false);
        return () => {
          subscription.unsubscribe();
        };
    });
  };
  
  function downloadWithFilename(url, filename) {
    var link = document.createElement("a");
    link.setAttribute("download", filename);
    link.href = url;
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
}

  const finalDownload = (fileName, fileUrl) => {
    setLoading(true);
    const subscription = download(selectedProject, fileName, fileUrl).subscribe(
      (subdata) => {
        setLoading(false);
        return () => {
          subscription.unsubscribe();
        };
      }
    );
  };
  return (
    <>
      <Card
        className="tabled"
        title={
          <>
            {<img src={ic_shared} width={30} />}{" "}
            <b>
              Shared with me{" "}
            </b>
          </>
        }
      >
        <Row gutter={[24, 0]} style={{ margin: 0 }}>
          <Col xs="24" xl={24}>
            <Row gutter={[24, 0]}>
              <Col xs="24" xl={24}>
                <div className="table-responsive">
                  {data && (
                    <Table
                      rowKey={"id"}
                      columns={sharedColumns}
                      dataSource={data}
                      className="ant-border-space"
                      pagination={{
                        defaultPageSize: 10,
                        showSizeChanger: true,
                        total: data.length,
                        showTotal: (total, [x,y]) => <span>{`${x} - ${y} of ${total}`}</span>
                      }}
                      loading={loading}
                      expandedRowRender={(record) => {
                        return (
                          subData && (
                            <List
                              itemLayout="horizontal"
                              dataSource={subData}
                              renderItem={(item, index) => (
                                <List.Item
                                  extra={
                                    <>
                                      {Object.keys(COUNTRIES.translation).map(
                                        (key) => {
                                          const val =
                                            COUNTRIES.translation[key];
                                          if (key === item.language) {
                                            return (
                                              <Tag className="language-tag">
                                                {val.icon ? (
                                                  <div className={val.icon} />
                                                ) : (
                                                  <div className="flag-icon">
                                                    <GlobalOutlined
                                                      style={{
                                                        fontSize: 40,
                                                        width: 90,
                                                      }}
                                                    />
                                                  </div>
                                                )}{" "}
                                                {
                                                  COUNTRIES.translation[key]
                                                    .name
                                                }
                                              </Tag>
                                            );
                                          }
                                        }
                                      )}
                                      <ForwardOutlined />
                                      {item.translations.length === 0 && (
                                        <div
                                          style={{
                                            paddingLeft: 5,
                                          }}
                                        >
                                          <SyncOutlined
                                            spin
                                            style={{ color: "gray" }}
                                          />
                                          &nbsp;
                                          <Tag className="language-tag">
                                            <GlobalOutlined />
                                          </Tag>
                                          <Button
                                            type={"link"}
                                            disabled={true}
                                            style={{
                                              flex: "right",
                                              padding: 0,
                                            }}
                                          >
                                            <CloudDownloadOutlined
                                              style={{
                                                fontSize: 17,
                                              }}
                                            />
                                          </Button>
                                        </div>
                                      )}
                                      {item.translations.length !== 0 && (
                                        <div
                                          style={{
                                            paddingLeft: 5,
                                          }}
                                        >
                                          {item.translations &&
                                            item.translations.map((subitem) => {
                                              const val =
                                                COUNTRIES.translation[
                                                  subitem.language
                                                ];
                                              return (
                                                <p>
                                                  {
                                                    STATUS[
                                                      subitem.status.toLowerCase()
                                                    ].icon
                                                  }
                                                  &nbsp;
                                                  <Tag
                                                    color={
                                                      STATUS[
                                                        subitem.status.toLowerCase()
                                                      ].color
                                                    }
                                                    className="language-tag"
                                                  >
                                                    {val.icon ? (
                                                      <div
                                                        className={val.icon}
                                                      />
                                                    ) : (
                                                      <div className="flag-icon">
                                                        <GlobalOutlined
                                                          style={{
                                                            fontSize: 40,
                                                            width: 90,
                                                          }}
                                                        />
                                                      </div>
                                                    )}{" "}
                                                    {val.name}
                                                  </Tag>
                                                  <Button
                                                    type={"link"}
                                                    disabled={
                                                      subitem.status.toLowerCase() !==
                                                      "succeeded"
                                                    }
                                                    onClick={() =>
                                                      handleDownload(
                                                        item.fileId,
                                                        subitem.language
                                                      )
                                                    }
                                                    style={{
                                                      flex: "right",
                                                      padding: 0,
                                                    }}
                                                  >
                                                    <CloudDownloadOutlined
                                                      style={{
                                                        fontSize: 17,
                                                      }}
                                                    />
                                                  </Button>
                                                  <br />
                                                </p>
                                              );
                                            })}
                                        </div>
                                      )}
                                    </>
                                  }
                                >
                                  <List.Item.Meta
                                    avatar={
                                      <img
                                        width={50}
                                        src={
                                          EXTENSION_ICONS[
                                            item.fileName.slice(
                                              ((item.fileName.lastIndexOf(".") -
                                                1) >>>
                                                0) +
                                                2
                                            )
                                          ]
                                        }
                                      />
                                    }
                                    title={
                                      <>
                                        {STATUS[item.translations.status]}
                                        <span> {item.fileName}</span>
                                      </>
                                    }
                                    description={
                                      <>
                                        <span>
                                          {moment
                                            .utc(item.createdDate)
                                            .local()
                                            .fromNow()}
                                        </span>
                                        &nbsp;
                                        <Tag>
                                          {formatBytes(item.length, 1) + " "}
                                          <DatabaseOutlined />
                                        </Tag>
                                        <Tag color="volcano">
                                          {item.characterCharge.toLocaleString() +
                                            " "}
                                          Chars
                                        </Tag>
                                        <Tag color="lime">
                                          <span className="money">
                                            <img
                                              src={dollarIcon}
                                              style={{
                                                width: 15,
                                              }}
                                            />
                                            <span>
                                              {item.translationCharge}
                                            </span>
                                          </span>
                                        </Tag>
                                        <Tooltip
                                          title={`expired date: ${moment
                                            .utc(item.expiredDate)
                                            .local()
                                            .toLocaleString()}`}
                                        >
                                          <img
                                            src={expiredIcon}
                                            style={{
                                              width: 15,
                                            }}
                                          />{" "}
                                          <span
                                            style={{
                                              color: "#C1666E",
                                            }}
                                          >
                                            {moment
                                              .utc(item.expiredDate)
                                              .local()
                                              .fromNow()}
                                          </span>
                                        </Tooltip>
                                      </>
                                    }
                                  />
                                </List.Item>
                              )}
                            />
                          )
                        );
                      }}
                      expandedRowKeys={expandedRows}
                      onExpand={(expanded, record) => handleRowClick(record)}
                    />
                  )}
                </div>
              </Col>
            </Row>
          </Col>
        </Row>
        <Modal
          title={
            <>
              <svg
                fill="#000000"
                height="15px"
                width="15px"
                version="1.1"
                id="Layer_1"
                xmlns="http://www.w3.org/2000/svg"
                viewBox="0 0 512 512"
              >
                <g>
                  <g>
                    <path
                      d="M418.056,162.056c-30.616,0-57.736,14.712-74.88,37.384L184.512,118.6c2.136-7.872,3.376-16.104,3.376-24.656
             C187.888,42.064,145.824,0,93.944,0S0,42.064,0,93.944c0,51.88,42.064,93.944,93.944,93.944c31.912,0,60.056-15.944,77.04-40.264
             l157.44,80.216c-2.792,8.888-4.304,18.344-4.304,28.16c0,9.816,1.512,19.272,4.304,28.16l-157.44,80.224
             c-16.976-24.32-45.128-40.272-77.04-40.272C42.064,324.112,0,366.176,0,418.056S42.064,512,93.944,512
             s93.944-42.064,93.944-93.944c0-8.544-1.24-16.784-3.376-24.648l158.664-80.848c17.152,22.672,44.264,37.384,74.88,37.384
             C469.936,349.944,512,307.88,512,256C512,204.112,469.936,162.056,418.056,162.056z M93.944,155.888
             C59.784,155.888,32,128.096,32,93.944C32,59.784,59.784,32,93.944,32c34.152,0,61.944,27.784,61.944,61.944
             C155.888,128.096,128.096,155.888,93.944,155.888z M93.944,480C59.784,480,32,452.216,32,418.056
             c0-34.152,27.792-61.944,61.944-61.944c34.152,0,61.944,27.792,61.944,61.944C155.888,452.216,128.096,480,93.944,480z
              M418.056,317.944c-34.152,0-61.944-27.792-61.944-61.944c0-34.16,27.792-61.944,61.944-61.944
             C452.216,194.056,480,221.84,480,256C480,290.152,452.216,317.944,418.056,317.944z"
                    />
                  </g>
                </g>
              </svg>{" "}
              Share your project
              <p style={{ margin: 0 }}>
                <small>Manage users can access to your project</small>
              </p>
            </>
          }
          visible={isModalVisible}
          onCancel={handleCancel}
          footer={
            <>
              <h4>Recipients</h4>
              <List
                style={{ textAlign: "left" }}
                bordered
                itemLayout="horizontal"
                size="default"
              >
                {listShared &&
                  listShared.map((item) => {
                    return (
                      <List.Item
                        key={item.title}
                        extra={
                          <Button
                            type="link"
                            danger
                            size="small"
                            onClick={() =>
                              unShare(selectedRecord.id, item.userId)
                            }
                            icon={<ApiOutlined />}
                          >
                            Un-share
                          </Button>
                        }
                      >
                        <List.Item.Meta
                          avatar={
                            <Avatar
                              style={{
                                backgroundColor: "#87d068",
                              }}
                            >
                              {item.username.toUpperCase().charAt(0)}
                            </Avatar>
                          }
                          title={item.username}
                          description={
                            item.permissionId === 1 ? (
                              <span style={{color: "gray"}}><EyeOutlined /> Viewer</span>
                            ) : (
                              <span style={{color: "#faad14"}}><CloudUploadOutlined /> Editor</span>
                            )
                          }
                        />
                      </List.Item>
                    );
                  })}
              </List>
            </>
          }
        >
          <h4>Select recipients</h4>
          <Row gutter={[5, 5]}>
            <Col span={10}>
              <Select
                style={{ width: "100%" }}
                onChange={(val) => setSelectedUser(val)}
                suffixIcon={<UserOutlined />}
              >
                {users &&
                  users.map((item) => {
                    return (
                      <Select.Option key={item.id} value={item.id}>
                        <UserOutlined /> {item.userName}
                      </Select.Option>
                    );
                  })}
              </Select>
            </Col>
            <Col span={8}>
              <Select
                style={{ width: "100%" }}
                onChange={(val) => setSelectedPermission(val)}
              >
                <Select.Option key={"1"} value={"1"} style={{ color: "green" }}>
                  <DownloadOutlined /> Download
                </Select.Option>
                <Select.Option key={"7"} value={"7"} style={{ color: "red" }}>
                  <UploadOutlined /> Upload
                </Select.Option>
              </Select>
            </Col>
            <Col span={6}>
              <Button
                block
                onClick={handleOk}
                type="primary"
                icon={<UsergroupAddOutlined />}
                style={{ height: 32, lineHeight: "20px" }}
              >
                Share
              </Button>
            </Col>
          </Row>
        </Modal>
      </Card>
      <Modal
        title="Create new project"
        visible={modalVisible}
        onCancel={handleAddCancel}
        onOk={handleAddOk}
        destroyOnClose
      >
        <Form form={form} layout="vertical" name="form_in_modal">
          <Form.Item
            name="title"
            label="Title"
            rules={[{ required: true, message: "Please enter a title" }]}
          >
            <Input placeholder="Input a title" />
          </Form.Item>
          <Form.Item
            name="description"
            label="Description"
            rules={[{ required: true, message: "Please enter a description" }]}
          >
            <Input.TextArea placeholder="Input a description" />
          </Form.Item>
        </Form>
      </Modal>
    </>
  );
}

export default Shared;
