import { from } from "rxjs";
import { apiRootGet, apiRootPost, apiRootPut, apiRootDelete } from "./RootAPI";

const rootPrefixTeam = `/my-team/users`;
const rootPrefixCredit = `/credits`;
const rootPrefixCreditHistory = `/credit-logs`;

export const getTeams = (keyword) => {
  return from(
    apiRootGet(`${rootPrefixTeam}/search?page=1&size=99999${keyword?'&keyword='+ keyword:""}`).then(
      async (res) => {
        return res;
      }
    )
  );
};


export const deleteTeam = (id) => {
  return from(
    apiRootDelete(`${rootPrefixTeam}/${id}`).then(async (res) => {
      return res;
    })
  );
};

export const inviteTeam = (emails) => {
  return from(
    apiRootPost(`${rootPrefixTeam}/invite`, emails).then(async (res) => {
      return res;
    })
  );
};

export const updateCredit = (id, formValues) => {
  return from(
    apiRootPost(`${rootPrefixTeam}/${id}${rootPrefixCredit}`, formValues).then(async (res) => {
      return res;
    })
  );
};

export const updateBudget = (id, budget) => {
  const bodySubmit = {
    Budget: budget
  }
  return from(
    apiRootPost(`${rootPrefixTeam}/${id}${rootPrefixCredit}`, bodySubmit).then(async (res) => {
      return res;
    })
  );
};

export const getTeamCreditHistory = (id) => {
  return from(
    apiRootGet(`${rootPrefixTeam}/${id}${rootPrefixCreditHistory}`).then(async (res) => {
      return res;
    })
  );
};