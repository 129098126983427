import { from } from "rxjs";
import { apiRootGet, apiRootPost, apiRootPut, apiRootDelete } from "./RootAPI";

const rootPrefixAdmin = `/admins`;
const rootPrefixBudget = `/credits`;
const rootPrefixBudgetHistory = `/credit-logs`;
const rootPrefixBu = `/business-units`;

export const getUsers = (search) => {
  return from(
    apiRootGet(`${rootPrefixAdmin}/users/search?page=1&size=99999${search?'&keyword='+ search:""}`).then(
      async (res) => {
        return res;
      }
    )
  );
};
export const resetPass = (id) => {
  return from(
    apiRootPut(`${rootPrefixAdmin}/users/${id}/reset-password`).then(
      async (res) => {
        return res;
      }
    )
  );
};

export const addProUser = (emails, fsu) => {
  return from(
    apiRootPost(`${rootPrefixAdmin}/add-new-user-pro?fsu=${fsu}`, emails).then(
      async (res) => {
        return res;
      }
    )
  );
};

export const addNewUser = (emails, department, role) => {
  const url = `${rootPrefixAdmin}/add-new-user-pro?${department ? `fsu=${department}&` : ''}role=${role}`;

  return from(
    apiRootPost(url, emails).then(async (res) => {
      return res;
    })
  );
};

export const updateProUser = (id, fsu) => {
  return from(
    apiRootPut(`${rootPrefixAdmin}/users/fsu`, {
      userId:id,
      fsu:fsu
    }).then(
      async (res) => {
        return res;
      }
    )
  );
};

export const updateUser = (id, role, fsu) => {
  const payload = {
    userId: id,
    role: role,
    ...(fsu && { fsu: fsu }), 
  };

  return from(
    apiRootPut(`${rootPrefixAdmin}/users/fsu`, payload).then(async (res) => {
      return res;
    })
  );
};

export const updateBudget = (id, budget) => {
  const bodySubmit = {
    Budget: budget
  }
  return from(
    apiRootPost(`${rootPrefixAdmin}/users/${id}${rootPrefixBudget}`, bodySubmit).then(async (res) => {
      return res;
    })
  );
};

export const getUserCreditHistory = (id) => {
  return from(
    apiRootGet(`${rootPrefixAdmin}/users/${id}${rootPrefixBudgetHistory}`).then(async (res) => {
      return res;
    })
  );
};


export const getBU = (keyword = "") => {
  return from(
    apiRootGet(`${rootPrefixAdmin}${rootPrefixBu}/search?keyword=${keyword}&page=1&size=9999`).then(
      async (res) => {
        return res;
      }
    )
  );
};

export const addBU = ({code, title, description}) => {
  return from(
    apiRootPost(`${rootPrefixAdmin}${rootPrefixBu}`,{
      code:code,
      title:title,
      description:description
    }).then(
      async (res) => {
        return res;
      }
    )
  );
};

export const updateBU = ({id, code, title, description}) => {
  return from(
    apiRootPut(`${rootPrefixAdmin}${rootPrefixBu}/${id}`,{
      code:code,
      title:title,
      description:description
    }).then(
      async (res) => {
        return res;
      }
    )
  );
};

export const removeBU = (id) => {
  return from(
    apiRootDelete(`${rootPrefixAdmin}${rootPrefixBu}/${id}`).then(
      async (res) => {
        return res;
      }
    )
  );
};