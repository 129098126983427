import { combineReducers } from "redux";
import profileReducer from "./profileReducer"; // Import your individual reducers here

const rootReducer = combineReducers({
  // Add your individual reducers here
  profile: profileReducer,
  // Add other reducers as needed
});

export default rootReducer;
