import { from } from "rxjs";
import { apiRootGet } from "./RootAPI";

export const getProfile = () => {
  return from(
    apiRootGet(`/me/profile`).then(async (res) => {
      return res;
    })
  );
};

export const getSummary = () => {
  return from(
    apiRootGet(`/me/translation-costs/summary`).then(async (res) => {
      return res;
    })
  );
};
