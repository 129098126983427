import { Menu, Divider } from "antd";
import { NavLink, useLocation } from "react-router-dom";
import fptLogo from "../../assets/images/dxg.png";
import projectIcon from "../../assets/images/project.svg";
import shareIcon from "../../assets/images/share.svg";
//import translationIcon from "../../assets/images/translation.svg";
import docTranslateIcon from "../../assets/images/translation.svg";
import translationIcon from "../../assets/images/document.png";
import usersIcon from "../../assets/images/users.svg";
import trashIcon from "../../assets/images/trash.svg";
import buIcon from "../../assets/images/bu.svg";
import bugsIcon from "../../assets/images/bugs.svg";
import { useHistory } from "react-router-dom";
import { useState } from "react";

import dxg_logo from "../../assets/icons/dxg-logo.svg"

import ic_text_translator from "../../assets/icons/ic-text-translator.png"
import ic_text_translator_default from "../../assets/icons/ic-text-translator-default.png"

import ic_trash from "../../assets/icons/ic-trash.png"
import ic_trash_default from "../../assets/icons/ic-trash-default.png"

import ic_team from "../../assets/icons/ic-team.png"
import ic_team_default from "../../assets/icons/ic-team-default.png"

import ic_share from "../../assets/icons/ic-share.png"
import ic_share_default from "../../assets/icons/ic-share-default.png"

import ic_my_projects from "../../assets/icons/ic-my-projects.png"
import ic_my_projects_default from "../../assets/icons/ic-my-projects-default.png"

import ic_document_translator from "../../assets/icons/ic-document-translator.png"
import ic_document_translator_default from "../../assets/icons/ic-document-translator-default.png"

import ic_department from "../../assets/icons/ic-department.png"
import ic_department_default from "../../assets/icons/ic-department-default.png"


import { useSelector } from "react-redux";
import { PageTitle } from "../../auth";
const REACT_APP_TEAM_CHANNEL = process.env.REACT_APP_TEAM_CHANNEL;

const ITEM_NAV = [
  {
    id:1 , name: 'TEXT-TRANSLATE'
  },
  {
    id:2 , name: 'DOC-TRANSLATE'
  },
  {
    id:3 , name: 'MY-PROJECTS'
  },
  {
    id:4 , name: 'SHARE'
  },
  {
    id:5 , name: 'TEAM'
  },
  {
    id:6 , name: 'TRASH'
  },
  {
    id:7 , name: 'USER'
  },
  {
    id:8 , name: 'DEPARTMENT'
  },
]

function Sidenav({ color, handleSelectNavItem }) {
  const history = useHistory();
  const { pathname } = useLocation();
  const page = pathname.replace("/", "");
  const profile = useSelector((state) => state.profile);
  const role = profile.role?.toString();
  const [activeNavItem, setActiveNavItem] = useState(-1);

  return (
    <>
      <div className="logo-section">
        {<img onClick={() => history.push("/translation")} src={dxg_logo} alt="" />}
        <h1 style={{fontWeight: 700, fontSize: '22px', color: '#01a5d4'}}>
          AI <span style={{color: '#202279'}}>Translator</span>
        </h1>
      </div>
      <hr />
      <Menu theme="light" mode="inline" selectedKeys={["2"]}>
        <Menu.Item onClick={() => handleSelectNavItem(PageTitle.Text)} key="0" onMouseEnter={() => setActiveNavItem(1)} onMouseOut={() => setActiveNavItem(-1)}>
            <NavLink to="/text-translator">
              <div
                className="icon"
                style={{
                  background: page === "text-translator" ? 'transparent' : "",
                }}
              >
                {<img src={activeNavItem == 1 || page === "text-translator" ? ic_text_translator : ic_text_translator_default} width={30} />}
              </div>
              <span className="label">{PageTitle.Text}</span>
            </NavLink>
          </Menu.Item>
        <Menu.Item key="1" onClick={() => handleSelectNavItem(PageTitle.Docs)} onMouseEnter={() => setActiveNavItem(2)} onMouseOut={() => setActiveNavItem(-1)}>
          <NavLink to="/translation">
            <span
              className="icon"
              style={{
                background: page === "translation" ? 'transparent' : "",
              }}
            >
              {<img src={activeNavItem == 2 || page === "translation" ? ic_document_translator : ic_document_translator_default} width={30} />}
            </span>
            <span className="label">{PageTitle.Docs}</span>
          </NavLink>
        </Menu.Item>
        <Menu.Item key="2" onClick={() => handleSelectNavItem(PageTitle.Project)} onMouseEnter={() => setActiveNavItem(3)} onMouseOut={() => setActiveNavItem(-1)}>
          <NavLink to="/project">
            <span
              className="icon"
              style={{
                background: page === "project" ? 'transparent' : "",
              }}
            >
              {<img src={activeNavItem == 3 || page === "project" ? ic_my_projects : ic_my_projects_default} width={30} />}
            </span>
            <span className="label">{PageTitle.Project}</span>
          </NavLink>
        </Menu.Item>
        <Menu.Item key="3" onClick={() => handleSelectNavItem(PageTitle.Share)} onMouseEnter={() => setActiveNavItem(4)} onMouseOut={() => setActiveNavItem(-1)}>
          <NavLink to="/shared">
            <span
              className="icon"
              style={{
                background: page === "shared" ? 'transparent' : "",
              }}
            >
              {<img src={activeNavItem == 4 || page === "shared" ? ic_share : ic_share_default} width={30} />}
            </span>
            <span className="label">{PageTitle.Share}</span>
          </NavLink>
        </Menu.Item>
        {role !== "1" && role !== "3" && (
          <Menu.Item key="4" onClick={() => handleSelectNavItem(PageTitle.Team)} onMouseEnter={() => setActiveNavItem(5)} onMouseOut={() => setActiveNavItem(-1)}>
            <NavLink to="/team">
              <span
                className="icon"
                style={{
                  background: page === "team" ? 'transparent' : "",
                }}
              >
                {<img src={activeNavItem == 5 || page === "team" ? ic_team: ic_team_default} width={30} />}
              </span>
              <span className="label">{PageTitle.User}</span>
            </NavLink>
          </Menu.Item>
        )}
        {
        role === "1"
        && (
          <Menu.Item key="4" onClick={() => handleSelectNavItem(PageTitle.User)} onMouseEnter={() => setActiveNavItem(7)} onMouseOut={() => setActiveNavItem(-1)}>
            <NavLink to="/user">
              <span
                className="icon"
                style={{
                  background: page === "user" ? 'transparent' : "",
                }}
              >
                {<img src={activeNavItem == 7 || page === "user" ? ic_team : ic_team_default} width={30} />}
              </span>
              <span className="label">{PageTitle.User}</span>
            </NavLink>
          </Menu.Item>
        )}
        {
        role === "1"
        && (
        <Menu.Item key="5" onClick={() => handleSelectNavItem(PageTitle.Department)} onMouseEnter={() => setActiveNavItem(8)} onMouseOut={() => setActiveNavItem(-1)}> 
          <NavLink to="/bu">
            <span
              className="icon"
              style={{
                background: page === "bu" ? 'transparent' : "",
              }}
            >
              {<img src={activeNavItem == 8 || page === "bu" ? ic_department : ic_department_default} width={25} />}
            </span>
            <span className="label">{PageTitle.Department}</span>
          </NavLink>
        </Menu.Item>
        )}
        <Menu.Item key="6" onClick={() => handleSelectNavItem(PageTitle.Trash)} onMouseEnter={() => setActiveNavItem(6)} onMouseOut={() => setActiveNavItem(-1)}>
          <NavLink to="/recycle">
            <span
              className="icon"
              style={{
                background: page === "recycle" ? 'transparent' : "",
              }}
            >
              {<img src={activeNavItem == 6 || page === "recycle" ? ic_trash : ic_trash_default} width={25} />}
            </span>
            <span className="label">{PageTitle.Trash}</span>
          </NavLink>
        </Menu.Item>
        <Divider/>
        <Menu.Item key="7" style={{position:"fixed", bottom: 0, width: 200}}>
          <a href={REACT_APP_TEAM_CHANNEL} target="_blank">
            <span
              className="icon"
              style={{
                background: page === "recycle" ? color : "",
              }}
            >
              {<img src={bugsIcon} width={25} />}
            </span>
            <span className="label">Bug report</span>
          </a>
        </Menu.Item>
      </Menu>
    </>
  );
}

export default Sidenav;
