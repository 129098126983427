import { from } from "rxjs";
import { apiDelete, apiGet, apiPost } from "./API";
import { apiRootGet, apiRootPost, apiRootPut, apiRootDelete } from "./RootAPI";

const prefixTranslate = "/document-requests/";
const prefixUpload = "/files/upload";
const rootPrefixMyContainer = `/my-container`;
const rootPrefixHistory = `/history-translates`;
const rootPrefixMeHistory = `/me/history-translates`;

export const upload = (files) => {
  const formData = new FormData();
  files.forEach((file) => {
    formData.append("files", file);
  });
  return from(
    apiRootPost(`${prefixUpload}`, formData, null, true).then(async (res) => {
      return res;
    })
  );
};

export const translate = (project, source, targets, files) => {
  return from(
    apiRootPost(
      `${prefixTranslate}translation`,
      {
        sourceLanguage: source,
        targetLanguage: targets.map((item) => {
          return {
            language: item,
          };
        }),
        fileId: files.map((item) => item.fileId),
      },
      {
        "container-id": project,
      }
    ).then(async (res) => {
      return res;
    })
  );
};

export const getMyProjects = () => {
  return from(
    apiRootGet(`${rootPrefixMyContainer}/search?page=1&size=9999`).then(
      async (res) => {
        return res;
      }
    )
  );
};

export const getHistories = (id, keyword) => {
  return from(
    apiRootGet(`${rootPrefixHistory}/search?userId=${id}&page=1&size=9999${keyword?"&keyword=" + keyword:""}`).then(
      async (res) => {
        return res;
      }
    )
  );
};

export const getMyHistories = (keyword) => {
  return from(
    apiRootGet(`${rootPrefixMeHistory}/search?page=1&size=9999${keyword?"&keyword=" + keyword:""}`).then(
      async (res) => {
        return res;
      }
    )
  );
};