import React, { useState } from "react";
import {
  Layout,
  message,
  Button,
  Row,
  Col,
  Typography,
  Form,
  Input,
} from "antd";
import translateBG from "../assets/images/People_OK.svg";
import { API_URL } from "./../common/config";
import { getProfile } from "../services/Profile";
import { setProfile } from "../redux/actions/profileActions";
import { useDispatch, useSelector } from "react-redux";

const { Title } = Typography;
const { Header, Footer, Content } = Layout;

const SignIn = (props) => {
  const [flag, setFlag] = useState(false);
  const dispatch = useDispatch();
 

  const onFinish = (values) => {
      fetch(`${API_URL}/login`, {
        method: "GET",
        mode: "cors",
        headers: {
          Authorization: `Basic ${window.btoa(
            `${values.username}:${values.password}`
          )}`,
        },
      })
        .then((response) => {
          if (response.status === 401) {
            message.error("wrong username or password.");
          }
          if (!response.ok) {
            throw new Error("Network response was not ok");
        }

        return response.text(); // Parse JSON response
      })
      .then((res) => {
        if (res) {
          localStorage.setItem(
            "token",
            `Basic ${window.btoa(`${values.username}:${values.password}`)}`
          );
          const loginInfo = JSON.parse(res);
          localStorage.setItem("roleId", loginInfo.roleId);


          const profileSubscription = getProfile().subscribe((res) => {
            if (res.uuid) {
              console.log("res: ", res)
              dispatch(setProfile(res));
              setFlag(true);
            } else {
              alert("something wrong happen!");
            }
          });
          props.history.push("/translation");
          return () => {
            profileSubscription.unsubscribe();
          };
        } else {
          message.error("wrong username or password.");
        }
      })
      .catch((error) => {
        console.error("There was a problem with the fetch operation:", error);
      });

      
  };

  const onFinishFailed = (errorInfo) => {
    console.log("Failed:", errorInfo);
  };

  return (
    <>
      <Layout className="layout-default layout-signin">
        <Header>
          <div className="header-col header-brand">
            <h5>DXGarage AI Translator</h5>
          </div>
          <div className="header-col header-nav"></div>
          <div className="header-col header-btn"></div>
        </Header>
        <Content className="signin">
          <Row gutter={[24, 0]} justify="space-around">
            <Col
              xs={{ span: 24, offset: 0 }}
              lg={{ span: 6, offset: 2 }}
              md={{ span: 12 }}
            >
              <Title className="mb-15">Sign In</Title>
              <Title className="font-regular text-muted" level={5}>
                Enter your username and password to sign in
              </Title>
              <Form
                onFinish={onFinish}
                onFinishFailed={onFinishFailed}
                layout="vertical"
                className="row-col"
              >
                <Form.Item
                  className="username"
                  label="User Name"
                  name="username"
                  rules={[
                    {
                      required: true,
                      message: "Please input your username!",
                    },
                  ]}
                >
                  <Input placeholder="Username" />
                </Form.Item>

                <Form.Item
                  className="username"
                  label="Password"
                  name="password"
                  rules={[
                    {
                      required: true,
                      message: "Please input your password!",
                    },
                  ]}
                >
                  <Input.Password
                    visibilityToggle={true}
                    placeholder="Password"
                  />
                </Form.Item>

                <Form.Item>
                  <Button
                    type="primary"
                    htmlType="submit"
                    style={{ width: "100%" }}
                  >
                    SIGN IN
                  </Button>
                </Form.Item>
              </Form>
            </Col>
            <Col
              className="sign-img"
              style={{ padding: 12 }}
              xs={{ span: 24 }}
              lg={{ span: 12 }}
              md={{ span: 12 }}
            >
              <img src={translateBG} alt="Sign In Background" />
            </Col>
          </Row>
        </Content>
        <Footer>
          <p className="copyright">
            {" "}
            Copyright © 2024 by <a href="#">Cloudsuite</a>.{" "}
          </p>
        </Footer>
      </Layout>
    </>
  );
};

export default SignIn;
