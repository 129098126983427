import { from } from "rxjs";
import { apiDelete, apiGet, apiPost } from "./API";
import { apiRootGet, apiRootPost, apiRootPut, apiRootDelete } from "./RootAPI";

const prefixTranslate = "/translate-text";
const prefixEstimation = "/price-estimation/text";
export const modelTranslate = (type, fromLang, targetLang, text, temperature = 1, estimationPrice) => {
  return from(
    apiRootPost(`${prefixTranslate}?type=${type}`, {
        targetLanguage: targetLang,
        fromLanguage: fromLang,
        text: text,
        temperature: temperature, // range value 0.0-2.0, default: 1 - áp dụng cho type = 2
        category: 'general', // default: general - áp dụng cho type = 1
        estimationPrice: estimationPrice
      }).then(async (res) => {
      return res;
    })
  );
};
export const modelEstimation = (type, fromLang, targetLang, text, temperature = 1, estimationPrice) => {
  return from(
    apiRootPost(`${prefixEstimation}?type=${type}`, {
        targetLanguage: targetLang,
        fromLanguage: fromLang,
        text: text,
        temperature: temperature, // range value 0.0-2.0, default: 1 - áp dụng cho type = 2
        category: 'general', // default: general - áp dụng cho type = 1
        estimationPrice: estimationPrice
      }).then(async (res) => {
      return res;
    })
  );
};