// CreateModal.js
import React from 'react';
import { Modal, Form, Input, message } from 'antd';
import {
  addProject,
} from "../../services/Project";

const CreateModal = ({visible, setVisible, refresh, setRefresh}) => {
    
  const [form] = Form.useForm();

  const onOk = async () => {
    try {
      await form.validateFields();
      onSubmit(form.getFieldsValue());
    } catch (error) {
      console.error("Validation Error:", error);
    }
  };

  const onCancel = () => {
    setVisible(false);
  };

  const onSubmit = (formValues) => {
    const subscription = addProject(
      formValues.title,
      formValues.description
    ).subscribe((data) => {
      if(!data?.message)
      {
        message.success("Create successful!");
        form.resetFields();
        setVisible(false);
        setRefresh(!refresh);
        return () => {
          subscription.unsubscribe();
        };
      }
    });
  };
  const handleOk = async () => {
    try {
      await form.validateFields();
      onOk();
    } catch (error) {
      console.error("Validation Error:", error);
    }
  };
  const validateSpecialCharacters = (_, value) => {
    const regex = /^[a-zA-Z0-9\s]+$/; // Allow alphanumeric characters and spaces
    if (!regex.test(value)) {
      return Promise.reject('String contains invalid characters.');
    }
    return Promise.resolve();
  };
  return (
    <Modal
      title="Create new project"
      visible={visible}
      onCancel={onCancel}
      onOk={handleOk}
      destroyOnClose
    >
      <Form form={form} layout="vertical" name="form_in_modal">
        <Form.Item
          name="title"
          label="Title"
          rules={[{ required: true, message: "Please enter a title" },
          { validator: validateSpecialCharacters }]}
        >
          <Input placeholder="Input a title" />
        </Form.Item>
        <Form.Item
          name="description"
          label="Description"
        >
          <Input.TextArea placeholder="Input a description"
          rules={[
          { validator: validateSpecialCharacters }]} />
        </Form.Item>
      </Form>
    </Modal>
  );
};

export default CreateModal;
