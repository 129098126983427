import { Badge, Tooltip } from "antd";

import excel from "../assets/images/excel.svg";
import doc from "../assets/images/word.svg";
import pdf from "../assets/images/pdf.svg";
import ppt from "../assets/images/powerpoint.svg";
import txt from "../assets/images/txt.svg";
import csv from "../assets/images/csv.svg";
import rtf from "../assets/images/rtf.svg";
import {
  UserOutlined,
  CheckCircleOutlined,
  CloseCircleOutlined,
  MinusCircleOutlined,
  SyncOutlined,
  SettingOutlined,
  SketchOutlined,
} from "@ant-design/icons";
const COUNTRIES = {
  translation: {
    "": {
      name: "Detect language",
      nativeName: "Auto detect",
    },
    af: {
      name: "Afrikaans",
      nativeName: "Afrikaans",
      dir: "ltr",
      icon: "flag-icon flag-icon-za",
    },
    am: {
      name: "Amharic",
      nativeName: "አማርኛ",
      dir: "ltr",
      icon: "flag-icon flag-icon-et",
    },
    ar: {
      name: "Arabic",
      nativeName: "العربية",
      dir: "rtl",
      icon: "flag-icon flag-icon-sa",
    },
    as: {
      name: "Assamese",
      nativeName: "অসমীয়া",
      dir: "ltr",
      icon: "flag-icon flag-icon-in",
    },
    az: {
      name: "Azerbaijani",
      nativeName: "Azərbaycan",
      dir: "ltr",
      icon: "flag-icon flag-icon-az",
    },
    ba: {
      name: "Bashkir",
      nativeName: "Bashkir",
      dir: "ltr",
      icon: "flag-icon flag-icon-ru",
    },
    bg: {
      name: "Bulgarian",
      nativeName: "Български",
      dir: "ltr",
      icon: "flag-icon flag-icon-bg",
    },
    bn: {
      name: "Bangla",
      nativeName: "বাংলা",
      dir: "ltr",
      icon: "flag-icon flag-icon-bd",
    },
    bo: {
      name: "Tibetan",
      nativeName: "བོད་སྐད་",
      dir: "ltr",
      icon: "flag-icon flag-icon-cn",
    },
    bs: {
      name: "Bosnian",
      nativeName: "Bosnian",
      dir: "ltr",
      icon: "flag-icon flag-icon-ba",
    },
    ca: {
      name: "Catalan",
      nativeName: "Català",
      dir: "ltr",
      icon: "flag-icon flag-icon-es",
    },
    cs: {
      name: "Czech",
      nativeName: "Čeština",
      dir: "ltr",
      icon: "flag-icon flag-icon-cz",
    },
    cy: {
      name: "Welsh",
      nativeName: "Cymraeg",
      dir: "ltr",
      icon: "flag-icon flag-icon-gb-wls",
    },
    da: {
      name: "Danish",
      nativeName: "Dansk",
      dir: "ltr",
      icon: "flag-icon flag-icon-dk",
    },
    de: {
      name: "German",
      nativeName: "Deutsch",
      dir: "ltr",
      icon: "flag-icon flag-icon-de",
    },
    dsb: {
      name: "Lower Sorbian",
      nativeName: "Dolnoserbšćina",
      dir: "ltr",
      icon: "flag-icon flag-icon-de",
    },
    dv: {
      name: "Divehi",
      nativeName: "ދިވެހިބަސް",
      dir: "rtl",
      icon: "flag-icon flag-icon-mv",
    },
    el: {
      name: "Greek",
      nativeName: "Ελληνικά",
      dir: "ltr",
      icon: "flag-icon flag-icon-gr",
    },
    en: {
      name: "English",
      nativeName: "English",
      dir: "ltr",
      icon: "flag-icon flag-icon-gb",
    },
    es: {
      name: "Spanish",
      nativeName: "Español",
      dir: "ltr",
      icon: "flag-icon flag-icon-es",
    },
    et: {
      name: "Estonian",
      nativeName: "Eesti",
      dir: "ltr",
      icon: "flag-icon flag-icon-ee",
    },
    eu: {
      name: "Basque",
      nativeName: "Euskara",
      dir: "ltr",
      icon: "flag-icon flag-icon-es",
    },
    fa: {
      name: "Persian",
      nativeName: "فارسی",
      dir: "rtl",
      icon: "flag-icon flag-icon-ir",
    },
    fi: {
      name: "Finnish",
      nativeName: "Suomi",
      dir: "ltr",
      icon: "flag-icon flag-icon-fi",
    },
    fil: {
      name: "Filipino",
      nativeName: "Filipino",
      dir: "ltr",
      icon: "flag-icon flag-icon-ph",
    },
    fj: {
      name: "Fijian",
      nativeName: "Na Vosa Vakaviti",
      dir: "ltr",
      icon: "flag-icon flag-icon-fj",
    },
    fo: {
      name: "Faroese",
      nativeName: "Føroyskt",
      dir: "ltr",
      icon: "flag-icon flag-icon-fo",
    },
    fr: {
      name: "French",
      nativeName: "Français",
      dir: "ltr",
      icon: "flag-icon flag-icon-fr",
    },
    "fr-CA": {
      name: "French (Canada)",
      nativeName: "Français (Canada)",
      dir: "ltr",
      icon: "flag-icon flag-icon-ca",
    },
    ga: {
      name: "Irish",
      nativeName: "Gaeilge",
      dir: "ltr",
      icon: "flag-icon flag-icon-ie",
    },
    gl: {
      name: "Galician",
      nativeName: "Galego",
      dir: "ltr",
      icon: "flag-icon flag-icon-es",
    },
    gom: {
      name: "Konkani",
      nativeName: "Konkani",
      dir: "ltr",
      icon: "flag-icon flag-icon-in",
    },
    gu: {
      name: "Gujarati",
      nativeName: "ગુજરાતી",
      dir: "ltr",
      icon: "flag-icon flag-icon-in",
    },
    ha: {
      name: "Hausa",
      nativeName: "Hausa",
      dir: "ltr",
      icon: "flag-icon flag-icon-ng",
    },
    he: {
      name: "Hebrew",
      nativeName: "עברית",
      dir: "rtl",
      icon: "flag-icon flag-icon-il",
    },
    hi: {
      name: "Hindi",
      nativeName: "हिन्दी",
      dir: "ltr",
      icon: "flag-icon flag-icon-in",
    },
    hr: {
      name: "Croatian",
      nativeName: "Hrvatski",
      dir: "ltr",
      icon: "flag-icon flag-icon-hr",
    },
    hsb: {
      name: "Upper Sorbian",
      nativeName: "Hornjoserbšćina",
      dir: "ltr",
      icon: "flag-icon flag-icon-de",
    },
    ht: {
      name: "Haitian Creole",
      nativeName: "Haitian Creole",
      dir: "ltr",
      icon: "flag-icon flag-icon-ht",
    },
    hu: {
      name: "Hungarian",
      nativeName: "Magyar",
      dir: "ltr",
      icon: "flag-icon flag-icon-hu",
    },
    hy: {
      name: "Armenian",
      nativeName: "Հայերեն",
      dir: "ltr",
      icon: "flag-icon flag-icon-am",
    },
    id: {
      name: "Indonesian",
      nativeName: "Indonesia",
      dir: "ltr",
      icon: "flag-icon flag-icon-id",
    },
    ig: {
      name: "Igbo",
      nativeName: "Ásụ̀sụ́ Ìgbò",
      dir: "ltr",
      icon: "flag-icon flag-icon-ng",
    },
    ikt: { name: "Inuinnaqtun", nativeName: "Inuinnaqtun", dir: "ltr" },
    is: {
      name: "Icelandic",
      nativeName: "Íslenska",
      dir: "ltr",
      icon: "flag-icon flag-icon-is",
    },
    it: {
      name: "Italian",
      nativeName: "Italiano",
      dir: "ltr",
      icon: "flag-icon flag-icon-it",
    },
    iu: { name: "Inuktitut", nativeName: "ᐃᓄᒃᑎᑐᑦ", dir: "ltr" },
    "iu-Latn": {
      name: "Inuktitut (Latin)",
      nativeName: "Inuktitut (Latin)",
      dir: "ltr",
    },
    ja: {
      name: "Japanese",
      nativeName: "日本語",
      dir: "ltr",
      icon: "flag-icon flag-icon-jp",
    },
    ka: {
      name: "Georgian",
      nativeName: "ქართული",
      dir: "ltr",
      icon: "flag-icon flag-icon-ge",
    },
    kk: {
      name: "Kazakh",
      nativeName: "Қазақ Тілі",
      dir: "ltr",
      icon: "flag-icon flag-icon-kz",
    },
    km: {
      name: "Khmer",
      nativeName: "ខ្មែរ",
      dir: "ltr",
      icon: "flag-icon flag-icon-kh",
    },
    kmr: {
      name: "Kurdish (Northern)",
      nativeName: "Kurdî (Bakur)",
      dir: "ltr",
    },
    kn: {
      name: "Kannada",
      nativeName: "ಕನ್ನಡ",
      dir: "ltr",
      icon: "flag-icon flag-icon-in",
    },
    ko: {
      name: "Korean",
      nativeName: "한국어",
      dir: "ltr",
      icon: "flag-icon flag-icon-kr",
    },
    ku: { name: "Kurdish (Central)", nativeName: "Kurdî (Navîn)", dir: "rtl" },
    ky: {
      name: "Kyrgyz",
      nativeName: "Кыргызча",
      dir: "ltr",
      icon: "flag-icon flag-icon-kg",
    },
    ln: {
      name: "Lingala",
      nativeName: "Lingála",
      dir: "ltr",
      icon: "flag-icon flag-icon-cd",
    },
    lo: {
      name: "Lao",
      nativeName: "ລາວ",
      dir: "ltr",
      icon: "flag-icon flag-icon-la",
    },
    lt: {
      name: "Lithuanian",
      nativeName: "Lietuvių",
      dir: "ltr",
      icon: "flag-icon flag-icon-lt",
    },
    lug: {
      name: "Ganda",
      nativeName: "Ganda",
      dir: "ltr",
      icon: "flag-icon flag-icon-ug",
    },
    lv: {
      name: "Latvian",
      nativeName: "Latviešu",
      dir: "ltr",
      icon: "flag-icon flag-icon-lv",
    },
    lzh: {
      name: "Chinese (Literary)",
      nativeName: "中文 (文言文)",
      dir: "ltr",
    },
    mai: { name: "Maithili", nativeName: "Maithili", dir: "ltr" },
    mg: {
      name: "Malagasy",
      nativeName: "Malagasy",
      dir: "ltr",
      icon: "flag-icon flag-icon-mg",
    },
    mi: {
      name: "Māori",
      nativeName: "Te Reo Māori",
      dir: "ltr",
      icon: "flag-icon flag-icon-nz",
    },
    mk: {
      name: "Macedonian",
      nativeName: "Македонски",
      dir: "ltr",
      icon: "flag-icon flag-icon-mk",
    },
    ml: {
      name: "Malayalam",
      nativeName: "മലയാളം",
      dir: "ltr",
      icon: "flag-icon flag-icon-in",
    },
    "mn-Cyrl": {
      name: "Mongolian (Cyrillic)",
      nativeName: "Mongolian (Cyrillic)",
      dir: "ltr",
    },
    "mn-Mong": {
      name: "Mongolian (Traditional)",
      nativeName: "ᠮᠣᠩᠭᠣᠯ ᠬᠡᠯᠡ",
      dir: "ltr",
    },
    mr: {
      name: "Marathi",
      nativeName: "मराठी",
      dir: "ltr",
      icon: "flag-icon flag-icon-in",
    },
    ms: {
      name: "Malay",
      nativeName: "Melayu",
      dir: "ltr",
      icon: "flag-icon flag-icon-my",
    },
    mt: {
      name: "Maltese",
      nativeName: "Malti",
      dir: "ltr",
      icon: "flag-icon flag-icon-mt",
    },
    mww: { name: "Hmong Daw", nativeName: "Hmong Daw", dir: "ltr" },
    my: {
      name: "Myanmar (Burmese)",
      nativeName: "မြန်မာ",
      dir: "ltr",
      icon: "flag-icon flag-icon-mm",
    },
    nb: {
      name: "Norwegian",
      nativeName: "Norsk Bokmål",
      dir: "ltr",
      icon: "flag-icon flag-icon-no",
    },
    ne: {
      name: "Nepali",
      nativeName: "नेपाली",
      dir: "ltr",
      icon: "flag-icon flag-icon-np",
    },
    nl: {
      name: "Dutch",
      nativeName: "Nederlands",
      dir: "ltr",
      icon: "flag-icon flag-icon-nl",
    },
    nso: {
      name: "Sesotho sa Leboa",
      nativeName: "Sesotho sa Leboa",
      dir: "ltr",
      icon: "flag-icon flag-icon-za",
    },
    nya: { name: "Nyanja", nativeName: "Nyanja", dir: "ltr" },
    or: {
      name: "Odia",
      nativeName: "ଓଡ଼ିଆ",
      dir: "ltr",
      icon: "flag-icon flag-icon-in",
    },
    otq: { name: "Querétaro Otomi", nativeName: "Hñähñu", dir: "ltr" },
    pa: {
      name: "Punjabi",
      nativeName: "ਪੰਜਾਬੀ",
      dir: "ltr",
      icon: "flag-icon flag-icon-in",
    },
    pl: {
      name: "Polish",
      nativeName: "Polski",
      dir: "ltr",
      icon: "flag-icon flag-icon-pl",
    },
    prs: { name: "Dari", nativeName: "دری", dir: "rtl" },
    ps: {
      name: "Pashto",
      nativeName: "پښتو",
      dir: "rtl",
      icon: "flag-icon flag-icon-af",
    },
    pt: {
      name: "Portuguese (Brazil)",
      nativeName: "Português (Brasil)",
      dir: "ltr",
      icon: "flag-icon flag-icon-br",
    },
    "pt-PT": {
      name: "Portuguese (Portugal)",
      nativeName: "Português (Portugal)",
      dir: "ltr",
      icon: "flag-icon flag-icon-pt",
    },
    ro: {
      name: "Romanian",
      nativeName: "Română",
      dir: "ltr",
      icon: "flag-icon flag-icon-ro",
    },
    ru: {
      name: "Russian",
      nativeName: "Русский",
      dir: "ltr",
      icon: "flag-icon flag-icon-ru",
    },
    run: {
      name: "Rundi",
      nativeName: "Rundi",
      dir: "ltr",
      icon: "flag-icon flag-icon-rw",
    },
    rw: {
      name: "Kinyarwanda",
      nativeName: "Kinyarwanda",
      dir: "ltr",
      icon: "flag-icon flag-icon-rw",
    },
    sd: { name: "Sindhi", nativeName: "سنڌي", dir: "ltr" },
    si: {
      name: "Sinhala",
      nativeName: "සිංහල",
      dir: "ltr",
      icon: "flag-icon flag-icon-lk",
    },
    sk: {
      name: "Slovak",
      nativeName: "Slovenčina",
      dir: "ltr",
      icon: "flag-icon flag-icon-sk",
    },
    sl: {
      name: "Slovenian",
      nativeName: "Slovenščina",
      dir: "ltr",
      icon: "flag-icon flag-icon-si",
    },
    sm: {
      name: "Samoan",
      nativeName: "Gagana Sāmoa",
      dir: "ltr",
      icon: "flag-icon flag-icon-ws",
    },
    sn: { name: "Shona", nativeName: "chiShona", dir: "ltr" },
    so: {
      name: "Somali",
      nativeName: "Soomaali",
      dir: "ltr",
      icon: "flag-icon flag-icon-so",
    },
    sq: {
      name: "Albanian",
      nativeName: "Shqip",
      dir: "ltr",
      icon: "flag-icon flag-icon-al",
    },
    "sr-Cyrl": {
      name: "Serbian (Cyrillic)",
      nativeName: "Српски (ћирилица)",
      dir: "ltr",
      icon: "flag-icon flag-icon-rs",
    },
    "sr-Latn": {
      name: "Serbian (Latin)",
      nativeName: "Srpski (latinica)",
      dir: "ltr",
      icon: "flag-icon flag-icon-rs",
    },
    st: {
      name: "Sesotho",
      nativeName: "Sesotho",
      dir: "ltr",
      icon: "flag-icon flag-icon-za",
    },
    sv: {
      name: "Swedish",
      nativeName: "Svenska",
      dir: "ltr",
      icon: "flag-icon flag-icon-se",
    },
    sw: {
      name: "Swahili",
      nativeName: "Kiswahili",
      dir: "ltr",
      icon: "flag-icon flag-icon-ke",
    },
    ta: {
      name: "Tamil",
      nativeName: "தமிழ்",
      dir: "ltr",
      icon: "flag-icon flag-icon-in",
    },
    te: {
      name: "Telugu",
      nativeName: "తెలుగు",
      dir: "ltr",
      icon: "flag-icon flag-icon-in",
    },
    th: {
      name: "Thai",
      nativeName: "ไทย",
      dir: "ltr",
      icon: "flag-icon flag-icon-th",
    },
    ti: { name: "Tigrinya", nativeName: "ትግር", dir: "ltr" },
    tk: {
      name: "Turkmen",
      nativeName: "Türkmen Dili",
      dir: "ltr",
      icon: "flag-icon flag-icon-tm",
    },
    "tlh-Latn": {
      name: "Klingon (Latin)",
      nativeName: "Klingon (Latin)",
      dir: "ltr",
    },
    "tlh-Piqd": {
      name: "Klingon (pIqaD)",
      nativeName: "Klingon (pIqaD)",
      dir: "ltr",
    },
    tn: {
      name: "Setswana",
      nativeName: "Setswana",
      dir: "ltr",
      icon: "flag-icon flag-icon-bw",
    },
    to: { name: "Tongan", nativeName: "Lea Fakatonga", dir: "ltr" },
    tr: {
      name: "Turkish",
      nativeName: "Türkçe",
      dir: "ltr",
      icon: "flag-icon flag-icon-tr",
    },
    tt: { name: "Tatar", nativeName: "Татар", dir: "ltr" },
    ty: { name: "Tahitian", nativeName: "Reo Tahiti", dir: "ltr" },
    ug: { name: "Uyghur", nativeName: "ئۇيغۇرچە", dir: "rtl" },
    uk: {
      name: "Ukrainian",
      nativeName: "Українська",
      dir: "ltr",
      icon: "flag-icon flag-icon-ua",
    },
    ur: {
      name: "Urdu",
      nativeName: "اردو",
      dir: "rtl",
      icon: "flag-icon flag-icon-pk",
    },
    uz: {
      name: "Uzbek (Latin)",
      nativeName: "Uzbek (Latin)",
      dir: "ltr",
      icon: "flag-icon flag-icon-uz",
    },
    vi: {
      name: "Vietnamese",
      nativeName: "Tiếng Việt",
      dir: "ltr",
      icon: "flag-icon flag-icon-vn",
    },
    xh: {
      name: "Xhosa",
      nativeName: "isiXhosa",
      dir: "ltr",
      icon: "flag-icon flag-icon-za",
    },
    yo: { name: "Yoruba", nativeName: "Èdè Yorùbá", dir: "ltr" },
    yua: { name: "Yucatec Maya", nativeName: "Yucatec Maya", dir: "ltr" },
    yue: {
      name: "Cantonese (Traditional)",
      nativeName: "粵語 (繁體)",
      dir: "ltr",
    },
    "zh-Hans": {
      name: "Chinese Simplified",
      nativeName: "中文 (简体)",
      dir: "ltr",
      icon: "flag-icon flag-icon-cn",
    },
    "zh-Hant": {
      name: "Chinese Traditional",
      nativeName: "繁體中文 (繁體)",
      dir: "ltr",
      icon: "flag-icon flag-icon-tw",
    },
    zu: {
      name: "Zulu",
      nativeName: "Isi-Zulu",
      dir: "ltr",
      icon: "flag-icon flag-icon-za",
    },
  },
  transliteration: {
    ar: {
      name: "Arabic",
      nativeName: "العربية",
      scripts: [
        {
          code: "Arab",
          name: "Arabic",
          nativeName: "العربية",
          dir: "rtl",
          toScripts: [
            {
              code: "Latn",
              name: "Latin",
              nativeName: "اللاتينية",
              dir: "ltr",
            },
          ],
        },
        {
          code: "Latn",
          name: "Latin",
          nativeName: "اللاتينية",
          dir: "ltr",
          toScripts: [
            { code: "Arab", name: "Arabic", nativeName: "العربية", dir: "rtl" },
          ],
        },
      ],
    },
    as: {
      name: "Assamese",
      nativeName: "অসমীয়া",
      scripts: [
        {
          code: "Beng",
          name: "Bengali",
          nativeName: "বাংলা",
          dir: "ltr",
          toScripts: [
            { code: "Latn", name: "Latin", nativeName: "লেটিন", dir: "ltr" },
          ],
        },
        {
          code: "Latn",
          name: "Latin",
          nativeName: "লেটিন",
          dir: "ltr",
          toScripts: [
            { code: "Beng", name: "Bangla", nativeName: "বাংলা", dir: "ltr" },
          ],
        },
      ],
    },
    be: {
      name: "Belarusian",
      nativeName: "беларуская",
      scripts: [
        {
          code: "Cyrl",
          name: "Cyrillic",
          nativeName: "Кірыліца",
          dir: "ltr",
          toScripts: [
            { code: "Latn", name: "Latin", nativeName: "Лацініца", dir: "ltr" },
          ],
        },
        {
          code: "Latn",
          name: "Latin",
          nativeName: "Лацініца",
          dir: "ltr",
          toScripts: [
            {
              code: "Cyrl",
              name: "Cyrillic",
              nativeName: "Кірыліца",
              dir: "ltr",
            },
          ],
        },
      ],
    },
    bg: {
      name: "Bulgarian",
      nativeName: "Български",
      scripts: [
        {
          code: "Cyrl",
          name: "Cyrillic",
          nativeName: "кирилица",
          dir: "ltr",
          toScripts: [
            { code: "Latn", name: "Latin", nativeName: "латиница", dir: "ltr" },
          ],
        },
        {
          code: "Latn",
          name: "Latin",
          nativeName: "латиница",
          dir: "ltr",
          toScripts: [
            {
              code: "Cyrl",
              name: "Cyrillic",
              nativeName: "кирилица",
              dir: "ltr",
            },
          ],
        },
      ],
    },
    bn: {
      name: "Bangla",
      nativeName: "বাংলা",
      scripts: [
        {
          code: "Beng",
          name: "Bengali",
          nativeName: "বাংলা",
          dir: "ltr",
          toScripts: [
            { code: "Latn", name: "Latin", nativeName: "ল্যাটিন", dir: "ltr" },
          ],
        },
        {
          code: "Latn",
          name: "Latin",
          nativeName: "ল্যাটিন",
          dir: "ltr",
          toScripts: [
            { code: "Beng", name: "Bangla", nativeName: "বাংলা", dir: "ltr" },
          ],
        },
      ],
    },
    brx: {
      name: "Bodo",
      nativeName: "बड़ो",
      scripts: [
        {
          code: "Deva",
          name: "Devanagari",
          nativeName: "Devanagari",
          dir: "ltr",
          toScripts: [
            { code: "Latn", name: "Latin", nativeName: "Latin", dir: "ltr" },
          ],
        },
        {
          code: "Latn",
          name: "Latin",
          nativeName: "Latin",
          dir: "ltr",
          toScripts: [
            {
              code: "Deva",
              name: "Devanagari",
              nativeName: "Devanagari",
              dir: "ltr",
            },
          ],
        },
      ],
    },
    el: {
      name: "Greek",
      nativeName: "Ελληνικά",
      scripts: [
        {
          code: "Grek",
          name: "Greek",
          nativeName: "ελληνικό",
          dir: "ltr",
          toScripts: [
            { code: "Latn", name: "Latin", nativeName: "λατινικό", dir: "ltr" },
          ],
        },
        {
          code: "Latn",
          name: "Latin",
          nativeName: "λατινικό",
          dir: "ltr",
          toScripts: [
            { code: "Grek", name: "Greek", nativeName: "ελληνικό", dir: "ltr" },
          ],
        },
      ],
    },
    fa: {
      name: "Persian",
      nativeName: "فارسی",
      scripts: [
        {
          code: "Arab",
          name: "Arabic",
          nativeName: "عربی",
          dir: "rtl",
          toScripts: [
            { code: "Latn", name: "Latin", nativeName: "لاتین", dir: "ltr" },
          ],
        },
        {
          code: "Latn",
          name: "Latin",
          nativeName: "لاتین",
          dir: "ltr",
          toScripts: [
            { code: "Arab", name: "Arabic", nativeName: "عربی", dir: "rtl" },
          ],
        },
      ],
    },
    gom: {
      name: "Konkani",
      nativeName: "Konkani",
      scripts: [
        {
          code: "Deva",
          name: "Devanagari",
          nativeName: "Devanagari",
          dir: "ltr",
          toScripts: [
            { code: "Latn", name: "Latin", nativeName: "Latin", dir: "ltr" },
          ],
        },
        {
          code: "Latn",
          name: "Latin",
          nativeName: "Latin",
          dir: "ltr",
          toScripts: [
            {
              code: "Deva",
              name: "Devanagari",
              nativeName: "Devanagari",
              dir: "ltr",
            },
          ],
        },
      ],
    },
    gu: {
      name: "Gujarati",
      nativeName: "ગુજરાતી",
      scripts: [
        {
          code: "Gujr",
          name: "Gujarati",
          nativeName: "ગુજરાતી",
          dir: "ltr",
          toScripts: [
            { code: "Latn", name: "Latin", nativeName: "લેટિન", dir: "ltr" },
          ],
        },
        {
          code: "Latn",
          name: "Latin",
          nativeName: "લેટિન",
          dir: "ltr",
          toScripts: [
            {
              code: "Gujr",
              name: "Gujarati",
              nativeName: "ગુજરાતી",
              dir: "ltr",
            },
          ],
        },
      ],
    },
    he: {
      name: "Hebrew",
      nativeName: "עברית",
      scripts: [
        {
          code: "Hebr",
          name: "Hebrew",
          nativeName: "עברי",
          dir: "rtl",
          toScripts: [
            { code: "Latn", name: "Latin", nativeName: "לטיני", dir: "ltr" },
          ],
        },
        {
          code: "Latn",
          name: "Latin",
          nativeName: "לטיני",
          dir: "ltr",
          toScripts: [
            { code: "Hebr", name: "Hebrew", nativeName: "עברי", dir: "rtl" },
          ],
        },
      ],
    },
    hi: {
      name: "Hindi",
      nativeName: "हिन्दी",
      scripts: [
        {
          code: "Deva",
          name: "Devanagari",
          nativeName: "देवनागरी",
          dir: "ltr",
          toScripts: [
            { code: "Latn", name: "Latin", nativeName: "लैटिन", dir: "ltr" },
          ],
        },
        {
          code: "Latn",
          name: "Latin",
          nativeName: "लैटिन",
          dir: "ltr",
          toScripts: [
            {
              code: "Deva",
              name: "Devanagari",
              nativeName: "देवनागरी",
              dir: "ltr",
            },
          ],
        },
      ],
    },
    ja: {
      name: "Japanese",
      nativeName: "日本語",
      scripts: [
        {
          code: "Jpan",
          name: "Japanese",
          nativeName: "日本語の文字",
          dir: "ltr",
          toScripts: [
            {
              code: "Latn",
              name: "Latin",
              nativeName: "ラテン文字",
              dir: "ltr",
            },
          ],
        },
        {
          code: "Latn",
          name: "Latin",
          nativeName: "ラテン文字",
          dir: "ltr",
          toScripts: [
            {
              code: "Jpan",
              name: "Japanese",
              nativeName: "日本語の文字",
              dir: "ltr",
            },
          ],
        },
      ],
    },
    kk: {
      name: "Kazakh",
      nativeName: "Қазақ Тілі",
      scripts: [
        {
          code: "Cyrl",
          name: "Cyrillic",
          nativeName: "кирилл жазуы",
          dir: "ltr",
          toScripts: [
            {
              code: "Latn",
              name: "Latin",
              nativeName: "латын жазуы",
              dir: "ltr",
            },
          ],
        },
        {
          code: "Latn",
          name: "Latin",
          nativeName: "латын жазуы",
          dir: "ltr",
          toScripts: [
            {
              code: "Cyrl",
              name: "Cyrillic",
              nativeName: "кирилл жазуы",
              dir: "ltr",
            },
          ],
        },
      ],
    },
    kn: {
      name: "Kannada",
      nativeName: "ಕನ್ನಡ",
      scripts: [
        {
          code: "Knda",
          name: "Kannada",
          nativeName: "ಕನ್ನಡ",
          dir: "ltr",
          toScripts: [
            { code: "Latn", name: "Latin", nativeName: "ಲ್ಯಾಟಿನ್", dir: "ltr" },
          ],
        },
        {
          code: "Latn",
          name: "Latin",
          nativeName: "ಲ್ಯಾಟಿನ್",
          dir: "ltr",
          toScripts: [
            { code: "Knda", name: "Kannada", nativeName: "ಕನ್ನಡ", dir: "ltr" },
          ],
        },
      ],
    },
    ko: {
      name: "Korean",
      nativeName: "한국어",
      scripts: [
        {
          code: "Kore",
          name: "Korean",
          nativeName: "한국 문자",
          dir: "ltr",
          toScripts: [
            { code: "Latn", name: "Latin", nativeName: "로마자", dir: "ltr" },
          ],
        },
        {
          code: "Latn",
          name: "Latin",
          nativeName: "로마자",
          dir: "ltr",
          toScripts: [
            {
              code: "Kore",
              name: "Korean",
              nativeName: "한국 문자",
              dir: "ltr",
            },
          ],
        },
      ],
    },
    ks: {
      name: "Kashmiri",
      nativeName: "کٲشُر",
      scripts: [
        {
          code: "Arab",
          name: "Arabic",
          nativeName: "Arabic",
          dir: "ltr",
          toScripts: [
            { code: "Latn", name: "Latin", nativeName: "Latin", dir: "ltr" },
          ],
        },
        {
          code: "Latn",
          name: "Latin",
          nativeName: "Latin",
          dir: "ltr",
          toScripts: [
            { code: "Arab", name: "Arabic", nativeName: "Arabic", dir: "ltr" },
          ],
        },
      ],
    },
    ky: {
      name: "Kyrgyz",
      nativeName: "Кыргызча",
      scripts: [
        {
          code: "Cyrl",
          name: "Cyrillic",
          nativeName: "кирилл",
          dir: "ltr",
          toScripts: [
            { code: "Latn", name: "Latin", nativeName: "латын", dir: "ltr" },
          ],
        },
        {
          code: "Latn",
          name: "Latin",
          nativeName: "латын",
          dir: "ltr",
          toScripts: [
            {
              code: "Cyrl",
              name: "Cyrillic",
              nativeName: "кирилл",
              dir: "ltr",
            },
          ],
        },
      ],
    },
    mai: {
      name: "Maithili",
      nativeName: "Maithili",
      scripts: [
        {
          code: "Deva",
          name: "Devanagari",
          nativeName: "Devanagari",
          dir: "ltr",
          toScripts: [
            { code: "Latn", name: "Latin", nativeName: "Latin", dir: "ltr" },
          ],
        },
        {
          code: "Latn",
          name: "Latin",
          nativeName: "Latin",
          dir: "ltr",
          toScripts: [
            {
              code: "Deva",
              name: "Devanagari",
              nativeName: "Devanagari",
              dir: "ltr",
            },
          ],
        },
      ],
    },
    mk: {
      name: "Macedonian",
      nativeName: "Македонски",
      scripts: [
        {
          code: "Cyrl",
          name: "Cyrillic",
          nativeName: "кирилско писмо",
          dir: "ltr",
          toScripts: [
            {
              code: "Latn",
              name: "Latin",
              nativeName: "латинично писмо",
              dir: "ltr",
            },
          ],
        },
        {
          code: "Latn",
          name: "Latin",
          nativeName: "латинично писмо",
          dir: "ltr",
          toScripts: [
            {
              code: "Cyrl",
              name: "Cyrillic",
              nativeName: "кирилско писмо",
              dir: "ltr",
            },
          ],
        },
      ],
    },
    ml: {
      name: "Malayalam",
      nativeName: "മലയാളം",
      scripts: [
        {
          code: "Mlym",
          name: "Malayalam",
          nativeName: "മലയാളം",
          dir: "ltr",
          toScripts: [
            { code: "Latn", name: "Latin", nativeName: "ലാറ്റിൻ", dir: "ltr" },
          ],
        },
        {
          code: "Latn",
          name: "Latin",
          nativeName: "ലാറ്റിൻ",
          dir: "ltr",
          toScripts: [
            {
              code: "Mlym",
              name: "Malayalam",
              nativeName: "മലയാളം",
              dir: "ltr",
            },
          ],
        },
      ],
    },
    "mn-Cyrl": {
      name: "Mongolian (Cyrillic)",
      nativeName: "Mongolian (Cyrillic)",
      scripts: [
        {
          code: "Cyrl",
          name: "Cyrillic",
          nativeName: "кирилл",
          dir: "ltr",
          toScripts: [
            { code: "Latn", name: "Latin", nativeName: "латин", dir: "ltr" },
          ],
        },
        {
          code: "Latn",
          name: "Latin",
          nativeName: "латин",
          dir: "ltr",
          toScripts: [
            {
              code: "Cyrl",
              name: "Cyrillic",
              nativeName: "кирилл",
              dir: "ltr",
            },
          ],
        },
      ],
    },
    mni: {
      name: "Manipuri",
      nativeName: "মৈতৈলোন্",
      scripts: [
        {
          code: "Beng",
          name: "Bengali",
          nativeName: "Bengali",
          dir: "ltr",
          toScripts: [
            { code: "Latn", name: "Latin", nativeName: "Latin", dir: "ltr" },
          ],
        },
        {
          code: "Latn",
          name: "Latin",
          nativeName: "Latin",
          dir: "ltr",
          toScripts: [
            { code: "Beng", name: "Bangla", nativeName: "Bengali", dir: "ltr" },
          ],
        },
      ],
    },
    mr: {
      name: "Marathi",
      nativeName: "मराठी",
      scripts: [
        {
          code: "Deva",
          name: "Devanagari",
          nativeName: "देवनागरी",
          dir: "ltr",
          toScripts: [
            { code: "Latn", name: "Latin", nativeName: "लॅटिन", dir: "ltr" },
          ],
        },
        {
          code: "Latn",
          name: "Latin",
          nativeName: "लॅटिन",
          dir: "ltr",
          toScripts: [
            {
              code: "Deva",
              name: "Devanagari",
              nativeName: "देवनागरी",
              dir: "ltr",
            },
          ],
        },
      ],
    },
    ne: {
      name: "Nepali",
      nativeName: "नेपाली",
      scripts: [
        {
          code: "Deva",
          name: "Devanagari",
          nativeName: "देवानागरी",
          dir: "ltr",
          toScripts: [
            { code: "Latn", name: "Latin", nativeName: "ल्याटिन", dir: "ltr" },
          ],
        },
        {
          code: "Latn",
          name: "Latin",
          nativeName: "ल्याटिन",
          dir: "ltr",
          toScripts: [
            {
              code: "Deva",
              name: "Devanagari",
              nativeName: "देवानागरी",
              dir: "ltr",
            },
          ],
        },
      ],
    },
    or: {
      name: "Odia",
      nativeName: "ଓଡ଼ିଆ",
      scripts: [
        {
          code: "Orya",
          name: "Odia",
          nativeName: "ଓଡ଼ିଆ",
          dir: "ltr",
          toScripts: [
            { code: "Latn", name: "Latin", nativeName: "ଲାଟିନ୍", dir: "ltr" },
          ],
        },
        {
          code: "Latn",
          name: "Latin",
          nativeName: "ଲାଟିନ୍",
          dir: "ltr",
          toScripts: [
            { code: "Orya", name: "Odia", nativeName: "ଓଡ଼ିଆ", dir: "ltr" },
          ],
        },
      ],
    },
    pa: {
      name: "Punjabi",
      nativeName: "ਪੰਜਾਬੀ",
      scripts: [
        {
          code: "Guru",
          name: "Gurmukhi",
          nativeName: "ਗੁਰਮੁਖੀ",
          dir: "ltr",
          toScripts: [
            { code: "Latn", name: "Latin", nativeName: "ਲਾਤੀਨੀ", dir: "ltr" },
          ],
        },
        {
          code: "Latn",
          name: "Latin",
          nativeName: "ਲਾਤੀਨੀ",
          dir: "ltr",
          toScripts: [
            {
              code: "Guru",
              name: "Gurmukhi",
              nativeName: "ਗੁਰਮੁਖੀ",
              dir: "ltr",
            },
          ],
        },
      ],
    },
    ru: {
      name: "Russian",
      nativeName: "Русский",
      scripts: [
        {
          code: "Cyrl",
          name: "Cyrillic",
          nativeName: "кириллица",
          dir: "ltr",
          toScripts: [
            { code: "Latn", name: "Latin", nativeName: "латиница", dir: "ltr" },
          ],
        },
        {
          code: "Latn",
          name: "Latin",
          nativeName: "латиница",
          dir: "ltr",
          toScripts: [
            {
              code: "Cyrl",
              name: "Cyrillic",
              nativeName: "кириллица",
              dir: "ltr",
            },
          ],
        },
      ],
    },
    sa: {
      name: "Sanskrit",
      nativeName: "संस्कृत",
      scripts: [
        {
          code: "Deva",
          name: "Devanagari",
          nativeName: "Devanagari",
          dir: "ltr",
          toScripts: [
            { code: "Latn", name: "Latin", nativeName: "Latin", dir: "ltr" },
          ],
        },
        {
          code: "Latn",
          name: "Latin",
          nativeName: "Latin",
          dir: "ltr",
          toScripts: [
            {
              code: "Deva",
              name: "Devanagari",
              nativeName: "Devanagari",
              dir: "ltr",
            },
          ],
        },
      ],
    },
    sd: {
      name: "Sindhi",
      nativeName: "سنڌي",
      scripts: [
        {
          code: "Arab",
          name: "Arabic",
          nativeName: "عربي",
          dir: "ltr",
          toScripts: [
            { code: "Latn", name: "Latin", nativeName: "لاطيني", dir: "ltr" },
          ],
        },
        {
          code: "Latn",
          name: "Latin",
          nativeName: "لاطيني",
          dir: "ltr",
          toScripts: [
            { code: "Arab", name: "Arabic", nativeName: "عربي", dir: "ltr" },
          ],
        },
      ],
    },
    si: {
      name: "Sinhala",
      nativeName: "සිංහල",
      scripts: [
        {
          code: "Sinh",
          name: "Sinhala",
          nativeName: "සිංහල",
          dir: "ltr",
          toScripts: [
            { code: "Latn", name: "Latin", nativeName: "ලතින්", dir: "ltr" },
          ],
        },
        {
          code: "Latn",
          name: "Latin",
          nativeName: "ලතින්",
          dir: "ltr",
          toScripts: [
            { code: "Sinh", name: "Sinhala", nativeName: "සිංහල", dir: "ltr" },
          ],
        },
      ],
    },
    "sr-Cyrl": {
      name: "Serbian (Cyrillic)",
      nativeName: "Српски (ћирилица)",
      scripts: [
        {
          code: "Cyrl",
          name: "Cyrillic",
          nativeName: "ћирилица",
          dir: "ltr",
          toScripts: [
            { code: "Latn", name: "Latin", nativeName: "latinica", dir: "ltr" },
          ],
        },
      ],
    },
    "sr-Latn": {
      name: "Serbian (Latin)",
      nativeName: "Srpski (latinica)",
      scripts: [
        {
          code: "Latn",
          name: "Latin",
          nativeName: "latinica",
          dir: "ltr",
          toScripts: [
            {
              code: "Cyrl",
              name: "Cyrillic",
              nativeName: "ćirilica",
              dir: "ltr",
            },
          ],
        },
      ],
    },
    ta: {
      name: "Tamil",
      nativeName: "தமிழ்",
      scripts: [
        {
          code: "Taml",
          name: "Tamil",
          nativeName: "தமிழ்",
          dir: "ltr",
          toScripts: [
            { code: "Latn", name: "Latin", nativeName: "லத்தின்", dir: "ltr" },
          ],
        },
        {
          code: "Latn",
          name: "Latin",
          nativeName: "லத்தின்",
          dir: "ltr",
          toScripts: [
            { code: "Taml", name: "Tamil", nativeName: "தமிழ்", dir: "ltr" },
          ],
        },
      ],
    },
    te: {
      name: "Telugu",
      nativeName: "తెలుగు",
      scripts: [
        {
          code: "Telu",
          name: "Telugu",
          nativeName: "తెలుగు",
          dir: "ltr",
          toScripts: [
            { code: "Latn", name: "Latin", nativeName: "లాటిన్", dir: "ltr" },
          ],
        },
        {
          code: "Latn",
          name: "Latin",
          nativeName: "లాటిన్",
          dir: "ltr",
          toScripts: [
            { code: "Telu", name: "Telugu", nativeName: "తెలుగు", dir: "ltr" },
          ],
        },
      ],
    },
    tg: {
      name: "Tajik",
      nativeName: "Tajik (Cyrillic)",
      scripts: [
        {
          code: "Cyrl",
          name: "Cyrillic",
          nativeName: "Кириллӣ",
          dir: "ltr",
          toScripts: [
            { code: "Latn", name: "Latin", nativeName: "Лотинӣ", dir: "ltr" },
          ],
        },
        {
          code: "Latn",
          name: "Latin",
          nativeName: "Лотинӣ",
          dir: "ltr",
          toScripts: [
            {
              code: "Cyrl",
              name: "Cyrillic",
              nativeName: "Кириллӣ",
              dir: "ltr",
            },
          ],
        },
      ],
    },
    th: {
      name: "Thai",
      nativeName: "ไทย",
      scripts: [
        {
          code: "Thai",
          name: "Thai",
          nativeName: "ไทย",
          dir: "ltr",
          toScripts: [
            { code: "Latn", name: "Latin", nativeName: "ละติน", dir: "ltr" },
          ],
        },
      ],
    },
    tt: {
      name: "Tatar",
      nativeName: "Татар",
      scripts: [
        {
          code: "Cyrl",
          name: "Cyrillic",
          nativeName: "кирилл",
          dir: "ltr",
          toScripts: [
            { code: "Latn", name: "Latin", nativeName: "латин", dir: "ltr" },
          ],
        },
        {
          code: "Latn",
          name: "Latin",
          nativeName: "латин",
          dir: "ltr",
          toScripts: [
            {
              code: "Cyrl",
              name: "Cyrillic",
              nativeName: "кирилл",
              dir: "ltr",
            },
          ],
        },
      ],
    },
    uk: {
      name: "Ukrainian",
      nativeName: "Українська",
      scripts: [
        {
          code: "Cyrl",
          name: "Cyrillic",
          nativeName: "кирилиця",
          dir: "ltr",
          toScripts: [
            { code: "Latn", name: "Latin", nativeName: "латиниця", dir: "ltr" },
          ],
        },
        {
          code: "Latn",
          name: "Latin",
          nativeName: "латиниця",
          dir: "ltr",
          toScripts: [
            {
              code: "Cyrl",
              name: "Cyrillic",
              nativeName: "кирилиця",
              dir: "ltr",
            },
          ],
        },
      ],
    },
    ur: {
      name: "Urdu",
      nativeName: "اردو",
      scripts: [
        {
          code: "Arab",
          name: "Arabic",
          nativeName: "عربی",
          dir: "rtl",
          toScripts: [
            { code: "Latn", name: "Latin", nativeName: "لاطینی", dir: "ltr" },
          ],
        },
        {
          code: "Latn",
          name: "Latin",
          nativeName: "لاطینی",
          dir: "ltr",
          toScripts: [
            { code: "Arab", name: "Arabic", nativeName: "عربی", dir: "rtl" },
          ],
        },
      ],
    },
    "zh-Hans": {
      name: "Chinese Simplified",
      nativeName: "中文 (简体)",
      scripts: [
        {
          code: "Hans",
          name: "Simplified",
          nativeName: "简体汉语",
          dir: "ltr",
          toScripts: [
            { code: "Latn", name: "Latin", nativeName: "拉丁语", dir: "ltr" },
            { code: "Hant", name: "Hat", nativeName: "传统", dir: "ltr" },
          ],
        },
        {
          code: "Latn",
          name: "Latin",
          nativeName: "拉丁语",
          dir: "ltr",
          toScripts: [
            { code: "Hans", name: "Han", nativeName: "简体汉语", dir: "ltr" },
            { code: "Hant", name: "Hat", nativeName: "传统", dir: "ltr" },
          ],
        },
      ],
    },
    "zh-Hant": {
      name: "Chinese Traditional",
      nativeName: "繁體中文 (繁體)",
      scripts: [
        {
          code: "Hant",
          name: "Traditional",
          nativeName: "傳統",
          dir: "ltr",
          toScripts: [
            { code: "Latn", name: "Latin", nativeName: "拉丁语", dir: "ltr" },
            { code: "Hans", name: "Han", nativeName: "简体汉语", dir: "ltr" },
          ],
        },
        {
          code: "Latn",
          name: "Latin",
          nativeName: "拉丁文",
          dir: "ltr",
          toScripts: [
            { code: "Hans", name: "Han", nativeName: "简体汉语", dir: "ltr" },
            { code: "Hant", name: "Hat", nativeName: "传统", dir: "ltr" },
          ],
        },
      ],
    },
  },
  dictionary: {
    af: {
      name: "Afrikaans",
      nativeName: "Afrikaans",
      dir: "ltr",
      translations: [
        { name: "English", nativeName: "English", dir: "ltr", code: "en" },
      ],
    },
    ar: {
      name: "Arabic",
      nativeName: "العربية",
      dir: "rtl",
      translations: [
        { name: "English", nativeName: "English", dir: "ltr", code: "en" },
      ],
    },
    bg: {
      name: "Bulgarian",
      nativeName: "Български",
      dir: "ltr",
      translations: [
        { name: "English", nativeName: "English", dir: "ltr", code: "en" },
      ],
    },
    bn: {
      name: "Bangla",
      nativeName: "বাংলা",
      dir: "ltr",
      translations: [
        { name: "English", nativeName: "English", dir: "ltr", code: "en" },
      ],
    },
    bs: {
      name: "Bosnian",
      nativeName: "Bosnian",
      dir: "ltr",
      translations: [
        { name: "English", nativeName: "English", dir: "ltr", code: "en" },
      ],
    },
    ca: {
      name: "Catalan",
      nativeName: "Català",
      dir: "ltr",
      translations: [
        { name: "English", nativeName: "English", dir: "ltr", code: "en" },
      ],
    },
    cs: {
      name: "Czech",
      nativeName: "Čeština",
      dir: "ltr",
      translations: [
        { name: "English", nativeName: "English", dir: "ltr", code: "en" },
      ],
    },
    cy: {
      name: "Welsh",
      nativeName: "Cymraeg",
      dir: "ltr",
      translations: [
        { name: "English", nativeName: "English", dir: "ltr", code: "en" },
      ],
    },
    da: {
      name: "Danish",
      nativeName: "Dansk",
      dir: "ltr",
      translations: [
        { name: "English", nativeName: "English", dir: "ltr", code: "en" },
      ],
    },
    de: {
      name: "German",
      nativeName: "Deutsch",
      dir: "ltr",
      translations: [
        { name: "English", nativeName: "English", dir: "ltr", code: "en" },
      ],
    },
    el: {
      name: "Greek",
      nativeName: "Ελληνικά",
      dir: "ltr",
      translations: [
        { name: "English", nativeName: "English", dir: "ltr", code: "en" },
      ],
    },
    en: {
      name: "English",
      nativeName: "English",
      dir: "ltr",
      translations: [
        { name: "Afrikaans", nativeName: "Afrikaans", dir: "ltr", code: "af" },
        { name: "Arabic", nativeName: "العربية", dir: "rtl", code: "ar" },
        { name: "Bulgarian", nativeName: "Български", dir: "ltr", code: "bg" },
        { name: "Bangla", nativeName: "বাংলা", dir: "ltr", code: "bn" },
        { name: "Bosnian", nativeName: "Bosnian", dir: "ltr", code: "bs" },
        { name: "Catalan", nativeName: "Català", dir: "ltr", code: "ca" },
        {
          name: "Chinese Simplified",
          nativeName: "中文 (简体)",
          dir: "ltr",
          code: "zh-Hans",
        },
        { name: "Czech", nativeName: "Čeština", dir: "ltr", code: "cs" },
        { name: "Welsh", nativeName: "Cymraeg", dir: "ltr", code: "cy" },
        { name: "Danish", nativeName: "Dansk", dir: "ltr", code: "da" },
        { name: "German", nativeName: "Deutsch", dir: "ltr", code: "de" },
        { name: "Greek", nativeName: "Ελληνικά", dir: "ltr", code: "el" },
        { name: "Spanish", nativeName: "Español", dir: "ltr", code: "es" },
        { name: "Estonian", nativeName: "Eesti", dir: "ltr", code: "et" },
        { name: "Persian", nativeName: "فارسی", dir: "rtl", code: "fa" },
        { name: "Finnish", nativeName: "Suomi", dir: "ltr", code: "fi" },
        { name: "French", nativeName: "Français", dir: "ltr", code: "fr" },
        { name: "Hebrew", nativeName: "עברית", dir: "rtl", code: "he" },
        { name: "Hindi", nativeName: "हिन्दी", dir: "ltr", code: "hi" },
        { name: "Croatian", nativeName: "Hrvatski", dir: "ltr", code: "hr" },
        { name: "Hungarian", nativeName: "Magyar", dir: "ltr", code: "hu" },
        { name: "Indonesian", nativeName: "Indonesia", dir: "ltr", code: "id" },
        { name: "Icelandic", nativeName: "Íslenska", dir: "ltr", code: "is" },
        { name: "Italian", nativeName: "Italiano", dir: "ltr", code: "it" },
        { name: "Japanese", nativeName: "日本語", dir: "ltr", code: "ja" },
        { name: "Korean", nativeName: "한국어", dir: "ltr", code: "ko" },
        { name: "Lithuanian", nativeName: "Lietuvių", dir: "ltr", code: "lt" },
        { name: "Latvian", nativeName: "Latviešu", dir: "ltr", code: "lv" },
        { name: "Maltese", nativeName: "Malti", dir: "ltr", code: "mt" },
        { name: "Malay", nativeName: "Melayu", dir: "ltr", code: "ms" },
        { name: "Hmong Daw", nativeName: "Hmong Daw", dir: "ltr", code: "mww" },
        { name: "Dutch", nativeName: "Nederlands", dir: "ltr", code: "nl" },
        {
          name: "Norwegian",
          nativeName: "Norsk Bokmål",
          dir: "ltr",
          code: "nb",
        },
        { name: "Polish", nativeName: "Polski", dir: "ltr", code: "pl" },
        {
          name: "Portuguese (Brazil)",
          nativeName: "Português (Brasil)",
          dir: "ltr",
          code: "pt",
        },
        { name: "Romanian", nativeName: "Română", dir: "ltr", code: "ro" },
        { name: "Russian", nativeName: "Русский", dir: "ltr", code: "ru" },
        { name: "Slovak", nativeName: "Slovenčina", dir: "ltr", code: "sk" },
        {
          name: "Slovenian",
          nativeName: "Slovenščina",
          dir: "ltr",
          code: "sl",
        },
        {
          name: "Serbian (Latin)",
          nativeName: "Srpski (latinica)",
          dir: "ltr",
          code: "sr-Latn",
        },
        { name: "Swedish", nativeName: "Svenska", dir: "ltr", code: "sv" },
        { name: "Swahili", nativeName: "Kiswahili", dir: "ltr", code: "sw" },
        { name: "Tamil", nativeName: "தமிழ்", dir: "ltr", code: "ta" },
        { name: "Thai", nativeName: "ไทย", dir: "ltr", code: "th" },
        {
          name: "Klingon (Latin)",
          nativeName: "Klingon (Latin)",
          dir: "ltr",
          code: "tlh-Latn",
        },
        { name: "Turkish", nativeName: "Türkçe", dir: "ltr", code: "tr" },
        { name: "Ukrainian", nativeName: "Українська", dir: "ltr", code: "uk" },
        { name: "Urdu", nativeName: "اردو", dir: "rtl", code: "ur" },
        {
          name: "Vietnamese",
          nativeName: "Tiếng Việt",
          dir: "ltr",
          code: "vi",
        },
      ],
    },
    es: {
      name: "Spanish",
      nativeName: "Español",
      dir: "ltr",
      translations: [
        { name: "English", nativeName: "English", dir: "ltr", code: "en" },
      ],
    },
    et: {
      name: "Estonian",
      nativeName: "Eesti",
      dir: "ltr",
      translations: [
        { name: "English", nativeName: "English", dir: "ltr", code: "en" },
      ],
    },
    fa: {
      name: "Persian",
      nativeName: "فارسی",
      dir: "rtl",
      translations: [
        { name: "English", nativeName: "English", dir: "ltr", code: "en" },
      ],
    },
    fi: {
      name: "Finnish",
      nativeName: "Suomi",
      dir: "ltr",
      translations: [
        { name: "English", nativeName: "English", dir: "ltr", code: "en" },
      ],
    },
    fr: {
      name: "French",
      nativeName: "Français",
      dir: "ltr",
      translations: [
        { name: "English", nativeName: "English", dir: "ltr", code: "en" },
      ],
    },
    he: {
      name: "Hebrew",
      nativeName: "עברית",
      dir: "rtl",
      translations: [
        { name: "English", nativeName: "English", dir: "ltr", code: "en" },
      ],
    },
    hi: {
      name: "Hindi",
      nativeName: "हिन्दी",
      dir: "ltr",
      translations: [
        { name: "English", nativeName: "English", dir: "ltr", code: "en" },
      ],
    },
    hr: {
      name: "Croatian",
      nativeName: "Hrvatski",
      dir: "ltr",
      translations: [
        { name: "English", nativeName: "English", dir: "ltr", code: "en" },
      ],
    },
    hu: {
      name: "Hungarian",
      nativeName: "Magyar",
      dir: "ltr",
      translations: [
        { name: "English", nativeName: "English", dir: "ltr", code: "en" },
      ],
    },
    id: {
      name: "Indonesian",
      nativeName: "Indonesia",
      dir: "ltr",
      translations: [
        { name: "English", nativeName: "English", dir: "ltr", code: "en" },
      ],
    },
    is: {
      name: "Icelandic",
      nativeName: "Íslenska",
      dir: "ltr",
      translations: [
        { name: "English", nativeName: "English", dir: "ltr", code: "en" },
      ],
    },
    it: {
      name: "Italian",
      nativeName: "Italiano",
      dir: "ltr",
      translations: [
        { name: "English", nativeName: "English", dir: "ltr", code: "en" },
      ],
    },
    ja: {
      name: "Japanese",
      nativeName: "日本語",
      dir: "ltr",
      translations: [
        { name: "English", nativeName: "English", dir: "ltr", code: "en" },
      ],
    },
    ko: {
      name: "Korean",
      nativeName: "한국어",
      dir: "ltr",
      translations: [
        { name: "English", nativeName: "English", dir: "ltr", code: "en" },
      ],
    },
    lt: {
      name: "Lithuanian",
      nativeName: "Lietuvių",
      dir: "ltr",
      translations: [
        { name: "English", nativeName: "English", dir: "ltr", code: "en" },
      ],
    },
    lv: {
      name: "Latvian",
      nativeName: "Latviešu",
      dir: "ltr",
      translations: [
        { name: "English", nativeName: "English", dir: "ltr", code: "en" },
      ],
    },
    ms: {
      name: "Malay",
      nativeName: "Melayu",
      dir: "ltr",
      translations: [
        { name: "English", nativeName: "English", dir: "ltr", code: "en" },
      ],
    },
    mt: {
      name: "Maltese",
      nativeName: "Malti",
      dir: "ltr",
      translations: [
        { name: "English", nativeName: "English", dir: "ltr", code: "en" },
      ],
    },
    mww: {
      name: "Hmong Daw",
      nativeName: "Hmong Daw",
      dir: "ltr",
      translations: [
        { name: "English", nativeName: "English", dir: "ltr", code: "en" },
      ],
    },
    nb: {
      name: "Norwegian",
      nativeName: "Norsk Bokmål",
      dir: "ltr",
      translations: [
        { name: "English", nativeName: "English", dir: "ltr", code: "en" },
      ],
    },
    nl: {
      name: "Dutch",
      nativeName: "Nederlands",
      dir: "ltr",
      translations: [
        { name: "English", nativeName: "English", dir: "ltr", code: "en" },
      ],
    },
    pl: {
      name: "Polish",
      nativeName: "Polski",
      dir: "ltr",
      translations: [
        { name: "English", nativeName: "English", dir: "ltr", code: "en" },
      ],
    },
    pt: {
      name: "Portuguese (Brazil)",
      nativeName: "Português (Brasil)",
      dir: "ltr",
      translations: [
        { name: "English", nativeName: "English", dir: "ltr", code: "en" },
      ],
    },
    ro: {
      name: "Romanian",
      nativeName: "Română",
      dir: "ltr",
      translations: [
        { name: "English", nativeName: "English", dir: "ltr", code: "en" },
      ],
    },
    ru: {
      name: "Russian",
      nativeName: "Русский",
      dir: "ltr",
      translations: [
        { name: "English", nativeName: "English", dir: "ltr", code: "en" },
      ],
    },
    sk: {
      name: "Slovak",
      nativeName: "Slovenčina",
      dir: "ltr",
      translations: [
        { name: "English", nativeName: "English", dir: "ltr", code: "en" },
      ],
    },
    sl: {
      name: "Slovenian",
      nativeName: "Slovenščina",
      dir: "ltr",
      translations: [
        { name: "English", nativeName: "English", dir: "ltr", code: "en" },
      ],
    },
    "sr-Latn": {
      name: "Serbian (Latin)",
      nativeName: "Srpski (latinica)",
      dir: "ltr",
      translations: [
        { name: "English", nativeName: "English", dir: "ltr", code: "en" },
      ],
    },
    sv: {
      name: "Swedish",
      nativeName: "Svenska",
      dir: "ltr",
      translations: [
        { name: "English", nativeName: "English", dir: "ltr", code: "en" },
      ],
    },
    sw: {
      name: "Swahili",
      nativeName: "Kiswahili",
      dir: "ltr",
      translations: [
        { name: "English", nativeName: "English", dir: "ltr", code: "en" },
      ],
    },
    ta: {
      name: "Tamil",
      nativeName: "தமிழ்",
      dir: "ltr",
      translations: [
        { name: "English", nativeName: "English", dir: "ltr", code: "en" },
      ],
    },
    th: {
      name: "Thai",
      nativeName: "ไทย",
      dir: "ltr",
      translations: [
        { name: "English", nativeName: "English", dir: "ltr", code: "en" },
      ],
    },
    "tlh-Latn": {
      name: "Klingon (Latin)",
      nativeName: "Klingon (Latin)",
      dir: "ltr",
      translations: [
        { name: "English", nativeName: "English", dir: "ltr", code: "en" },
      ],
    },
    tr: {
      name: "Turkish",
      nativeName: "Türkçe",
      dir: "ltr",
      translations: [
        { name: "English", nativeName: "English", dir: "ltr", code: "en" },
      ],
    },
    uk: {
      name: "Ukrainian",
      nativeName: "Українська",
      dir: "ltr",
      translations: [
        { name: "English", nativeName: "English", dir: "ltr", code: "en" },
      ],
    },
    ur: {
      name: "Urdu",
      nativeName: "اردو",
      dir: "rtl",
      translations: [
        { name: "English", nativeName: "English", dir: "ltr", code: "en" },
      ],
    },
    vi: {
      name: "Vietnamese",
      nativeName: "Tiếng Việt",
      dir: "ltr",
      translations: [
        { name: "English", nativeName: "English", dir: "ltr", code: "en" },
      ],
    },
    "zh-Hans": {
      name: "Chinese Simplified",
      nativeName: "中文 (简体)",
      dir: "ltr",
      translations: [
        { name: "English", nativeName: "English", dir: "ltr", code: "en" },
      ],
    },
  },
};

const ALLOW_EXT = [
  ".xml",
  ".odt",
  ".odp",
  ".ods",
  ".docx",
  ".pdf",
  ".doc",
  ".txt",
  ".ppt",
  ".pptx",
  ".xlsx",
  ".xls",
  ".pptx",
  ".tsv",
  ".tab",
  ".csv"
];

const FILE_TYPES = [
      {
          "format": "PlainText",
          "fileExtensions": [
              ".txt"
          ],
          "contentTypes": [
              "text/plain"
          ],
          "versions": []
      },
      {
          "format": "OpenXmlWord",
          "fileExtensions": [
              ".docx"
          ],
          "contentTypes": [
              "application/vnd.openxmlformats-officedocument.wordprocessingml.document"
          ],
          "versions": []
      },
      {
          "format": "OpenXmlPresentation",
          "fileExtensions": [
              ".pptx"
          ],
          "contentTypes": [
              "application/vnd.openxmlformats-officedocument.presentationml.presentation"
          ],
          "versions": []
      },
      {
          "format": "OpenXmlSpreadsheet",
          "fileExtensions": [
              ".xlsx"
          ],
          "contentTypes": [
              "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"
          ],
          "versions": []
      },
      {
          "format": "OutlookMailMessage",
          "fileExtensions": [
              ".msg"
          ],
          "contentTypes": [
              "application/vnd.ms-outlook"
          ],
          "versions": []
      },
      {
          "format": "HtmlFile",
          "fileExtensions": [
              ".html",
              ".htm"
          ],
          "contentTypes": [
              "text/html"
          ],
          "versions": []
      },
      {
          "format": "PortableDocumentFormat",
          "fileExtensions": [
              ".pdf"
          ],
          "contentTypes": [
              "application/pdf"
          ],
          "versions": []
      },
      {
          "format": "XLIFF",
          "fileExtensions": [
              ".xlf"
          ],
          "contentTypes": [
              "application/xliff+xml"
          ],
          "versions": [
              "1.0",
              "1.1",
              "1.2"
          ]
      },
      {
          "format": "TSV",
          "fileExtensions": [
              ".tsv",
              ".tab"
          ],
          "contentTypes": [
              "text/tab-separated-values"
          ],
          "versions": []
      },
      {
          "format": "CSV",
          "fileExtensions": [
              ".csv"
          ],
          "contentTypes": [
              "text/csv"
          ],
          "versions": []
      },
      {
          "format": "RichTextFormat",
          "fileExtensions": [
              ".rtf"
          ],
          "contentTypes": [
              "application/rtf"
          ],
          "versions": []
      },
      {
          "format": "WordDocument",
          "fileExtensions": [
              ".doc"
          ],
          "contentTypes": [
              "application/msword"
          ],
          "versions": []
      },
      {
          "format": "PowerpointPresentation",
          "fileExtensions": [
              ".ppt"
          ],
          "contentTypes": [
              "application/vnd.ms-powerpoint"
          ],
          "versions": []
      },
      {
          "format": "ExcelSpreadsheet",
          "fileExtensions": [
              ".xls"
          ],
          "contentTypes": [
              "application/vnd.ms-excel"
          ],
          "versions": []
      },
      {
          "format": "OpenDocumentText",
          "fileExtensions": [
              ".odt"
          ],
          "contentTypes": [
              "application/vnd.oasis.opendocument.text"
          ],
          "versions": []
      },
      {
          "format": "OpenDocumentPresentation",
          "fileExtensions": [
              ".odp"
          ],
          "contentTypes": [
              "application/vnd.oasis.opendocument.presentation"
          ],
          "versions": []
      },
      {
          "format": "OpenDocumentSpreadsheet",
          "fileExtensions": [
              ".ods"
          ],
          "contentTypes": [
              "application/vnd.oasis.opendocument.spreadsheet"
          ],
          "versions": []
      },
      {
          "format": "Markdown",
          "fileExtensions": [
              ".markdown",
              ".mdown",
              ".mkdn",
              ".md",
              ".mkd",
              ".mdwn",
              ".mdtxt",
              ".mdtext",
              ".rmd"
          ],
          "contentTypes": [
              "text/markdown",
              "text/x-markdown",
              "text/plain"
          ],
          "versions": []
      },
      {
          "format": "Mhtml",
          "fileExtensions": [
              ".mhtml",
              ".mht"
          ],
          "contentTypes": [
              "message/rfc822",
              "application/x-mimearchive",
              "multipart/related"
          ],
          "versions": []
      }
  ]
const ROLE = {
  0: "None",
  1: (
    <span style={{ color: "#000" }}>
      <SettingOutlined /> Admin
    </span>
  ),
  2: (
    <span style={{ color: "#d6a600" }}>
      <SketchOutlined /> Pro
    </span>
  ),
  3: (
    <span style={{ color: "gray" }}>
      <UserOutlined /> Basic
    </span>
  ),
};

const PARENT_ROLE = {
  1: "No direct manager",
  2: (
    <span style={{ color: "#000" }}>
      <SettingOutlined /> Admin
    </span>
  ),
  3: (
    <span style={{ color: "#d6a600" }}>
      <SketchOutlined /> Pro
    </span>
  ),
};

const STAFF_ROLE = {
  1: (
    <span style={{ color: "#d6a600" }}>
      <SketchOutlined /> Pro
    </span>
  ),
  2: (
    <span style={{ color: "gray" }}>
      <UserOutlined /> Basic
    </span>
  ),
  3: "No staffs",
};

const ROLE_DES = {
  0: "",
  1: "The admin is the highest authority in our web app, enabling the creation of pro users and assigning budgets to them for enhanced functionality and growth.",
  2: "As a pro user within our web application, you possess elevated privileges, including the ability to translate content and allocate budgets to basic users. Your role is pivotal in fostering collaboration and facilitating growth within our platform.",
  3: "As a basic user in our web application, you have access to essential features such as translating content",
};
const STATUS = {
  notstarted: {
    icon: (
      <Tooltip title={"Not Stated"} status="default">
        <Badge count={<MinusCircleOutlined style={{ color: "blue" }} />} />
      </Tooltip>
    ),
    color: "blue",
  },
  running: {
    icon: (
      <Tooltip title={"Running"} status="processing">
        <SyncOutlined spin style={{ color: "gray" }} />
      </Tooltip>
    ),
    color: "gray",
  },
  succeeded: {
    icon: (
      <Tooltip title={"Succeeded"}>
        <Badge count={<CheckCircleOutlined style={{ color: "green" }} />} />
      </Tooltip>
    ),
    color: "green",
  },
  failed: {
    icon: (
      <Tooltip title={"Failed"} status="error">
        <Badge count={<CloseCircleOutlined style={{ color: "red" }} />} />
      </Tooltip>
    ),
    color: "red",
  },
};

const EXTENSION_ICONS = {
  doc: doc,
  docx: doc,
  pdf: pdf,
  ppt: ppt,
  pptx: ppt,
  txt: txt,
  xls: excel,
  xlsx: excel,
  csv: csv,
  rtf: rtf,
};

export { ROLE, ROLE_DES, FILE_TYPES, PARENT_ROLE, STAFF_ROLE, STATUS, EXTENSION_ICONS, COUNTRIES, ALLOW_EXT };
