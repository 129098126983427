// ShareModal.js
import React from "react";
import { useEffect, useState } from "react";
import {
  Modal,
  List,
  Avatar,
  Button,
  Row,
  Col,
  Select,
  message,
  Tooltip,
} from "antd";
import {
  ApiOutlined,
  UserOutlined,
  EyeOutlined,
  ShareAltOutlined,
  CloudUploadOutlined,
  InfoCircleOutlined
} from "@ant-design/icons";
import {
  shareProject,
  unshareProject,
  getSharedUsers,
} from "../../services/Project";
import shareIcon from "../../assets/images/share.svg";
import { useSelector } from "react-redux";

const ShareModal = ({
  visible,
  setVisible,
  users,
  selectedPrj,
  selectedPrjId,
}) => {
  const profile = useSelector((state) => state.profile);
  const [selectedUser, setSelectedUser] = useState();
  const [selectedPermission, setSelectedPermission] = useState("1");
  const [shareLoading, setShareLoading] = useState();
  const [listShared, setListShared] = useState();
  const [selectedRecord, setSelectedRecord] = useState(null);

  useEffect(() => {
    setSelectedRecord(selectedPrj);
    if (selectedPrjId) {
      const subscription = getSharedUsers(selectedPrjId).subscribe((data) => {
        setListShared(data);
        //setVisible(true);
        return () => {
          subscription.unsubscribe();
        };
      });
    }
  }, [selectedPrjId, shareLoading]);

  const onCancel = () => {
    setVisible(false);
    setSelectedRecord();
    setSelectedUser();
    setSelectedPermission("1");
  };
  const handleOk = () => {
    if (selectedUser && selectedPermission) {
      share(selectedPrj.id, selectedUser);
    } else {
      message.error("Please select user to share.");
    }
  };

  const share = (id, user) => {
    setShareLoading(true);
    const subscription = shareProject(
      id,
      user,
      parseInt(selectedPermission, 10)
    ).subscribe((data) => {
      
      if(!data?.message)
      {
        message.success("Shared Successful!");
        setShareLoading(false);
        return () => {
          subscription.unsubscribe();
        };
      }
    });
  };

  const unShare = (id, user) => {
    const subscription = unshareProject(id, user).subscribe((data) => {
      
      if(!data?.message)
      {
        message.success("Un-Shared Successful!");
        setShareLoading(!shareLoading);
        return () => {
          subscription.unsubscribe();
        };
      }
    });
  };
  return (
    <Modal
      title={
        <>
          {<img src={shareIcon} width={30} />}
          Share your project
          &nbsp;
          <Tooltip
            title={<>
              <p>
                Users with <span style={{color: "gray"}}><EyeOutlined /> Viewer</span> role can download.
                </p>
                <p>
                Users with <span style={{color: "#faad14"}}><CloudUploadOutlined /> Editor</span> role can upload and download also.
              </p>
              </>
            }
          >
            <InfoCircleOutlined />
          </Tooltip>
          <p style={{ margin: 0 }}>
            <small>Manage users can access to your project</small>
          </p>
        </>
      }
      visible={visible}
      onCancel={onCancel}
      footer={
        <>
          <h4>
            <UserOutlined /> Recipients
          </h4>
          <List
            style={{ textAlign: "left" }}
            bordered
            itemLayout="horizontal"
            size="default"
          >
            {listShared &&
              listShared
                .filter((share) => share.username !== profile.userName)
                .map((item) => (
                  <List.Item
                    key={item.title}
                    extra={
                      <Button
                        type="link"
                        danger
                        size="small"
                        onClick={() => unShare(selectedRecord.id, item.userId)}
                        icon={<ApiOutlined style={{ fontSize: 17 }} />}
                      >
                        Un-share
                      </Button>
                    }
                  >
                    <List.Item.Meta
                      avatar={
                        <Avatar
                          style={{
                            backgroundColor: "#87d068",
                          }}
                        >
                          {item.username.toUpperCase().charAt(0)}
                        </Avatar>
                      }
                      title={item.username}
                      description={
                        item.permissionId === 1 ? (
                          <span style={{color: "gray"}}><EyeOutlined /> Viewer</span>
                        ) : (
                          <span style={{color: "#faad14"}}><CloudUploadOutlined /> Editor</span>
                        )
                      }
                    />
                  </List.Item>
                ))}
          </List>
        </>
      }
    >
      <h4>Select recipients</h4>
      <Row gutter={[5, 5]}>
        <Col span={10}>
          <Select
            mode={"multiple"}
            style={{ width: "100%" }}
            onChange={(val) => setSelectedUser(val)}
            suffixIcon={<UserOutlined />}
            placeholder={"Select user to share"}
            value={selectedUser}
          >
            {users &&
              users.map((item) => (
                <Select.Option key={item.uuid} value={item.uuid}>
                  <UserOutlined /> {item.userName}
                </Select.Option>
              ))}
          </Select>
        </Col>
        <Col span={8}>
          <Select
            style={{ width: "100%" }}
            onChange={(val) => setSelectedPermission(val)}
            placeholder={"Select permission"}
            
            value={selectedPermission}
          >
            <Select.Option key={"1"} value={"1"} style={{ color: "gray" }}>
              <EyeOutlined /> Viewer
            </Select.Option>
            <Select.Option key={"7"} value={"7"} style={{ color: "#faad14" }}>
              <CloudUploadOutlined /> Editor
            </Select.Option>
          </Select>
        </Col>
        <Col span={6}>
          <Button
            block
            onClick={handleOk}
            type="primary"
            icon={<ShareAltOutlined style={{ fontSize: 17 }} />}
            style={{ height: 32, lineHeight: "20px" }}
          >
            Share
          </Button>
        </Col>
      </Row>
    </Modal>
  );
};

export default ShareModal;
