/*!
  =========================================================
  * Muse Ant Design Dashboard - v1.0.0
  =========================================================
  * Product Page: https://www.creative-tim.com/product/muse-ant-design-dashboard
  * Copyright 2021 Creative Tim (https://www.creative-tim.com)
  * Licensed under MIT (https://github.com/creativetimofficial/muse-ant-design-dashboard/blob/main/LICENSE.md)
  * Coded by Creative Tim
  =========================================================
  * The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/
import { useState, useEffect } from "react";

import {
  Row,
  Col,
  Card,
  Button,
  List,
  Descriptions,
  Avatar,
  Input,
  Space,
  Statistic,
  Tag,
  Table,
  Tabs
} from "antd";

import {
  FacebookOutlined,
  TwitterOutlined,
  InstagramOutlined,
  UserOutlined,
  SearchOutlined,
  GlobalOutlined,
  DatabaseOutlined,
  CloudDownloadOutlined,
  CheckCircleOutlined,
  CloseCircleOutlined,
  SwapOutlined
} from "@ant-design/icons";
import { CSVLink } from "react-csv";
import dollarIcon from "./../assets/images/dollar.svg";
import dollarSpendIcon from "./../assets/images/dollar-spend.svg";
import teamIcon from "./../assets/images/microsoft-team.svg";
import translationIcon from "./../assets/images/translation.svg";
import { useSelector } from "react-redux";
import { ROLE, ROLE_DES, PARENT_ROLE, STAFF_ROLE, COUNTRIES, STATUS } from "../common/const";
import { formatBytes } from "./../common/util";
import {
  getMyHistories,
} from "../services/Translation";
import {
  getTeams,
} from "./../services/Team";
import {
  getUsers,
} from "./../services/User";
import {
  getSummary,
} from "../services/Profile";
import moment from "moment";
import { APP_NAME, PageTitle, SLOGAN } from "../auth";

function Profile() {
  const profile = useSelector((state) => state.profile);
  const [search, setSearch] = useState();
  const [loading, setLoading] = useState(false);

  const columns = [
    {
      title: "Created On",
      dataIndex: "createdOn",
      key: "createdOn",
      render: (lastModified, record) => (
        <span>{moment.utc(lastModified).local().fromNow()}</span>
      ),
      sorter: (a, b) => moment.utc(a.createdOn).local() - moment.utc(b.createdOn).local(),
    },
    {
      title: "File Name",
      dataIndex: "fileName",
      key: "fileName",
    },
    {
      title: "Status",
      dataIndex: "status",
      key: "status",
      render: (status, record) => (
        <span>{STATUS[status?.toLowerCase()]?.icon}</span>
      ),
    },
    {
      title: "Source",
      dataIndex: "sourceLanguage",
      key: "sourceLanguage",
      render: (sourceLanguage, record) => {
          const val = COUNTRIES.translation[sourceLanguage];
            return (
              <Tag className="language-tag">
                {val?.icon ? (
                  <div className={val?.icon} />
                ) : (
                  <div className="flag-icon">
                    <GlobalOutlined
                      style={{
                        fontSize: 13,
                        width: 10,
                      }}
                    />
                  </div>
                )}{" "}
                {COUNTRIES.translation[sourceLanguage]?.name}
              </Tag>
            );
      }
    },
    {
      title: "Target",
      dataIndex: "targetLanguage",
      key: "targetLanguage",
      render: (targetLanguage, record) => {
          const val = COUNTRIES.translation[targetLanguage];
            return (
              <Tag className="language-tag">
                {val?.icon ? (
                  <div className={val?.icon} />
                ) : (
                  <div className="flag-icon">
                    <GlobalOutlined
                      style={{
                        fontSize: 13,
                        width: 10,
                      }}
                    />
                  </div>
                )}{" "}
                {COUNTRIES.translation[targetLanguage]?.name}
              </Tag>
            );
      }
    },
    {
      title: "Cost",
      dataIndex: "translationCharged",
      key: "translationCharged",
      render: (translationCharged, record) =>{
        
        return <Tag color="lime">
          <span className="money">
            <img
              src={dollarIcon}
              style={{
                width: 15,
              }}
            />
            <span>
              {translationCharged>0?translationCharged:0}
            </span>
          </span>
        </Tag>
      },
      sorter: (a, b) => a.translationCharged - b.translationCharged,
    },
    {
      title: "Characters",
      dataIndex: "charactersCharged",
      key: "charactersCharged",
      render: (charactersCharged, record) => (
        <Tag color="volcano">
          {charactersCharged.toLocaleString() +
            " "}
          Chars
        </Tag>
      ),
      sorter: (a, b) => a.charactersCharged - b.charactersCharged,
    },
    {
      title: "Storage",
      dataIndex: "fileLength",
      key: "fileLength",
      render: (fileLength, record) => (
        <Tag>
          {formatBytes(fileLength, 1) + " "}
          <DatabaseOutlined />
        </Tag>
      ),
      sorter: (a, b) => a.fileLength - b.fileLength,
    },
  ];

  const data = [
    {
      title: "Admin",
      avatar: <UserOutlined />,
      description: "High level management account",
    },
  ];

  
  const [historyData, setHistoryData] = useState();
  const [refresh, setRefresh] = useState(false);
  useEffect(() => {
    setLoading(true);
    
    const subscription = getMyHistories(search).subscribe((res) => {
      setHistoryData(res.items);
      setLoading(false);
      return () => {
        subscription.unsubscribe();
      };
    });
  }, [refresh, search]);
  

  const [teams, setTeams] = useState();
  useEffect(() => {
    setLoading(true);
    if(profile.role === 2)
      {
        const subscription = getTeams("").subscribe((res) => {
          setTeams(res.items);
          setLoading(false);
          return () => {
            subscription.unsubscribe();
          };
        });
      }
    else if(profile.role === 1){
      {
        const subscription = getUsers("").subscribe((res) => {
          setTeams(res.items);
          setLoading(false);
          return () => {
            subscription.unsubscribe();
          };
        });
      }
    }
  }, [refresh, search]);

  const [summary, setSummary] = useState();
  useEffect(() => {
    setLoading(true);
    
    const subscription = getSummary().subscribe((res) => {
      setSummary(res);
      setLoading(false);
      return () => {
        subscription.unsubscribe();
      };
    });
  }, [refresh]);

  useEffect(() => {
    document.title = `${PageTitle.Profile} - ${APP_NAME}`;
  }, []); 

  return (
    <>
      <Card
        className="card-profile-head"
        bodyStyle={{ display: "none" }}
        title={
          <Row justify="space-between" align="middle" gutter={[24, 0]}>
            <Col span={24} md={12} className="col-info">
              <Avatar.Group>
                <Avatar size={74} shape="square" icon={<UserOutlined />} />

                <div className="avatar-info">
                  <h4 className="font-semibold m-0">{profile?.userName}</h4>
                  <p>{<Tag>{ROLE[profile?.role]}</Tag>}</p>
                </div>
              </Avatar.Group>
            </Col>
            <Col
              span={24}
              md={12}
              style={{
                display: "flex",
                alignItems: "center",
                justifyContent: "flex-end",
              }}
            >
              <h1 className={"money"}>
                <img
                  src={dollarIcon}
                  style={{
                    width: 25,
                  }}
                />
                <span>{profile?.budget.toLocaleString(undefined, {minimumFractionDigits: 2})}</span>
              </h1>
            </Col>
          </Row>
        }
      ></Card>

      <Row gutter={[24, 0]}>
        <Col span={24} md={8} className="mb-24">
          <Card bordered={false}>
            <Statistic
              title="Total cost"
              value={summary?.usageCost.toLocaleString(undefined, {minimumFractionDigits: 2})}
              precision={2}
              valueStyle={{
                color: '#278664',
              }}
              prefix={
                <img
                  src={dollarIcon}
                  style={{
                    width: 25,
                  }}
                />}
            />
          </Card>
        </Col>
        <Col span={24} md={8} className="mb-24">
          <Card bordered={false}>
            <Statistic
              title="Total Storages"
              value={formatBytes(summary?.usageStorage)}
              precision={2}
              valueStyle={{
                color: 'gray',
              }}
              prefix={
                <DatabaseOutlined />}
            />
          </Card></Col>
          <Col span={24} md={8} className="mb-24">
            <Card bordered={false}>
            <Statistic
                title="Total translations"
                value={summary?.totalTranslate}
                precision={0}
                prefix={<SwapOutlined />}
                suffix={<>
                  <Tag icon={<CheckCircleOutlined />} color="success">
                    {summary?.translateSuccessed} success
                  </Tag>
                  <Tag icon={<CloseCircleOutlined />} color="error">
                  {summary?.translateFailed} errors
                  </Tag></>}
              />
            </Card></Col>
      </Row>
      <Tabs defaultActiveKey="0">
        <Tabs.TabPane key="0" tab="Profile Information">
          
      <Row gutter={[24, 0]}>
        <Col span={24} md={12} className="mb-24">
          <Card
            bordered={false}
            title={<h6 className="font-semibold m-0">Profile Information</h6>}
            className="header-solid h-full card-profile-information"
            bodyStyle={{ paddingBottom: 16 }}
          >
          <p className="text-dark"> <blockquote>{ROLE_DES[profile?.role]}</blockquote> </p>
          <hr className="my-25" />
            <Descriptions>
              <Descriptions.Item label="User Name" span={3}>
                {profile?.userName}
              </Descriptions.Item>
              <Descriptions.Item label="FSU in charge" span={3}>
                {profile?.fsu}
              </Descriptions.Item>
              <Descriptions.Item label="Role" span={3}>
                {<Tag>{ROLE[profile?.role]}</Tag>}
              </Descriptions.Item>
              <Descriptions.Item label="Total" span={3}>
                <span className="money">
                  <img
                    src={dollarIcon}
                    style={{
                      width: 15,
                    }}
                  />
                  <span>{profile?.totalDeposit.toLocaleString(undefined, {minimumFractionDigits: 2})}</span>
                </span>
              </Descriptions.Item>
              <Descriptions.Item label="Credit" span={3}>
                <span className="money">
                  <img
                    src={dollarIcon}
                    style={{
                      width: 15,
                    }}
                  />
                  <span>{profile?.budget.toLocaleString(undefined, {minimumFractionDigits: 2})}</span>
                </span>
              </Descriptions.Item>
              <Descriptions.Item label="Spend" span={3}>
                <span className="money-spend">
                  -
                  <img
                    src={dollarSpendIcon}
                    style={{
                      width: 15,
                    }}
                  />
                  <span>{profile?.spended.toLocaleString(undefined, {minimumFractionDigits: 2})}</span>
                </span>
              </Descriptions.Item>
              <Descriptions.Item label="Email" span={3}>
                {profile?.email}
              </Descriptions.Item>
            </Descriptions>
            </Card>
            </Col>
        <Col span={24} md={12} className="mb-24">
          <Card
            bordered={false}
            title={<h6 className="font-semibold m-0">Management</h6>}
            className="header-solid h-full card-profile-information"
            bodyStyle={{ paddingBottom: 16 }}
          >
            <List
              itemLayout="horizontal"
              header="Direct Manager"
              dataSource={data}
              split={false}
              className="conversations-list"
              renderItem={(item) => (
                <List.Item
                  actions={
                    profile.role !== 1 && [
                      <Button type="link">
                        <a href={`https://teams.microsoft.com/l/chat/0/0?users=${profile.directedBy}`} target="_blank">
                        <img
                          src={teamIcon}
                          style={{
                            width: 25,
                          }}
                        /></a>
                      </Button>,
                    ]
                  }
                >
                  <List.Item.Meta
                    avatar={
                      <UserOutlined style={{fontSize: 40}} />
                    }
                    title={
                      <a href={`mailto:${profile.directedBy}`}>
                        {profile.directedBy?profile.directedBy:"support@dxgarage.io"}
                      </a>
                    }
                    description={<Tag>{PARENT_ROLE[profile.role]}</Tag>}
                  />
                </List.Item>
              )}
            />
            
            {/*
            {teams && <>
            <hr/>
            <List
              itemLayout="horizontal"
              header="Team"
              dataSource={teams}
              split={false}
              className="conversations-list"
              style={{overflow: "auto", height: 250}}
              renderItem={(item) => (
                <List.Item
                  actions={
                    [
                      <Button type="link">
                        <a href={`https://teams.microsoft.com/l/chat/0/0?users=${item.email}`} target="_blank">
                        <img
                          src={teamIcon}
                          style={{
                            width: 25,
                          }}
                        /></a>
                      </Button>,
                    ]
                  }
                >
                  <List.Item.Meta
                    avatar={
                      <UserOutlined style={{fontSize: 40}} />
                    }
                    title={
                      <a href={`mailto:${item.email}`}>
                        {item.email}
                      </a>
                    }
                    description={<Tag>{STAFF_ROLE[profile.role]}</Tag>}
                  />
                </List.Item>
              )}
            /></>}
            
            */}
            </Card>
            </Col>
        </Row>
          </Tabs.TabPane>
        <Tabs.TabPane key="1" tab="History">
          
      <Row gutter={[24, 0]}>
        <Col span={24} md={24} className="mb-24">
          <Card
            className="tabled"
            title={<h6 className="font-semibold m-0">History</h6>}
               
        extra={<Space><Input placeholder="" prefix={<SearchOutlined/>} size="small" onChange={(event) => setSearch(event.target.value)}/>
        {data && <CSVLink
        filename={`Export_${new Date().getTime()}.csv`}
        data={data}
        className="btn btn-primary"
      ><Button type={"primary"} icon={<CloudDownloadOutlined />}>
        Download</Button>
      </CSVLink>}</Space>
        }
          >
            <Row gutter={[24, 0]} style={{ margin: 0 }}>
              <Col xs="24" xl={24}>
                <Row gutter={[24, 0]}>
                  <Col xs="24" xl={24}>
                    <div className="table-responsive">
                      {data && (
                        <Table
                          rowKey={"id"}
                          columns={columns}
                          dataSource={historyData}
                          className="ant-border-space"
                          pagination={{
                            defaultPageSize: 10,
                          }}
                          loading={loading}
                          scroll={{ x: 400 }}
                        />
                      )}
                    </div>
                  </Col>
                </Row>
              </Col>
            </Row>
          </Card>
        </Col>
        </Row>
          </Tabs.TabPane>
      </Tabs>
    </>
  );
}

export default Profile;
