import { useEffect, useState } from "react";
import moment from "moment";
import {
  Row,
  Col,
  Card,
  Table,
  Modal,
  message,
  Button,
  Tag,
  Form,
  List,
  Tooltip,
  Alert,
  Space,
  Input,
} from "antd";

import {
  FolderAddOutlined,
  CloudDownloadOutlined,
  ForwardOutlined,
  GlobalOutlined,
  EditOutlined,
  ShareAltOutlined,
  SyncOutlined,
  DatabaseOutlined,
  RedoOutlined,
  PaperClipOutlined,
  SearchOutlined,
} from "@ant-design/icons";
import {
  getDocuments,
  requestDownload,
  download,
  retryDownload,
  getUsers,
  updateProject,
  removeProject,
  getMyProjects,
  deleteDocumentsById,
  setProjectDefault,
} from "../../services/Project";

import { STATUS, EXTENSION_ICONS, COUNTRIES } from "../../common/const";
import { formatBytes } from "../../common/util";
import EditModal from "./EditModal";
import CreateModal from "./CreateModal";
import ShareModal from "./ShareModal";
import projectIcon from "../../assets/images/project.svg";
import dollarIcon from "../../assets/images/dollar.svg";
import expiredIcon from "../../assets/images/expired.png";
import downloadingIcon from "../../assets/images/download.gif";
import trashIcon from "../../assets/images/trashBtn.svg";
import { APP_NAME, PageTitle, SLOGAN } from "../../auth";
import ic_my_projects from "../../assets/icons/ic-my-projects.png";

function Project() {
  const [data, setData] = useState();
  const [refresh, setRefresh] = useState(false);
  const [subRefresh, setSubRefresh] = useState(false);
  const [loading, setLoading] = useState(false);
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [users, setUsers] = useState();
  const [selectedPrj, setSelectedPrj] = useState();
  const [selectedPrjId, setSelectedPrjId] = useState();
  const [modalVisible, setModalVisible] = useState(false);
  const [search, setSearch] = useState();
  const MAX_LENGTH = 40;

  const handleDelete = (id, record) => {
    Modal.confirm({
      title: "Delete Project",
      content: `Are you sure you want to delete '${record.title}' project?`,
      onOk: async () => {
        const subscription = removeProject(id).subscribe((data) => {
          if (!data?.message) {
            setRefresh(!refresh);
            message.success("delete successful!");
            return () => {
              subscription.unsubscribe();
            };
          }
        });
      },
      onCancel: () => {
        // Handle cancellation if needed
        console.log("Action canceled");
      },
    });
  };

  const handleSetDefaultProject = (projectId) => {
    if (projectId) {
      const subscription = setProjectDefault(projectId).subscribe((res) => {
        if (!res?.message) {
          setRefresh(!refresh);
          setModalVisible(false);
          message.success("The project has been set to default!");
          return () => {
            subscription.unsubscribe();
          };
        }
        setLoading(false);
      });
    }
  };

  useEffect(() => {
    setSelectedPrj();
    setSelectedPrjId();
    setLoading(true);
    const subscription = getMyProjects().subscribe((data) => {
      if (data) {
        data.items.map((item) => {
          if (item.isDefault === true) {
            setSelectedProject(item.id);
            setExpandedRows([item.id]);
          }
        });
        setData(
          data.items.sort(function (a, b) {
            return new Date(b.lastModified) - new Date(a.lastModified);
          })
        );
      }
      setLoading(false);
      return () => {
        subscription.unsubscribe();
      };
    });
  }, [refresh]);

  useEffect(() => {
    const subscription = getUsers().subscribe((data) => {
      setUsers(data);
      return () => {
        subscription.unsubscribe();
      };
    });
  }, [refresh]);

  useEffect(() => {
    document.title = `${PageTitle.Project} - ${APP_NAME}`;
  }, []);

  const columns = [
    {
      title: "Project",
      dataIndex: "id",
      key: "id",
      render: (id, record) => {
        return (
          record.title && (
            <>
              {<img src={projectIcon} width={30} />}
              <span> {record.title}</span>
              <Button
                type="link"
                onClick={() => {
                  editForm.setFieldsValue({ title: record.title });
                  editForm.setFieldsValue({ description: record.description });
                  setSelectedPrjId(id);
                  setSelectedPrj(record);
                  setEditModalVisible(true);
                }}
                icon={<EditOutlined style={{ color: "gray", fontSize: 11 }} />}
                size={"large"}
                ghost
              ></Button>
              {record.isDefault ? <Tag>Default</Tag> : <></>}
            </>
          )
        );
      },
    },
    {
      title: "Description",
      dataIndex: "description",
      key: "description",
    },
    {
      title: "Cost",
      dataIndex: "translationCharged",
      key: "translationCharged",
      render: (translationCharged, record) => (
        <Tag color="lime">
          <span className="money">
            <img
              src={dollarIcon}
              style={{
                width: 15,
              }}
            />
            <span>{translationCharged > 0 ? translationCharged : 0}</span>
          </span>
        </Tag>
      ),
    },

    {
      title: "Storage",
      dataIndex: "containerSize",
      key: "containerSize",
      render: (containerSize, record) => (
        <Tag>
          {formatBytes(containerSize, 1) + " "}
          <DatabaseOutlined />
        </Tag>
      ),
    },
    {
      title: "Files",
      dataIndex: "totalFile",
      key: "totalFile",
      render: (totalFile, record) => (
        <>
          {totalFile ? (
            <>
              {totalFile} <PaperClipOutlined />
            </>
          ) : (
            <></>
          )}
        </>
      ),
    },
    {
      title: "Updated",
      dataIndex: "lastModified",
      key: "lastModified",
      render: (lastModified, record) => (
        <span>{moment.utc(lastModified).local().fromNow()}</span>
      ),
    },
    {
      title: "Action",
      dataIndex: "id",
      key: "id",
      align: "right",
      render: (id, record, index) => {
        return (
          <>
            <Button
              type="ghost"
              onClick={() => {
                handleSetDefaultProject(record.id);
              }}
              size={"large"}
              disabled={record.isDefault}
            >
              <Tooltip title="Set default">Set default</Tooltip>
            </Button>

            <Button
              type="link"
              onClick={() => {
                setSelectedPrjId(id);
                setSelectedPrj(record);
                setIsModalVisible(true);
              }}
              size={"large"}
            >
              <Tooltip title="SHARE">
                <ShareAltOutlined style={{ fontSize: 20 }} />
              </Tooltip>
            </Button>

            <Button
              type="link"
              danger
              onClick={() => {
                handleDelete(id, record);
              }}
            >
              <Tooltip title={"DELETE"}>
                {<img src={trashIcon} width={25} />}
              </Tooltip>
            </Button>
          </>
        );
      },
    },
  ];

  //EDIT
  const [editForm] = Form.useForm();

  const handleEditOk = async () => {
    try {
      await editForm.validateFields();
      onEditSubmit(editForm.getFieldsValue());
    } catch (error) {
      console.error("Validation Error:", error);
    }
  };
  const [editModalVisible, setEditModalVisible] = useState(false);

  const handleEditCancel = () => {
    setEditModalVisible(false);
  };

  const onEditSubmit = (formValues) => {
    const subscription = updateProject(
      selectedPrjId,
      formValues.title,
      formValues.description
    ).subscribe((data) => {
      if (!data?.message) {
        message.success("Update successful!");
        editForm.resetFields();
        setEditModalVisible(false);
        setRefresh(!refresh);
        setLoading(false);
        return () => {
          subscription.unsubscribe();
        };
      }
    });
  };
  const [expandedRows, setExpandedRows] = useState([]);
  const [downloadLoading, setDownloadLoading] = useState();

  const handleRowClick = (record) => {
    const isRowExpanded = expandedRows.includes(record.id);
    const newExpandedRows = isRowExpanded
      ? expandedRows.filter((rowKey) => rowKey !== record.id)
      : [record.id];
    setSelectedProject(newExpandedRows[0]);
    setExpandedRows(newExpandedRows);
  };

  const [selectedProject, setSelectedProject] = useState();
  const [subData, setSubData] = useState();
  useEffect(() => {
    if (selectedProject) {
      setLoading(true);
      const subscription = getDocuments(selectedProject).subscribe((data) => {
        setSubData(data);
        setLoading(false);
        return () => {
          subscription.unsubscribe();
        };
      });
    }
  }, [selectedProject, subRefresh]);

  const handleDownload = (fileId, lang) => {
    setLoading(true);
    setDownloadLoading({ fileId: fileId, language: lang });
    const subscription = requestDownload(
      selectedProject,
      fileId,
      lang
    ).subscribe((data) => {
      if (data?.fileUrl) {
        downloadWithFilename(data.fileUrl, data.fileName);
        //downloadBlob(data.fileUrl);
      }

      setLoading(false);
      setDownloadLoading();
      return () => {
        subscription.unsubscribe();
      };
    });
  };

  /*
  const downloadBlob = async (url) =>
  {
    try {
      const response = await fetch(url);
      const blob = await response.blob();
      const tempAnchor = document.createElement('a');
      tempAnchor.href = URL.createObjectURL(blob);
      tempAnchor.download = 'filename'; // Specify the filename here
      tempAnchor.click();
      URL.revokeObjectURL(tempAnchor.href);
      tempAnchor.remove();
    } catch (error) {
      console.error('Error downloading blob:', error);
    }
  }
  */

  function downloadWithFilename(url, filename) {
    var link = document.createElement("a");
    link.setAttribute("download", filename);
    link.href = url;
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  }

  const finalDownload = (fileName, fileUrl) => {
    setLoading(true);
    const subscription = download(selectedProject, fileName, fileUrl).subscribe(
      (subdata) => {
        setLoading(false);
        setDownloadLoading();
        return () => {
          subscription.unsubscribe();
        };
      }
    );
  };

  const removeDoc = (fileId) => {
    Modal.confirm({
      title: "Delete file?",
      content: `Are you sure you want to delete this file?`,
      onOk: async () => {
        const subscription = deleteDocumentsById(
          selectedProject,
          fileId
        ).subscribe((subdata) => {
          if (!subdata?.message) {
            message.success("File already be removed!");
            setSubRefresh(!subRefresh);
            return () => {
              subscription.unsubscribe();
            };
          }
        });
      },
      onCancel: () => {
        // Handle cancellation if needed
        console.log("Action canceled");
      },
    });
  };

  const handleKeyPress = (e) => {
    if (e.key === "Enter") {
      setSearch(e.target.value);
    }
  };

  return (
    <>
      <Card
        className="tabled"
        title={
          <>
            {<img src={ic_my_projects} width={30} />}{" "}
            <b style={{ verticalAlign: "top" }}>{PageTitle.Project}</b>
          </>
        }
        extra={
          <Space>
            <Input.Search
              placeholder=""
              size="small"
              onSearch={(value) => setSearch(value)}
              onPressEnter={handleKeyPress}
            />
            <Button
              size={"small"}
              type="primary"
              icon={<FolderAddOutlined style={{ fontSize: 15 }} />}
              onClick={() => setModalVisible(true)}
            >
              Create
            </Button>
          </Space>
        }
      >
        <Row gutter={[24, 0]} style={{ margin: 0 }}>
          <Col xs="24" xl={24}>
            <Row gutter={[24, 0]}>
              <Col xs="24" xl={24}>
                <Alert
                  banner
                  message={<></>}
                  description={
                    <>
                      All files are stored and processed by FPT storage located
                      in <div class="flag-icon flag-icon-jp"></div> Japan data
                      center.
                      <br />
                      Both the <b>original</b> files and translated file are
                      archived in <span style={{ color: "red" }}>14 days</span>{" "}
                      <b>before</b> automatically{" "}
                      <span style={{ color: "red" }}>deleted</span> by the{" "}
                      <b>AI translator system</b>.
                    </>
                  }
                  type="warning"
                  showIcon
                  closable
                />
                <br />
                <div className="table-responsive">
                  {data && (
                    <Table
                      rowKey={"id"}
                      columns={columns}
                      dataSource={data}
                      className="ant-border-space"
                      pagination={{
                        defaultPageSize: 10,
                        showSizeChanger: true,
                        total: data.length,
                        showTotal: (total, [x, y]) => (
                          <span>{`${x} - ${y} of ${total}`}</span>
                        ),
                      }}
                      loading={loading}
                      expandedRowRender={(record) => {
                        return (
                          subData && (
                            <List
                              itemLayout="horizontal"
                              dataSource={subData}
                              renderItem={(item, index) => (
                                <List.Item
                                  extra={
                                    <>
                                      {(item.status.toLowerCase() ===
                                        "notstarted" ||
                                        item.status.toLowerCase() ===
                                          "running") && (
                                        <Tooltip title={"refresh"}>
                                          <Button
                                            type={"link"}
                                            onClick={() =>
                                              setSubRefresh(!subRefresh)
                                            }
                                          >
                                            <RedoOutlined
                                              style={{ fontSize: 17 }}
                                            />
                                          </Button>
                                        </Tooltip>
                                      )}
                                      {item?.status.toLowerCase() ===
                                        "failed" && STATUS["failed"].icon}
                                      {Object.keys(COUNTRIES.translation).map(
                                        (key) => {
                                          const val =
                                            COUNTRIES.translation[key];
                                          if (key === item.language) {
                                            return (
                                              <Tag className="language-tag">
                                                {val.icon ? (
                                                  <div className={val.icon} />
                                                ) : (
                                                  <div className="flag-icon">
                                                    <GlobalOutlined
                                                      style={{
                                                        fontSize: 40,
                                                        width: 90,
                                                      }}
                                                    />
                                                  </div>
                                                )}{" "}
                                                {
                                                  COUNTRIES.translation[key]
                                                    .name
                                                }
                                              </Tag>
                                            );
                                          }
                                        }
                                      )}
                                      <ForwardOutlined />
                                      {item.translations.length === 0 && (
                                        <div
                                          style={{
                                            paddingLeft: 5,
                                          }}
                                        >
                                          <SyncOutlined
                                            spin
                                            style={{ color: "gray" }}
                                          />
                                          &nbsp;
                                          <Tag className="language-tag">
                                            <GlobalOutlined />
                                          </Tag>
                                          <Button
                                            type={"link"}
                                            disabled={true}
                                            style={{
                                              flex: "right",
                                              padding: 0,
                                            }}
                                          >
                                            <CloudDownloadOutlined
                                              style={{
                                                fontSize: 17,
                                              }}
                                            />
                                          </Button>
                                        </div>
                                      )}
                                      {item.translations.length !== 0 && (
                                        <div
                                          style={{
                                            paddingLeft: 5,
                                          }}
                                        >
                                          {item.translations &&
                                            item.translations.map((subitem) => {
                                              const val =
                                                COUNTRIES.translation[
                                                  subitem.language
                                                ];
                                              return (
                                                <p>
                                                  {
                                                    STATUS[
                                                      subitem.status.toLowerCase()
                                                    ].icon
                                                  }
                                                  &nbsp;
                                                  <Tag
                                                    color={
                                                      STATUS[
                                                        subitem.status.toLowerCase()
                                                      ].color
                                                    }
                                                    className="language-tag"
                                                  >
                                                    {val.icon ? (
                                                      <div
                                                        className={val.icon}
                                                      />
                                                    ) : (
                                                      <div className="flag-icon">
                                                        <GlobalOutlined
                                                          style={{
                                                            fontSize: 40,
                                                            width: 90,
                                                          }}
                                                        />
                                                      </div>
                                                    )}{" "}
                                                    {val.name}
                                                  </Tag>
                                                  {downloadLoading &&
                                                  downloadLoading.fileId ===
                                                    item.fileId &&
                                                  downloadLoading.language ===
                                                    subitem.language ? (
                                                    <Button
                                                      type={"link"}
                                                      style={{
                                                        flex: "right",
                                                        padding: 0,
                                                      }}
                                                    >
                                                      <img
                                                        src={downloadingIcon}
                                                        style={{ width: 20 }}
                                                      />
                                                    </Button>
                                                  ) : (
                                                    <Button
                                                      type={"link"}
                                                      disabled={
                                                        subitem.status.toLowerCase() !==
                                                        "succeeded"
                                                      }
                                                      onClick={() =>
                                                        handleDownload(
                                                          item.fileId,
                                                          subitem.language
                                                        )
                                                      }
                                                      style={{
                                                        flex: "right",
                                                        padding: 0,
                                                      }}
                                                    >
                                                      <CloudDownloadOutlined
                                                        style={{
                                                          fontSize: 17,
                                                        }}
                                                      />
                                                    </Button>
                                                  )}
                                                  <Button
                                                    type="link"
                                                    danger
                                                    onClick={() =>
                                                      removeDoc(item.fileId)
                                                    }
                                                  >
                                                    <Tooltip title={"DELETE"}>
                                                      {
                                                        <img
                                                          src={trashIcon}
                                                          width={25}
                                                        />
                                                      }
                                                    </Tooltip>
                                                  </Button>
                                                  <br />
                                                </p>
                                              );
                                            })}
                                        </div>
                                      )}
                                    </>
                                  }
                                >
                                  <List.Item.Meta
                                    avatar={
                                      <img
                                        width={50}
                                        src={
                                          EXTENSION_ICONS[
                                            item.fileName.slice(
                                              ((item.fileName.lastIndexOf(".") -
                                                1) >>>
                                                0) +
                                                2
                                            )
                                          ]
                                        }
                                      />
                                    }
                                    title={
                                      <>
                                        {STATUS[item.translations.status]}
                                        <Tooltip title={item.fileName}>
                                          {" "}
                                          {item.fileName.length <= MAX_LENGTH
                                            ? item.fileName
                                            : item.fileName.substring(
                                                0,
                                                MAX_LENGTH
                                              ) + "..."}
                                        </Tooltip>
                                      </>
                                    }
                                    description={
                                      <>
                                        <span>
                                          {moment
                                            .utc(item.createdDate)
                                            .local()
                                            .fromNow()}
                                        </span>
                                        &nbsp;
                                        <Tag>
                                          {formatBytes(item.length, 1) + " "}
                                          <DatabaseOutlined />
                                        </Tag>
                                        <Tag color="volcano">
                                          {item.characterCharge.toLocaleString() +
                                            " "}
                                          Chars
                                        </Tag>
                                        <Tag color="lime">
                                          <span className="money">
                                            <img
                                              src={dollarIcon}
                                              style={{
                                                width: 15,
                                              }}
                                            />
                                            <span>
                                              {item.translationCharge}
                                            </span>
                                          </span>
                                        </Tag>
                                        <Tooltip
                                          title={`expired date: ${moment
                                            .utc(item.expiredDate)
                                            .local()
                                            .toLocaleString()}`}
                                        >
                                          <img
                                            src={expiredIcon}
                                            style={{
                                              width: 15,
                                            }}
                                          />{" "}
                                          <span
                                            style={{
                                              color: "#C1666E",
                                            }}
                                          >
                                            {moment
                                              .utc(item.expiredDate)
                                              .local()
                                              .fromNow()}
                                          </span>
                                        </Tooltip>
                                      </>
                                    }
                                  />
                                </List.Item>
                              )}
                            />
                          )
                        );
                      }}
                      defaultExpandAllRows={false}
                      expandedRowKeys={expandedRows}
                      onExpand={(expanded, record) => handleRowClick(record)}
                    />
                  )}
                </div>
              </Col>
            </Row>
          </Col>
        </Row>
      </Card>

      <ShareModal
        visible={isModalVisible}
        setVisible={setIsModalVisible}
        users={users}
        selectedPrj={selectedPrj}
        selectedPrjId={selectedPrjId}
      />
      <CreateModal
        visible={modalVisible}
        setVisible={setModalVisible}
        refresh={refresh}
        setRefresh={setRefresh}
      />

      <EditModal
        visible={editModalVisible}
        onCancel={handleEditCancel}
        onOk={handleEditOk}
        form={editForm}
        initialValues={{
          title: selectedPrj?.title,
          description: selectedPrj?.description,
        }}
      />
    </>
  );
}

export default Project;
