import {
  Row,
  Col,
  Card,
  Table,
  Tag,
  Tooltip,
  Input,
  PageHeader,
  Space,
  Button,
  Tabs
} from "antd";
import { useEffect, useState } from "react";
import { useParams } from 'react-router-dom';

import { CSVLink } from "react-csv";
import {
  getHistories,
} from "../services/Translation";
import {
  getUserCreditHistory,
} from "../services/User";
import {
  getTeamCreditHistory,
} from "../services/Team";
import dollarSpendIcon from "./../assets/images/dollar-spend.svg";
import dollarIcon from "./../assets/images/dollar.svg";
import { formatBytes } from "./../common/util";
import { COUNTRIES, STATUS } from "./../common/const";
import moment from 'moment-timezone';

import {
  DatabaseOutlined,
  InfoCircleOutlined,
  GlobalOutlined,
  HistoryOutlined,
  SearchOutlined,
  CloudDownloadOutlined
} from "@ant-design/icons";

function History() {
  let { id, type } = useParams();
  const [data, setData] = useState();
  const [fundData, setFundData] = useState();
  const [refresh, setRefresh] = useState(false);
  const [loading, setLoading] = useState(false);

  const [search, setSearch] = useState();
  useEffect(() => {
    setLoading(true);
    
    const subscription = getHistories(id,search).subscribe((res) => {
      setData(res.items);
      setLoading(false);
      return () => {
        subscription.unsubscribe();
      };
    });
  }, [refresh, search]);

  useEffect(() => {
    setLoading(true);
    if("user" === type)
      {
        const subscription = getUserCreditHistory(id).subscribe((res) => {
          setFundData(res)
          setLoading(false);
          return () => {
            subscription.unsubscribe();
          };
        });
      }
      else if("team" === type){
        const subscription = getTeamCreditHistory(id).subscribe((res) => {
          setFundData(res)
          setLoading(false);
          return () => {
            subscription.unsubscribe();
          };
        });
      }
  }, [refresh]);

  const fundColumns = [
    {
      title: "Transaction Time",
      dataIndex: "createdOn",
      key: "createdOn",
      render: (createdOn, record) => {
        const userTimeZone = Intl.DateTimeFormat().resolvedOptions().timeZone;
        
        // Parse the createdOn time as UTC and convert to the user's time zone
        const localTime = moment.utc(createdOn).tz(userTimeZone).format('YYYY-MM-DD hh:mm A');
  
        return (
          <span>{localTime} - {moment(localTime).fromNow()}</span>
        );
      },
    },
    {
      title: "Amount",
      dataIndex: "amount",
      key: "amount",
      render: (amount, record) => {
      return <Tag color="lime">
        <span className={amount > 0 ? "money" : "money-spend"}>
          <img
            src={amount > 0 ? dollarIcon : dollarSpendIcon}
            style={{
              width: 15,
            }}
          />
          <span>
            {amount?.toLocaleString(undefined, { 
              minimumFractionDigits: 2, 
              maximumFractionDigits: 2  })}
          </span>
        </span>
      </Tag>
    }},
    {
      title: "Username",
      dataIndex: "depositUser",
      key: "Username",
      render: (username, record) => {
        return <span>{record?.depositUser != "" ? record?.depositUser : record?.withdrawUser}</span>
    },},
      {
        title: "Description",
        dataIndex: "description",
        key: "description",
        render: (description, record) => (
          // <span>{moment.utc(createdOn).local().fromNow()}</span>
          <span>{description}</span>
        ),}
  ]

  const columns = [
    {
      title: "Created On",
      dataIndex: "createdOn",
      key: "createdOn",
      render: (lastModified, record) => (
        <span>{moment.utc(lastModified).local().fromNow()}</span>
      ),
      sorter: (a, b) => moment.utc(a.createdOn).local() - moment.utc(b.createdOn).local(),
    },
    {
      title: "File Name",
      dataIndex: "fileName",
      key: "fileName",
    },
    {
      title: "Status",
      dataIndex: "status",
      key: "status",
      render: (status, record) => (
        <span>{STATUS[status?.toLowerCase()]?.icon}</span>
      ),
    },
    {
      title: "Source",
      dataIndex: "sourceLanguage",
      key: "sourceLanguage",
      render: (sourceLanguage, record) => {
          const val = COUNTRIES.translation[sourceLanguage];
            return (
              <Tag className="language-tag">
                {val?.icon ? (
                  <div className={val?.icon} />
                ) : (
                  <div className="flag-icon">
                    <GlobalOutlined
                      style={{
                        fontSize: 15,
                        width: 20,
                        padding:3,
                      }}
                    />
                  </div>
                )}{" "}
                {COUNTRIES.translation[sourceLanguage]?.name}
              </Tag>
            );
      }
    },
    {
      title: "Target",
      dataIndex: "targetLanguage",
      key: "targetLanguage",
      render: (targetLanguage, record) => {
          const val = COUNTRIES.translation[targetLanguage];
            return (
              <Tag className="language-tag">
                {val?.icon ? (
                  <div className={val?.icon} />
                ) : (
                  <div className="flag-icon">
                    <GlobalOutlined
                      style={{
                        fontSize: 15,
                        width: 20,
                        padding: "3px 0px 0px -3px",
                      }}
                    />
                  </div>
                )}{" "}
                {COUNTRIES.translation[targetLanguage]?.name}
              </Tag>
            );
      }
    },
    {
      title: "Cost",
      dataIndex: "translationCharged",
      key: "translationCharged",
      render: (translationCharged, record) =>{
        
        return <Tag color="lime">
          <span className="money">
            <img
              src={dollarIcon}
              style={{
                width: 15,
              }}
            />
            <span>
              {translationCharged>0?translationCharged:0}
            </span>
          </span>
        </Tag>
      },
      sorter: (a, b) => a.translationCharged - b.translationCharged,
    },
    {
      title: "Characters",
      dataIndex: "charactersCharged",
      key: "charactersCharged",
      render: (charactersCharged, record) => (
        <Tag color="volcano">
          {charactersCharged.toLocaleString() +
            " "}
          Chars
        </Tag>
      ),
      sorter: (a, b) => a.charactersCharged - b.charactersCharged,
    },
    {
      title: "Storage",
      dataIndex: "fileLength",
      key: "fileLength",
      render: (fileLength, record) => (
        <Tag>
          {formatBytes(fileLength, 1) + " "}
          <DatabaseOutlined />
        </Tag>
      ),
      sorter: (a, b) => a.fileLength - b.fileLength,
    },
  ];

  return (
    <>
      <PageHeader
        ghost={false}
        onBack={() => window.history.back()}
        title="Back to Users"
      >
      </PageHeader>
      <Card
        className="tabled"
        title={
          <>
            {<HistoryOutlined style={{fontSize:19}} />}{" "}
            <b style={{ verticalAlign: "top" }}>
              History{" "}
              <Tooltip
                title={
                  <smaller style={{ fontWeight: "normal" }}>
                    All histories of user
                  </smaller>
                }
              >
                <InfoCircleOutlined />
              </Tooltip>
            </b>
          </>
        }        
        extra={<Space>
          <Input placeholder="" prefix={<SearchOutlined/>} size="small" onChange={(event) => setSearch(event.target.value)}/>
        {data && <CSVLink
        filename={`Export_${new Date().getTime()}.csv`}
        data={data}
        className="btn btn-primary"
      ><Button type={"primary"} icon={<CloudDownloadOutlined />}>
        Download</Button>
      </CSVLink>}</Space>
        }
      >
        <Row gutter={[24, 0]} style={{ margin: 0 }}>
          <Col xs="24" xl={24}>
            <Row gutter={[24, 0]}>
              <Col xs="24" xl={24}>
                <Tabs defaultActiveKey="0">
                  <Tabs.TabPane key="0" tab="Translations">
                  <div className="table-responsive">
                    {data && (
                      <Table
                        rowKey={"id"}
                        columns={columns}
                        dataSource={data}
                        className="ant-border-space"
                        pagination={{
                          defaultPageSize: 10,
                        }}
                        loading={loading}
                      />
                    )}
                  </div></Tabs.TabPane>
                  <Tabs.TabPane key="1" tab="Credit">
                  <div className="table-responsive">
                    {fundData && (
                      <Table
                        rowKey={"id"}
                        columns={fundColumns}
                        dataSource={fundData}
                        className="ant-border-space"
                        pagination={{
                          defaultPageSize: 10,
                        }}
                        loading={loading}
                      />
                    )}
                  </div>
                    </Tabs.TabPane>
                </Tabs>
              </Col>
            </Row>
          </Col>
        </Row>
      </Card>
    </>
  );
}

export default History;
