import {
  Row,
  Col,
  Card,
  Timeline,
  Upload,
  message,
  Button,
  Typography,
  Select,
  Tooltip,
  List,
  Tag,
} from "antd";
import moment from "moment";
import {
  getMyProjects,
  getSharedProjects,
  getDocumentsByIds,
  requestDownload,
  download,
} from "../services/Project";
import { upload, translate } from "../services/Translation";
import "./../../node_modules/flag-icon-css/css/flag-icons.min.css";
import {
  STATUS,
  EXTENSION_ICONS,
  FILE_TYPES,
  ALLOW_EXT,
} from "./../common/const";

import { formatBytes } from "./../common/util";
import translationIcon from "./../assets/images/translation.svg";
import projectIcon from "./../assets/images/project.svg";
import uploadFileIcon from "./../assets/images/upload-file.png";
import downloadingIcon from "./../assets/images/download.gif";
import dollarIcon from "./../assets/images/dollar.svg";
import expiredIcon from "./../assets/images/expired.png";
import scrollIcon from "./../assets/images/scroll-down.svg";
import translatingIcon from "./../assets/images/translating.gif";
import ic_docs_translator from "../assets/icons/ic-document-translator.png"

import {
  ClockCircleOutlined,
  DeleteOutlined,
  UserOutlined,
  InfoCircleOutlined,
  GlobalOutlined,
  DatabaseOutlined,
  LoadingOutlined,
  ForwardOutlined,
  SyncOutlined,
  CloudDownloadOutlined,
  CheckCircleOutlined,
} from "@ant-design/icons";
import { useEffect, useState } from "react";

// Images
import langIcon from "../assets/images/lang.svg";
import targetIcon from "../assets/images/target.svg";
import { COUNTRIES } from "./../common/const";
import { APP_NAME, PageTitle, SLOGAN } from "../auth";
function Translation() {
  const { Title, Text } = Typography;
  const { Option, OptGroup } = Select;
  const [fileList, setFileList] = useState([]);
  const [source, setSource] = useState("ja");
  const [targets, setTargets] = useState(["en"]);
  const [uploading, setUploading] = useState(false);
  const [refresh, setRefresh] = useState(false);
  const [loading, setLoading] = useState(false);
  const [downloadLoading, setDownloadLoading] = useState();
  const [subRefresh, setSubRefresh] = useState(false);
  const [selectedProject, setSelectedProject] = useState();
  //const allowedExtensions = ["docx", "pdf", "doc", "txt", "ppt", "pptx", "tsv"];
  const allowedExtensions = FILE_TYPES.map((item) => item.fileExtensions)
    .reduce(function (pre, cur) {
      return pre.concat(cur);
    })
    .filter((item) => ALLOW_EXT.includes(item));
  const COMMON_LANGS = ["en", "ja", "vi", "ko", "zh-Hant", "de", "fr"];
  const DEFAULT_LANGS = ["en", "ja", "vi"];
  const maxFileSize = 30 * 1024 * 1024; // 5 MB
  const isExtensionAllowed = (fileName) => {
    const extension = fileName.slice(
      ((fileName.lastIndexOf(".") - 1) >>> 0) + 2
    );
    return allowedExtensions.includes("." + extension.toLowerCase());
  };

  const [translatedFiles, setTranslatedFiles] = useState([]);
  const [translatedFileIds, setTranslatedFileIds] = useState([]);
  const [reload, setReload] = useState(false);
  const handleUpload = () => {
    const formData = new FormData();
    fileList.forEach((file) => {
      formData.append("FileDetails", file);
      formData.append("FileType", file.type);
    });
    setUploading(true);
    // You can use any AJAX library you like

    const subscription = translate(
      selectedProject,
      source,
      targets,
      fileIds
    ).subscribe((res) => {
      if (!res?.message) {
        setTranslatedFileIds(res);
        setFileList([]);
        message.success(
          "Files are translating. Wait for system process in few minutes"
        );
        setUploading(false);
        setReload(true);
        setSubRefresh((prev) => !prev);
        setFileIds([]);
        return () => {
          subscription.unsubscribe();
        };
      } else {
        setUploading(false);
        setTranslatedFileIds([]);
        setFileList([]);
      }
    });
  };

  useEffect(() => {
    if (reload) {
      let statusInterval = setInterval(() => {
        setTranslatedFiles((arr) => {
          if (arr.length > 0) {
            let done = true;
            arr?.map((item) => {
              if (item.status !== "Succeeded" && item.status !== "Failed") {
                done = false;
              }
            });
            if (done) {
              clearInterval(statusInterval);
              setReload(false);
            } else {
              setSubRefresh((prev) => !prev);
            }
          } else {
            setSubRefresh((prev) => !prev);
          }
          return arr;
        });
      }, 10000);
    }
  }, [reload]);

  const getDocumentStatus = (arr) => {
    setTranslatedFiles([]);
    setTranslatedFileIds([]);
    arr?.forEach((item) => {
      const subscription = getDocumentsByIds(
        item.containerId,
        item.fileId
      ).subscribe((res) => {
        setTranslatedFiles((prevItems) => [...prevItems, res[0]]);
        setTranslatedFileIds((prevItems) => [
          ...prevItems,
          { fileId: res[0].fileId, containerId: res[0].containerId },
        ]);
        return () => {
          subscription.unsubscribe();
        };
      });
    });
  };
  useEffect(() => {
    if (translatedFileIds.length > 0) {
      getDocumentStatus(translatedFileIds);
    }
  }, [subRefresh]);

  useEffect(() => {
    document.title = `${PageTitle.Docs} - ${APP_NAME}`;
  }, []); 

  const handleDownload = (fileId, lang) => {
    setLoading(true);
    setDownloadLoading({ fileId: fileId, language: lang });
    const subscription = requestDownload(
      selectedProject,
      fileId,
      lang
    ).subscribe((data) => {
      if (data?.fileUrl) {
        downloadWithFilename(data.fileUrl, data.fileName);
        //downloadBlob(data.fileUrl);
      }

      setLoading(false);
      setDownloadLoading();
      return () => {
        subscription.unsubscribe();
      };
    });
  };

  function downloadWithFilename(url, filename) {
    var link = document.createElement("a");
    link.setAttribute("download", filename);
    link.href = url;
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  }

  const finalDownload = (fileName, fileUrl) => {
    setLoading(true);
    const subscription = download(selectedProject, fileName, fileUrl).subscribe(
      (subdata) => {
        setLoading(false);
        setDownloadLoading();
        return () => {
          subscription.unsubscribe();
        };
      }
    );
  };

  const [fileIds, setFileIds] = useState([]);
  const props = {
    onRemove: (file) => {
      const removedFileList = fileIds.filter(
        (item) => item.fileName !== file.name
      );
      setFileList(removedFileList);

      const index = fileList.indexOf(file);
      const newFileList = fileList.slice();
      newFileList.splice(index, 1);
      setFileList(newFileList);
    },
    beforeUpload: (file) => {
      return false;
    },
    onChange: (info) => {
      setUploading(true);
      if (!isExtensionAllowed(info.file.name)) {
        message.error(
          <>You can only upload {allowedExtensions.join("|")} file!</>
        );
        return false; // Block the upload
      }
      const isAllowedSize = info.file.size <= maxFileSize;
      if (!isAllowedSize) {
        message.error(
          `File size must be less than ${maxFileSize / (1024 * 1024)}MB!`
        );
        return false; // Block the upload
      }
      setFileList(info.fileList);
      const subscription = upload([info.file]).subscribe((res) => {
        if (!res?.message) {
          message.success("Upload successfully.");
          setFileIds((prev) => [...prev, res[0]]);
          setUploading(false);
          return () => {
            subscription.unsubscribe();
          };
        } else {
          setFileList([]);
        }
      });
    },
    multiple: true,
    fileList: fileList,
    showUploadList: false,
    disabled: reload || uploading,
  };

  const [projects, setProjects] = useState([]);
  const [sharedProjects, setSharedProjects] = useState([]);
  useEffect(() => {
    setLoading(true);

    const subscription = getMyProjects().subscribe((data) => {
      setProjects(
        data.items.sort(function (a, b) {
          return new Date(b.lastModified) - new Date(a.lastModified);
        })
      );
      setSelectedProject(
        data.items.find((item) => item.isDefault === true)?.id
      );
      setLoading(false);
      return () => {
        subscription.unsubscribe();
      };
    });
  }, [refresh]);
  useEffect(() => {
    setLoading(true);
    const subscription = getSharedProjects().subscribe((data) => {
      data.items.map((item) => {
        item.shared = true;
        return item;
      });
      setSharedProjects(
        data.items.sort(function (a, b) {
          return new Date(b.lastModified) - new Date(a.lastModified);
        })
      );
      setLoading(false);
      return () => {
        subscription.unsubscribe();
      };
    });
  }, [refresh]);
  const handleRemove = (file) => {
    const removedFileList = fileIds.filter(
      (item) => item.fileName !== file.name
    );
    setFileList(removedFileList);

    const index = fileList.indexOf(file);
    const newFileList = fileList.slice();
    newFileList.splice(index, 1);
    setFileList(newFileList);
  };

  const sourceOptions = (key) => {
    const val = COUNTRIES.translation[key];
    return (
      <Option key={val.name} value={key}>
        <Col span={24}>
          {val.icon ? (
            <div className={val.icon} />
          ) : (
            <div className="flag-icon">
              <GlobalOutlined style={{ fontSize: 40, width: "100%" }} />
            </div>
          )}

          <div>
            <b>{val.name}</b> <br />
            <smaller>({val.nativeName})</smaller>
          </div>
        </Col>
      </Option>
    );
  };

  const targetOptions = (key) => {
    const val = COUNTRIES.translation[key];
    return (
      <Option
        key={val.name}
        value={key}
        disabled={
          targets.length >= 5 ? (targets.includes(key) ? false : true) : false
        }
      >
        <Col span={8}>
          {val.icon ? (
            <div className={val.icon} />
          ) : (
            <div className="flag-icon">
              <GlobalOutlined style={{ fontSize: 40, width: "100%" }} />
            </div>
          )}

          <div>
            <b>{val.name}</b> <br />
            <smaller>({val.nativeName})</smaller>
          </div>
        </Col>
      </Option>
    );
  };
  function handleScroll() {
    window.scroll({
      top: document.body.offsetHeight,
      left: 0,
      behavior: "smooth",
    });
  }
  const timelineList = [
    {
      title: (
        <Card
          bordered={false}
          type="inner"
          className="criclebox tablespace mb-24 no-shadow padding-body select-project"
          title={null}
          style={{
            background: "#f8f8f8",
            border: "1px solid #f9f9f9",
            width: "50%",
            margin: 0,
          }}
        >
          <Select
            style={{ width: "calc(100% + 20px)" }}
            onChange={(val) => {
              setSelectedProject(val);
              setTranslatedFileIds([]);
              setTranslatedFiles([]);
              setReload(false);
            }}
            placeholder={"Select project"}
            value={selectedProject}
          >
            {[...projects, ...sharedProjects].map((item) => {
              return (
                <Option key={item.id} value={item.id}>
                  {<img src={projectIcon} width={30} />} {item.title}{" "}
                  {item.shared ? (
                    <span style={{ color: "#ccc", float: "right" }}>
                      <UserOutlined /> shared
                    </span>
                  ) : (
                    <></>
                  )}
                </Option>
              );
            })}
          </Select>
        </Card>
      ),
      time: "",
      color: "green",
      visible: true,
    },
    {
      title:
        selectedProject &&
        (projects.find((item) => item.id === selectedProject)
          ? true
          : [15, 7].includes(
              sharedProjects.find((item) => item.id === selectedProject)
                .permissionId
            )) ? (
          <>
            <Card
              bordered={false}
              type="inner"
              className="criclebox tablespace mb-24 no-shadow padding-body"
              title={null}
              style={{ background: "#f8f8f8", border: "1px solid #f9f9f9" }}
            >
              <Row gutter={[20, 20]}>
                <Col span={12}>
                  <label>
                    Language{" "}
                    <Tooltip
                      title={
                        <smaller style={{ fontWeight: "normal" }}>
                          What is the language in the original document?
                        </smaller>
                      }
                    >
                      <InfoCircleOutlined />
                    </Tooltip>
                    <Button.Group className="common-lang">
                      {DEFAULT_LANGS.map((item) => {
                        const val = COUNTRIES.translation[item];

                        return (
                          <Button
                            className={source === item ? "active" : ""}
                            type="link"
                            onClick={() => setSource(item)}
                          >
                            {val.name}
                          </Button>
                        );
                      })}
                    </Button.Group>
                  </label>
                  <Select
                    style={{ width: "100%" }}
                    onChange={(val) => setSource(val)}
                    value={source}
                    disabled={reload}
                    className="lang-option-fix"
                    showSearch
                    placeholder="Select source"
                    optionFilterProp="children"
                    filterOption={(input, option) => {
                      return (
                        option.key.toLowerCase().indexOf(input.toLowerCase()) >=
                        0
                      );
                    }}
                    size="large"
                    dropdownRender={(menu) => (
                      <div className="lang-option">{menu}</div>
                    )}
                  >
                    <OptGroup label={"Common"}>
                      {COMMON_LANGS.map((lang) => {
                        return sourceOptions(lang);
                      })}
                    </OptGroup>
                    <OptGroup label={"Others"}>
                      {Object.keys(COUNTRIES.translation)
                        .filter((item) => !COMMON_LANGS.includes(item))
                        .map((key) => {
                          return sourceOptions(key);
                        })}
                    </OptGroup>
                  </Select>
                </Col>
                <Col span={12}>
                  <label>
                    <img
                      src={targetIcon}
                      width={20}
                      alt={"Document Translation"}
                    />{" "}
                    Target Languages{" "}
                    <Tooltip
                      title={
                        <small style={{ fontWeight: "normal" }}>
                          What are target languages you want to translate?
                        </small>
                      }
                    >
                      <InfoCircleOutlined />
                    </Tooltip>
                    <Button.Group className="common-lang">
                      {DEFAULT_LANGS.map((item) => {
                        const val = COUNTRIES.translation[item];

                        return (
                          <Button
                            className={targets.includes(item) ? "active" : ""}
                            type="link"
                            onClick={() => {
                              if (targets.includes(item)) {
                                setTargets((prev) =>
                                  [...prev].filter((sub) => sub !== item)
                                );
                              } else {
                                setTargets((prev) => [...prev, item]);
                              }
                            }}
                          >
                            {val.name}
                          </Button>
                        );
                      })}
                    </Button.Group>
                  </label>
                  <Select
                    style={{ width: "100%" }}
                    onChange={(val) => setTargets(val)}
                    value={targets}
                    disabled={reload}
                    className="lang-option-fix-multi"
                    mode={"multiple"}
                    showSearch
                    placeholder="Select targets"
                    optionFilterProp="children"
                    filterOption={(input, option) => {
                      return (
                        option.key.toLowerCase().indexOf(input.toLowerCase()) >=
                        0
                      );
                    }}
                    size="large"
                    dropdownRender={(menu) => (
                      <div className="lang-option">{menu}</div>
                    )}
                    maxTagCount={3}
                  >
                    <OptGroup label={"Common"}>
                      {COMMON_LANGS.map((lang) => {
                        return targetOptions(lang);
                      })}
                    </OptGroup>
                    <OptGroup label={"Others"}>
                      {Object.keys(COUNTRIES.translation)
                        .filter((item) => !COMMON_LANGS.includes(item))
                        .map((key) => {
                          return targetOptions(key);
                        })}
                    </OptGroup>
                  </Select>
                </Col>
                <Col span={24}>
                  <Upload.Dragger {...props}>
                    {fileList.length > 0 ? (
                      <>
                        {fileList.map((item) => {
                          return (
                            <div className="upload-item">
                              <img
                                width={50}
                                src={
                                  EXTENSION_ICONS[
                                    item.name.slice(
                                      ((item.name.lastIndexOf(".") - 1) >>> 0) +
                                        2
                                    )
                                  ]
                                }
                              />
                              <h4>
                                <b>{item.name}</b>
                              </h4>
                              {/* Display additional information or the file content here */}
                              <p>
                                <small>
                                  File size: {formatBytes(item.size, 1) + " "}
                                  <DatabaseOutlined />
                                </small>
                              </p>

                              {/* Add any other information you want to display */}
                              <Button
                                icon={<DeleteOutlined />}
                                type={"link"}
                                danger
                                onClick={() => handleRemove(item)}
                              >
                                Remove
                              </Button>
                            </div>
                          );
                        })}
                      </>
                    ) : (
                      <>
                        <p className="ant-upload-drag-icon">
                          <img src={uploadFileIcon} />
                        </p>
                        <p className="ant-upload-text">
                          Click or drag file to this area to upload
                        </p>

                        <small className="text-hint">
                          <Tooltip
                            title={
                              <p className="ant-upload-hint">
                                Support for multi target uploads. Strictly
                                prohibited from uploading company data or other
                                banned files.
                              </p>
                            }
                          >
                            <InfoCircleOutlined />
                          </Tooltip>
                          &nbsp; Supported formats:
                          {allowedExtensions.map((item) => (
                            <code>{item}</code>
                          ))}
                        </small>
                        <small className="text-hint">
                          Max file size: <code>30mb</code>
                        </small>
                      </>
                    )}
                  </Upload.Dragger>
                </Col>
              </Row>
              <br />
              <Row>
                <Col span={9}></Col>
                <Col span={6}>
                  <Button
                    block
                    type="primary"
                    onClick={handleUpload}
                    disabled={fileList.length === 0 || uploading}
                    loading={uploading}
                    icon={
                      <svg
                        class="svg-icon"
                        viewBox="0 0 1024 1024"
                        version="1.1"
                        width="25"
                        height="25"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <path d="M608 416h288c35.36 0 64 28.48 64 64v416c0 35.36-28.48 64-64 64H480c-35.36 0-64-28.48-64-64v-288H128c-35.36 0-64-28.48-64-64V128c0-35.36 28.48-64 64-64h416c35.36 0 64 28.48 64 64v288z m0 64v64c0 35.36-28.48 64-64 64h-64v256.032c0 17.664 14.304 31.968 31.968 31.968H864a31.968 31.968 0 0 0 31.968-31.968V512a31.968 31.968 0 0 0-31.968-31.968H608zM128 159.968V512c0 17.664 14.304 31.968 31.968 31.968H512a31.968 31.968 0 0 0 31.968-31.968V160A31.968 31.968 0 0 0 512.032 128H160A31.968 31.968 0 0 0 128 159.968z m64 244.288V243.36h112.736V176h46.752c6.4 0.928 9.632 1.824 9.632 2.752a10.56 10.56 0 0 1-1.376 4.128c-2.752 7.328-4.128 16.032-4.128 26.112v34.368h119.648v156.768h-50.88v-20.64h-68.768v118.272H306.112v-118.272H238.752v24.768H192z m46.72-122.368v60.48h67.392V281.92H238.752z m185.664 60.48V281.92h-68.768v60.48h68.768z m203.84 488H576L668.128 576h64.64l89.344 254.4h-54.976l-19.264-53.664h-100.384l-19.232 53.632z m33.024-96.256h72.864l-34.368-108.608h-1.376l-37.12 108.608zM896 320h-64a128 128 0 0 0-128-128V128a192 192 0 0 1 192 192zM128 704h64a128 128 0 0 0 128 128v64a192 192 0 0 1-192-192z" />
                      </svg>
                    }
                  >
                    {uploading ? "Uploading" : "Translate"}
                  </Button>
                </Col>
              </Row>
            </Card>
          </>
        ) : (
          <span>
            You have no permission to translate file inside this project
          </span>
        ),
      time: "",
      color: "green",
      visible: true,
    },
    {
      title: translatedFiles && (
        <Card
          bordered={false}
          type="inner"
          className="criclebox tablespace mb-24 no-shadow padding-body translation-download"
          title={null}
          style={{ background: "#f8f8f8", border: "1px solid #f9f9f9" }}
        >
          <List
            itemLayout="horizontal"
            dataSource={translatedFiles}
            renderItem={(item, index) => (
              <List.Item
                extra={
                  <>
                    {/*
                    (item.status === undefined ||
                      item?.status.toLowerCase() === "notstarted" ||
                      item?.status.toLowerCase() === "running") && (
                      <Tooltip title={"refresh"}>
                        <Button
                          type={"link"}
                          onClick={() => setSubRefresh(!subRefresh)}
                        >
                          <RedoOutlined style={{ fontSize: 17 }} />
                        </Button>
                      </Tooltip>
                    )
                    */}
                    {item?.status.toLowerCase() === "failed" &&
                      STATUS["failed"].icon}

                    {Object.keys(COUNTRIES.translation).map((key) => {
                      const val = COUNTRIES.translation[key];
                      if (key === item.language) {
                        return (
                          <Tag className="language-tag">
                            {val.icon ? (
                              <div className={val.icon} />
                            ) : (
                              <div className="flag-icon">
                                <GlobalOutlined
                                  style={{
                                    fontSize: 40,
                                    width: 90,
                                  }}
                                />
                              </div>
                            )}{" "}
                            {COUNTRIES.translation[key].name}
                          </Tag>
                        );
                      }
                    })}
                    <ForwardOutlined />
                    {item.translations.length === 0 && (
                      <div
                        style={{
                          paddingLeft: 5,
                        }}
                      >
                        <SyncOutlined spin style={{ color: "gray" }} />
                        &nbsp;
                        <Tag className="language-tag">
                          <GlobalOutlined />
                        </Tag>
                        <Button
                          type={"link"}
                          disabled={true}
                          style={{
                            flex: "right",
                            padding: 0,
                          }}
                        >
                          <CloudDownloadOutlined
                            style={{
                              fontSize: 17,
                            }}
                          />
                        </Button>
                      </div>
                    )}
                    {item.translations.length !== 0 && (
                      <div
                        style={{
                          paddingLeft: 5,
                        }}
                      >
                        {item.translations &&
                          item.translations.map((subitem) => {
                            const val = COUNTRIES.translation[subitem.language];
                            return (
                              <p style={{ margin: 0 }}>
                                {STATUS[subitem.status.toLowerCase()].icon}
                                &nbsp;
                                <Tag
                                  color={
                                    STATUS[subitem.status.toLowerCase()].color
                                  }
                                  className="language-tag"
                                >
                                  {val.icon ? (
                                    <div className={val.icon} />
                                  ) : (
                                    <div className="flag-icon">
                                      <GlobalOutlined
                                        style={{
                                          fontSize: 40,
                                          width: 90,
                                        }}
                                      />
                                    </div>
                                  )}{" "}
                                  {val.name}
                                </Tag>
                                {downloadLoading &&
                                downloadLoading.fileId === item.fileId &&
                                downloadLoading.language ===
                                  subitem.language ? (
                                  <Button
                                    type={"link"}
                                    style={{
                                      flex: "right",
                                      padding: 0,
                                    }}
                                  >
                                    <img
                                      src={downloadingIcon}
                                      style={{ width: 20 }}
                                    />
                                  </Button>
                                ) : (
                                  <Button
                                    type={"link"}
                                    disabled={
                                      subitem.status.toLowerCase() !==
                                      "succeeded"
                                    }
                                    onClick={() =>
                                      handleDownload(
                                        item.fileId,
                                        subitem.language
                                      )
                                    }
                                    style={{
                                      flex: "right",
                                      padding: 0,
                                    }}
                                  >
                                    <CloudDownloadOutlined
                                      style={{
                                        fontSize: 17,
                                        color: "#1890ff",
                                      }}
                                    />
                                  </Button>
                                )}
                                <br />
                              </p>
                            );
                          })}
                      </div>
                    )}
                  </>
                }
              >
                <List.Item.Meta
                  avatar={
                    <img
                      width={50}
                      src={
                        EXTENSION_ICONS[
                          item.fileName.slice(
                            ((item.fileName.lastIndexOf(".") - 1) >>> 0) + 2
                          )
                        ]
                      }
                    />
                  }
                  title={
                    <>
                      {STATUS[item.translations.status]}
                      <span> {item.fileName}</span>
                    </>
                  }
                  description={
                    <>
                      <span>
                        {moment.utc(item.createdDate).local().fromNow()}
                      </span>
                      &nbsp;
                      <Tag>
                        {formatBytes(item.length, 1) + " "}
                        <DatabaseOutlined />
                      </Tag>
                      <Tag color="volcano">
                        {item.characterCharge.toLocaleString() + " "}
                        Chars
                      </Tag>
                      <Tag color="lime">
                        <span className="money">
                          <img
                            src={dollarIcon}
                            style={{
                              width: 15,
                            }}
                          />
                          <span>{item.translationCharge}</span>
                        </span>
                      </Tag>
                      <Tooltip
                        title={`expired date: ${moment
                          .utc(item.expiredDate)
                          .local()
                          .toLocaleString()}`}
                      >
                        <img
                          src={expiredIcon}
                          style={{
                            width: 15,
                          }}
                        />{" "}
                        <span
                          style={{
                            color: "#C1666E",
                          }}
                        >
                          {moment.utc(item.expiredDate).local().fromNow()}
                        </span>
                      </Tooltip>
                    </>
                  }
                />
              </List.Item>
            )}
          />
        </Card>
      ),
      dot: translatedFiles.find(
        (item) =>
          item.status === undefined ||
          item?.status.toLowerCase() === "notstarted" ||
          item?.status.toLowerCase() === "running"
      ) ? (
        <img src={translatingIcon} style={{ minWidth: 25 }} />
      ) : (
        <Tooltip title={"Translation step"}>
          <CheckCircleOutlined style={{ color: "green", fontSize: 17 }} />
        </Tooltip>
      ),
      time: "",
      color: "green",
      visible: translatedFiles.length > 0,
    },
  ];

  const [showBtn, setShowBtn] = useState(false);
  const isBottom = (el: HTMLElement) => {
    return (
      document.documentElement.scrollTop + window.innerHeight + 1 >
      document.documentElement.scrollHeight
    );
  };

  const trackScrolling = () => {
    const wrappedElement = document.getElementById("container");
    if (isBottom(wrappedElement)) {
      setShowBtn(false);
    } else {
      setShowBtn(true);
    }
  };
  useEffect(() => {
    document.addEventListener("scroll", trackScrolling);
    return () => document.removeEventListener("scroll", trackScrolling);
  }, [trackScrolling]);

  return (
    <>
      <Card
        className="tabled"
        title={
          <>
            {<img src={ic_docs_translator} width={30} />}{" "}
            <b>
              {PageTitle.Docs}
            </b>
          </>
        }
      >
        <Timeline className="timelinelist">
          {timelineList.map((t, index) => {
            if (t.visible) {
              if (t.dot) {
                return (
                  <Timeline.Item color={t.color} key={index} dot={t.dot}>
                    <Title level={5}>{t.title}</Title>
                    <Text>{t.time}</Text>
                  </Timeline.Item>
                );
              }
              if (selectedProject || index === 0) {
                return (
                  <Timeline.Item color={t.color} key={index}>
                    <Title level={5}>{t.title}</Title>
                    <Text>{t.time}</Text>
                  </Timeline.Item>
                );
              }
              return (
                <Timeline.Item
                  color={"red"}
                  key={index}
                  dot={<ClockCircleOutlined className="timeline-clock-icon" />}
                >
                  <Title level={5}>{"..."}</Title>
                </Timeline.Item>
              );
            }
          })}
        </Timeline>
        {reload && translatedFiles?.length === 0 && (
          <>
            <LoadingOutlined
              style={{
                fontSize: 24,
              }}
              spin
            />{" "}
            Translating...
          </>
        )}
      </Card>
      {reload && showBtn && (
        <Button
          visible={showBtn}
          id={"container"}
          type={"link"}
          style={{
            position: "fixed",
            bottom: 10,
            left: "calc(50% + 20px)",
            width: 50,
            animation: "updown 2s ease infinite",
          }}
          onClick={handleScroll}
        >
          <img src={scrollIcon} />
        </Button>
      )}
    </>
  );
}

export default Translation;
