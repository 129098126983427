import { from } from "rxjs";
import { apiRootGet, apiRootPost, apiRootPut, apiRootDelete } from "./RootAPI";

const rootPrefix = `/recycle-bins`;

export const getRecycles = (search) => {
  return from(
    apiRootGet(`${rootPrefix}/search?page=1&size=99999${search?'&keyword='+ search:""}`).then(
      async (res) => {
        return res;
      }
    )
  );
};

export const empty = () => {
  return from(
    apiRootPost(`${rootPrefix}/empty`)
  );
};

export const deleteDocs = (ids) => {
  return from(
    apiRootPost(`${rootPrefix}/delete-document`,ids)
  );
};

export const restoreDocs = (ids) => {
  return from(
    apiRootPost(`${rootPrefix}/restore-document`,ids)
  );
};
