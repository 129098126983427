import {
  Row,
  Col,
  Card,
  Table,
  Tag,
  Tooltip,
  Input,
  Modal,
  Space,
  Button, message
} from "antd";
import { useEffect, useState } from "react";
import { useParams } from 'react-router-dom';
import moment from "moment";
import { CSVLink } from "react-csv";
import {
  deleteDocs,
  empty,
  getRecycles,
  restoreDocs,
} from "../services/Recycle";
import trashIcon from "./../assets/images/trashBtn.svg";
import dollarIcon from "./../assets/images/dollar.svg";
import expiredIcon from "./../assets/images/expired.png";
import { formatBytes } from "../common/util";
import { COUNTRIES, STATUS } from "../common/const";
import { APP_NAME, PageTitle, SLOGAN } from "../auth";
import {
  DatabaseOutlined,
  InfoCircleOutlined,
  GlobalOutlined,
  RollbackOutlined,
  SearchOutlined,
  DeleteOutlined
} from "@ant-design/icons";
import ic_trash from "../assets/icons/ic-trash.png"

function Recycle() {
  let { id } = useParams();
  const [data, setData] = useState();
  const [refresh, setRefresh] = useState(false);
  const [loading, setLoading] = useState(false);

  const [search, setSearch] = useState();

  const handleConfirm = (id) => {
    Modal.confirm({
      title: "Delete file permanently?",
      content: `Are you sure you want to delete this file forever?`,
      onOk: async () => {
        const subscription = deleteDocs([id]).subscribe((res) => {
          
        if(!data?.message)
        {
            setRefresh(!refresh);
            message.success("delete successful!");
            setLoading(false);
            return () => {
              subscription.unsubscribe();
            };
          }
        });
      },
      onCancel: () => {
        // Handle cancellation if needed
        console.log("Action canceled");
      },
    });
  };
  
  const handleDelete = (ids) => {
    Modal.confirm({
      title: "Delete selected files permanently?",
      content: `Are you sure you want to delete selected files forever?`,
      onOk: async () => {
        const subscription = deleteDocs(ids).subscribe((res) => {
          
        if(!data?.message)
        {
            setRefresh(!refresh);
            message.success("delete selecteds successful!");
            setLoading(false);
            return () => {
              subscription.unsubscribe();
            };
          }
        });
      },
      onCancel: () => {
        // Handle cancellation if needed
        console.log("Action canceled");
      },
    });
  };
  
  const handleRestore = (ids) => {
    Modal.confirm({
      title: "Restore files?",
      content: `Are you sure you want to restore selected files?`,
      onOk: async () => {
        const subscription = restoreDocs(ids).subscribe((res) => {
          
        if(!data?.message)
        {
            setRefresh(!refresh);
            message.success("restore successful!");
            setLoading(false);
            return () => {
              subscription.unsubscribe();
            };
          }
        });
      },
      onCancel: () => {
        // Handle cancellation if needed
        console.log("Action canceled");
      },
    });
  };
  
  const handleClean = () => {
    Modal.confirm({
      title: "Empty all files permanently?",
      content: `Are you sure you want to delete all files forever?`,
      onOk: async () => {
        const subscription = empty().subscribe((res) => {
          
        if(!data?.message)
        {
            setRefresh(!refresh);
            message.success("empty files successful!");
            setLoading(false);
            return () => {
              subscription.unsubscribe();
            };
          }
        });
      },
      onCancel: () => {
        // Handle cancellation if needed
        console.log("Action canceled");
      },
    });
  };
  useEffect(() => {
    setLoading(true);
    
    const subscription = getRecycles(search?.trim()).subscribe((res) => {
      setData(res.items);
      setLoading(false);
      return () => {
        subscription.unsubscribe();
      };
    });
  }, [refresh, search]);
  const columns = [
    {
      title: "File Name",
      dataIndex: "fileName",
      key: "fileName",
    },
    {
      title: "Project Title",
      dataIndex: "projectTitle",
      key: "projectTitle",
    },
    {
      title: "Created On",
      dataIndex: "createdOn",
      key: "createdOn",
      render: (createdOn, record) => (
        <span>{moment.utc(createdOn).local().fromNow()}</span>
      ),
      sorter: (a, b) => moment.utc(a.createdOn).local() - moment.utc(b.createdOn).local(),
    },
    {
      title: "Expired Date",
      dataIndex: "expiredDate",
      key: "expiredDate",
      render: (expiredDate, record) => (
        <span style={{color:"#C1666E"}}><img
        src={expiredIcon}
        style={{
          width: 15,
        }}
      />{" "}{moment.utc(expiredDate).local().fromNow()}</span>
      ),
      sorter: (a, b) => moment.utc(a.expiredDate).local() - moment.utc(b.expiredDate).local(),
    },
    {
      title: "Storage",
      dataIndex: "fileLength",
      key: "fileLength",
      render: (fileLength, record) => (
        <Tag>
          {formatBytes(fileLength, 1) + " "}
          <DatabaseOutlined />
        </Tag>
      ),
      sorter: (a, b) => a.fileLength - b.fileLength,
    },
    {
      title: "Action",
      key: "action",
      render: (text, record) => (
          <Button
            type="link"
            danger
            onClick={() => handleConfirm(record.fileId)}
          >
            <Tooltip title={'PERMANENT DELETE'}>
              {<img src={trashIcon} width={25} />}
            </Tooltip>
          </Button>
      ),
    },
  ];
  const [selectedRowKeys, setSelectedRowKeys] = useState([]);
  
  const onSelectChange = (newSelectedRowKeys) => {
    setSelectedRowKeys(newSelectedRowKeys);
  };
  const rowSelection = {
    selectedRowKeys,
    onChange: onSelectChange,
  };

  const handleKeyPress = (e) => {
    if (e.key === 'Enter') {
      setSearch(e.target.value);
    }
  };

  useEffect(() => {
    document.title = `${PageTitle.Trash} - ${APP_NAME}`;
  }, []); 
  
  return (
    <>
      <Card
        className="tabled"
        title={
          <>
            {<img src={ic_trash} style={{width:30}}/>}{" "}
            <b style={{ verticalAlign: "top" }}>
              {PageTitle.Trash}
            </b>
          </>
        }        
        extra={<Space><Input.Search placeholder="" size="small" onSearch={(value) => setSearch(value)} onPressEnter={handleKeyPress}/>
        {data && <Button type={"danger"} icon={<DeleteOutlined />} onClick={() => handleClean()}>
        Empty trash</Button>} </Space>
        }
      >
        <Row gutter={[24, 0]} style={{ margin: 0 }}>
          <Col xs="24" xl={24}>
            <Row gutter={[24, 0]}>
              <Col xs="24" xl={24}>
                
        <Space>{selectedRowKeys.length>0 &&<Button type={"danger"} icon={<DeleteOutlined />} onClick={() =>handleDelete(selectedRowKeys)}>
        Delete selecteds</Button>}{selectedRowKeys.length>0 &&<Button type={"primary"} icon={<RollbackOutlined />} onClick={() => handleRestore(selectedRowKeys)}>
        Restore selecteds</Button>}</Space>
        <p/>
                <div className="table-responsive">
                  {data && (
                    <Table
                      rowKey={"fileId"}
                      columns={columns}
                      dataSource={data}
                      className="ant-border-space"
                      pagination={{
                        defaultPageSize: 10,
                        showSizeChanger: true,
                        total: data.length,
                        showTotal: (total, [x,y]) => <span>{`${x} - ${y} of ${total}`}</span>
                      }}
                      loading={loading}
                      rowSelection={rowSelection}
                    />
                  )}
                </div>
              </Col>
            </Row>
          </Col>
        </Row>
      </Card>
    </>
  );
}

export default Recycle;
