// AppWrapper.js

import React, { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { authenticationInit } from "./auth";
import App from "./App";
import { getAccessToken } from "./auth";
import { isObservable } from "rxjs";
import { getProfile } from "./services/Profile";
import { setProfile } from "./redux/actions/profileActions";
import { useHistory } from "react-router-dom";

const AppWrapper = () => {
  const history = useHistory();
  const dispatch = useDispatch();
  const [flag, setFlag] = useState(false);
  useEffect(() => {
    const subscription = authenticationInit().subscribe(
      (connect) => {
        if (!isObservable(connect) && getAccessToken()) {
          const profileSubscription = getProfile().subscribe((res) => {
            if (res.uuid) {
              dispatch(setProfile(res));
              setFlag(true);
            } else {
              alert("something wrong happen!");
            }
          });

          return () => {
            profileSubscription.unsubscribe();
          };
        }
      },
      // handle initial login error
      (err) => {
        console.error(err);
        if (!getAccessToken()) {
          history.push("/sign-in");
        }
      }
    );

    return () => {
      subscription.unsubscribe();
    };
  }, [dispatch]);

  if (flag) {
    return <App />;
  } else {
    return <></>;
  }
};

export default AppWrapper;
