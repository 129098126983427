export const SSO_ACCESS_TOKEN = "access_token";
export const SSO_REFRESH_TOKEN = "refresh_token";

export const AUTH_USER_NAME = "auth_user_name";
export const AUTH_USER_EMAIL = "auth_user_email";
export const AUTH_USER_ROLES = "auth_user_roles";
export const AUTH_USER_PREFERRED_USERNAME = "auth_preferred_username";
export const TOKEN_EXPIRE_BEFORE_SECONDS = 300;

export const AUTHORIZATION_CODE = {
  TokenMissing: 0,
  TokenExpired: 1,
  PermissionMissing: 2,
};

export const UserRoles = {
  Trainee: "trainee",
  Contributor: "contributor",
  Leader: "leader",
  Admin: "admin",
};

export const PageTitle = {
  Home: "AI Translator",
  Text: "Text Translator",
  Docs: "Docs Translator",
  User: "Users",
  Project: "My Projects",
  Team: "Users",
  Share: "Shared with me",
  Trash: "Trash",
  Profile: "Profile",
  Department: "Departments"
};

export const APP_NAME = 'AI Translator'

export const SLOGAN = 'Translate Instantly, Connect Effortlessly'
