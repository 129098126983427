// src/services/api.js
import { API_URL } from "../common/config";
import { getAccessToken } from "../auth";
import { message } from "antd";

const makeRequest = async (
  method,
  endpoint,
  data = null,
  customHeaders,
  isMultipart
) => {
  const url = `${API_URL}${endpoint}`;
  let options = {
    method,
    mode: "cors",
    headers: {
      Authorization: `Bearer ${getAccessToken()}`,
      ...customHeaders,
    },
  };
  if (method !== "GET" && !isMultipart) {
    options.headers["Content-Type"] = "application/json";
    options["body"] = data ? JSON.stringify(data) : null;
  } else if (isMultipart) {
    options["body"] = data;
  } else {
  }

  try {
    const response = await fetch(url, options)
    
    if (!response.ok) {
      if(response.status >= 500)
      {
        //window.location.href = "/error/500.html";
      }
      if(response.status === 404)
      {
        //window.location.href = "/error/404.html";
      }
      if(response.status === 401)
      {

      }
      else{
        const error = await response.json();
        throw new Error(error.message);
      }
    }
    if (
      customHeaders &&
      customHeaders["Content-Type"] === "application/octet-stream"
    ) {
      return await response.blob();
    }
    return await response.json();
  } catch (error) {
    message.error(error.toString());
    return {message: error.toString()}
  }
};

export const apiRootGet = (endpoint, customHeaders = {}) =>
  makeRequest("GET", endpoint, null, customHeaders);
export const apiRootPost = (
  endpoint,
  data,
  customHeaders = {},
  isMultipart = false
) => makeRequest("POST", endpoint, data, customHeaders, isMultipart);
export const apiRootPut = (endpoint, data, customHeaders = {}) =>
  makeRequest("PUT", endpoint, data, customHeaders);
export const apiRootDelete = (endpoint, data, customHeaders = {}) =>
  makeRequest("DELETE", endpoint, data, customHeaders);
