import {
  Row,
  Col,
  Card,
  Table,
  Modal,
  message,
  Button,
  Tag,
  Form,
  Spin,
  Select,
  Statistic,
  Tooltip,
  Input,
  Space,
  Typography,
} from "antd";
import { setProfile } from "../redux/actions/profileActions";
import moment from "moment";
import {
  getUsers,
  resetPass,
  updateBudget,
  getBU,
  addNewUser,
  updateUser,
} from "./../services/User";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faUsd, faFileText } from "@fortawesome/free-solid-svg-icons";
import dollarIcon from "./../assets/images/dollar.svg";
import dollarSpendIcon from "./../assets/images/dollar-spend.svg";
import { useHistory } from "react-router-dom";
import { APP_NAME, PageTitle, SLOGAN } from "../auth";
import deleteIcon from "./../assets/images/trashBtn.svg";
import { useDispatch, useSelector } from "react-redux";
import { deleteTeam } from "./../services/Team";
import {
  MailOutlined,
  DollarOutlined,
  LaptopOutlined,
  HistoryOutlined,
  EditOutlined,
  UserOutlined,
} from "@ant-design/icons";
import { useEffect, useState, useRef } from "react";
import ic_user from "../assets/icons/ic-team.png";


// Images
import { ROLE } from "../common/const";
import { switchMap } from "rxjs";
import { getProfile } from "../services/Profile";

const listRole = [
  {
    id: 1,
    role: "Admin",
  },
  {
    id: 2,
    role: "Pro",
  },
  {
    id: 3,
    role: "Basic",
  },
];

const USER_ROLE = {
  Admin: {
    id: 1,
  },
  Pro: {
    id: 2,
  },
  Basic: {
    id: 3,
  },
};

function User() {
  const history = useHistory();
  const [form] = Form.useForm();
  const [editForm] = Form.useForm();
  const [editBudgetForm] = Form.useForm();
  const [budget, setBudget] = useState();
  const [current, setCurrent] = useState();
  const [data, setData] = useState();
  const [refresh, setRefresh] = useState(false);
  const [loading, setLoading] = useState(false);

  const [search, setSearch] = useState();
  const [currentBudgetChange, setCurrentBudgetChange] = useState();
  const [budgetChangeStt, setBudgetChangeStt] = useState();
  const [valueStyleBudgetUpdate, setValueStyleBudgetUpdate] = useState({
    color: "black",
  });
  const dispatch = useDispatch();
  const profile = useSelector((state) => state.profile);
  const role = profile.role?.toString();
  const timeoutRef = useRef(null);

  const validateEmail = (email) => {
    const re =
      /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    return re.test(String(email).toLowerCase());
  };

  useEffect(() => {
    setLoading(true);

    const subscription = getUsers(search?.trim()).subscribe((res) => {
      res.items?.map((item) => (item.children = item.teams));
      setData(res.items);
      setLoading(false);
      return () => {
        subscription.unsubscribe();
      };
    });
  }, [refresh, search]);

  const [bus, setBus] = useState([]);
  const [selectedBu, setSelectedBu] = useState([]);
  useEffect(() => {
    setLoading(true);

    const subscription = getBU().subscribe((res) => {
      setBus(res.items);
      setLoading(false);
      return () => {
        subscription.unsubscribe();
      };
    });
  }, [refresh]);

  useEffect(() => {
    document.title = `${PageTitle.User} - ${APP_NAME}`;
  }, []);

  const sharedColumns = [
    {
      title: "Username",
      dataIndex: "userName",
      key: "userName",
      render: (userName, record, index) => {
        return (
          <>
            {<UserOutlined />}
            <span> {userName}</span>
            <Button
              type="link"
              onClick={() => {
                if (role == USER_ROLE.Admin.id) {
                  editForm.setFieldsValue({
                    fsu: record.fsu,
                    role: record.role,
                  });
                  setCurrent(record.uuid);
                  setModalEditVisible(true);
                }
              }}
              icon={<EditOutlined style={{ color: "gray", fontSize: 11 }} />}
              size={"large"}
              ghost
            ></Button>
          </>
        );
      },
    },
    {
      title: "Email",
      dataIndex: "email",
      key: "email",
      render: (email, record, index) => {
        return (
          email && (
            <Tag
              color="#1F88E5"
              style={{ cursor: "pointer" }}
              onClick={() => (window.location = `mailto:${email}`)}
            >
              <MailOutlined /> {email}
            </Tag>
          )
        );
      },
    },
    {
      title: "FSU",
      dataIndex: "fsu",
      key: "fsu",
      render: (fsu, record, index) => {
        return fsu ? (
          <>
            <LaptopOutlined /> {fsu}
          </>
        ) : (
          <span style={{ color: "#ccc" }}>Not assigned</span>
        );
      },
      sorter: (a, b) => a.fsu.localeCompare(b.fsu),
    },
    {
      title: "Role",
      dataIndex: "role",
      key: "role",
      render: (role, record, index) => {
        return <Tag>{ROLE[role]}</Tag>;
      },
    },
    {
      title: "Credit",
      dataIndex: "budget",
      key: "budget",
      render: (budget, record) => (
        <span className="money">
          <img
            src={dollarIcon}
            style={{
              width: 18,
            }}
          />
          <span>
            {budget?.toLocaleString(undefined, { 
              minimumFractionDigits: 2, 
              maximumFractionDigits: 2  })}
          </span>
        </span>
      ),
      sorter: (a, b) => a.budget - b.budget,
    },
    {
      title: "Spend",
      dataIndex: "spended",
      key: "spended",
      render: (spended, record) => (
        <span className="money-spend">
          -
          <img
            src={dollarSpendIcon}
            style={{
              width: 18,
            }}
          />
          <span>
            {spended?.toLocaleString(undefined, { 
              minimumFractionDigits: 2, 
              maximumFractionDigits: 2  })}
          </span>
        </span>
      ),
      sorter: (a, b) => a.spended - b.spended,
    },
    {
      title: "Update",
      dataIndex: "lastModifiedDate",
      key: "lastModifiedDate",
      render: (lastModifiedDate, record) => (
        <span>{moment.utc(lastModifiedDate).local().fromNow()}</span>
      ),
      sorter: (a, b) =>
        moment.utc(a.lastModifiedDate).local() -
        moment.utc(b.lastModifiedDate).local(),
    },
    {
      title: "Action",
      key: "action",
      render: (text, record) => (
        <>
          { (
            <Button
              type="link"
              className="money"
              onClick={() => {
                setCurrent({ uuid: record.uuid });
                setCurrentBudgetChange();
                setBudget(record.budget);
                setValueStyleBudgetUpdate({ color: "black" });
                setBudgetChangeStt(null);
              }}
            >
              <Tooltip title="BUDGET">
                {<DollarOutlined style={{ fontSize: 20 }} />}
              </Tooltip>
            </Button>
          )}

          <Button
            type="link"
            ghost
            onClick={() =>
              history.push(`/user/history/user/${record.uuid}/${record.email}`)
            }
          >
            <Tooltip title="HISTORY">
              {<HistoryOutlined style={{ fontSize: 19 }} />}
            </Tooltip>
          </Button>

          {role == USER_ROLE.Admin.id && (
            <Button
              type="link"
              danger
              onClick={() => handleConfirm(record.uuid, record)}
            >
              <Tooltip title="DELETE">
                {<img src={deleteIcon} width="25" />}
              </Tooltip>
            </Button>
          )}
        </>
      ),
    },
  ];

  const handleConfirm = (id, record) => {
    Modal.confirm({
      title: "Delete User",
      content: `Are you sure you want to delete this user?`,
      onOk: async () => {
        const subscription = deleteTeam(id).subscribe((res) => {
          if (!data?.message) {
            setRefresh(!refresh);
            setLoading(false);
            return () => {
              subscription.unsubscribe();
            };
          }
        });
      },
      onCancel: () => {},
    });
  };

  const handleResetConfirm = (id, record) => {
    Modal.confirm({
      title: "Reset Password",
      content: `Are you sure you want to reset password for '${record.userName}' user?`,
      onOk: async () => {
        const subscription = resetPass(id).subscribe((res) => {
          if (!res?.message) {
            setRefresh(!refresh);
            message.success("reset password mail already sent!");
            setLoading(false);
            return () => {
              subscription.unsubscribe();
            };
          }
        });
      },
      onCancel: () => {
        console.log("Action canceled");
      },
    });
  };

  const formatBudget = (number) => {
    return number % 1 === 0 ? number.toFixed(0) : number.toFixed(2);
  };

  const handleOnChangeBudget = (e) => {
    if (timeoutRef.current) {
      clearTimeout(timeoutRef.current);
    }
    timeoutRef.current = setTimeout(() => {
      const updateBudget = Number(e.target.value);
      if (updateBudget < 0 || updateBudget > 10000) {
        setCurrentBudgetChange();
        setValueStyleBudgetUpdate({ color: "black" });
        return;
      } else {
        if (updateBudget < budget) {
          setValueStyleBudgetUpdate({ color: "red" });
          setBudgetChangeStt("down");
          setCurrentBudgetChange(formatBudget(budget - updateBudget));
        } else {
          setValueStyleBudgetUpdate({ color: "green" });
          setCurrentBudgetChange(formatBudget(updateBudget - budget));
        }
      }
    }, 800);
  };

  const handleAdd = () => {
    form.submit();
  };
  const handleUpdate = () => {
    editForm.submit();
  };
  const [emails, setEmail] = useState([]);
  const [user, setUser] = useState(null);
  const [modalVisible, setModalVisible] = useState();
  const [modalEditVisible, setModalEditVisible] = useState();
  const handleCancel = () => {
    if (!loading) {
      setEmail([]);
      setUser(null);
      setModalVisible(false);
    }
  };

  const onFinish = (formValues) => {
    const checkError = form.getFieldsError().filter((e) => e.errors.length > 0);
    if (checkError.length === 0) {
      const subscription = addNewUser(
        formValues?.emails,
        formValues.fsu,
        formValues.role
      ).subscribe((res) => {
        if (!res?.message) {
          setRefresh(!refresh);
          message.success(
            "Users are already invited, please check emails for more information!"
          );
          setModalVisible(false);
          setLoading(false);
          return () => {
            subscription.unsubscribe();
          };
        }
      });
      // const subscription = addProUser(
      //   formValues?.emails,
      //   formValues.fsu
      // ).subscribe((res) => {
      //   if (!res?.message) {
      //     setRefresh(!refresh);
      //     message.success(
      //       "users are already invited, please check emails for more informations!"
      //     );
      //     setLoading(false);
      //     return () => {
      //       subscription.unsubscribe();
      //     };
      //   }
      // });
    }
  };

  const onEditFinish = (formValues) => {
    const checkError = editForm
      .getFieldsError()
      .filter((e) => e.errors.length > 0);
    if (checkError.length === 0) {
      const subscription = updateUser(
        current,
        formValues.role,
        formValues.fsu
      ).subscribe((res) => {
        if (!res?.message) {
          setRefresh(!refresh);
          message.success("Update user information successfully!");
        }
        onEditCancel();
        setLoading(false);
        return () => {
          subscription.unsubscribe();
        };
      });
      // const subscription = updateProUser(current, formValues.fsu).subscribe(
      //   (res) => {
      //     if (!res?.message) {
      //       setRefresh(!refresh);
      //       message.success("FSU already changed!");
      //     }
      //     onEditCancel();
      //     setLoading(false);
      //     return () => {
      //       subscription.unsubscribe();
      //     };
      //   }
      // );
    }
  };

  const [editBudgetModalVisible, setEditBudgetModalVisible] = useState(false);

  const handleEditBudgetOk = async () => {
    try {
      await editBudgetForm.validateFields();
      onBudgetSubmit(editBudgetForm.getFieldsValue());
    } catch (error) {
      console.error("Validation Error:", error);
    }
  };

  const onBudgetSubmit = (formValues) => {
    let currentChange = currentBudgetChange;
    if (budgetChangeStt == "down") {
      currentChange = -currentChange;
    }
    const subscription = updateBudget(current.uuid, currentChange)
      .pipe(
        switchMap((res) => {
          if (!res?.message) {
            message.success("Add budget successful!");
            editBudgetForm.resetFields();
            setBudget(0);
            setRefresh(!refresh);
            setEditBudgetModalVisible(false);
            setLoading(false);
            return getProfile(); // Chain the getProfile observable
          } 
        })
      )
      .subscribe(
        (profileRes) => {
          if (profileRes.uuid) {
            dispatch(setProfile(profileRes));
          } else {
            alert("Something went wrong!");
          }
        },
      );
    // Make sure to unsubscribe to avoid memory leaks
    return () => {
      subscription.unsubscribe();
    };
  };

  const handleEditBudgetCancel = () => {
    editBudgetForm.resetFields();
    setBudget(0);
    setCurrent();
    setEditBudgetModalVisible(false);
  };

  useEffect(() => {
    if (current?.uuid !== undefined) {
      setEditBudgetModalVisible(true);
      editBudgetForm.setFieldsValue({ budget: current.budget });
    }
  }, [current]);

  const validatePositiveNumber = (rule, value) => {
    if (value < 0) {
      return Promise.reject("Number must be positive");
    }
    return Promise.resolve();
  };

  const validateRangeNumber = (
    _,
    value,
    min,
    max,
    errorMessage,

  ) => {
    if (value) {
      const number = Number(value);
      if (isNaN(number) || number < min || number > max) {
        return Promise.reject(new Error(errorMessage));
      }
    }
    return Promise.resolve();
  };

  const onEditCancel = () => {
    setModalEditVisible(false);
    setCurrent();
  };
  const handleKeyPress = (e) => {
    if (e.key === "Enter") {
      setSearch(e.target.value);
    }
  };


  return (
    <>
      <Card
        className="tabled"
        title={
          <>
            {<img src={ic_user} width={30} />}{" "}
            <b style={{ verticalAlign: "top" }}>{PageTitle.User}</b>
          </>
        }
        extra={
          <Space>
            <Input.Search
              placeholder=""
              size="small"
              onSearch={(value) => setSearch(value)}
              onPressEnter={handleKeyPress}
            />

            <Button
              type={"primary"}
              onClick={() => setModalVisible(true)}
              icon={<MailOutlined />}
            >
              Invite
            </Button>
          </Space>
        }
      >
        <Row gutter={[24, 0]} style={{ margin: 0 }}>
          <Col xs="24" xl={24}>
            <Row gutter={[24, 0]}>
              <Col xs="24" xl={24}>
                <div className="table-responsive">
                  {data && (
                    <Table
                      rowKey={"id"}
                      columns={sharedColumns}
                      dataSource={data}
                      className="ant-border-space"
                      pagination={{
                        defaultPageSize: 10,
                        showSizeChanger: true,
                        total: data.length,
                        showTotal: (total, [x, y]) => (
                          <span>{`${x} - ${y} of ${total}`}</span>
                        ),
                      }}
                      loading={loading}
                    />
                  )}
                </div>
              </Col>
            </Row>
          </Col>
        </Row>
      </Card>

      <Modal
        className="invite-user-layout"
        title={<span>Invite Users</span>}
        visible={modalVisible}
        onCancel={handleCancel}
        destroyOnClose={true}
        maskClosable={false}
        footer={[
          <Button key="back" onClick={handleCancel} disabled={loading}>
            Cancel
          </Button>,
          <Button
            key="submit"
            type="primary"
            onClick={handleAdd}
            disabled={loading}
          >
            Invite
          </Button>,
        ]}
      >
        <Spin spinning={loading}>
          <Form
            className="invite-user-form"
            preserve={false}
            layout="vertical"
            form={form}
            onFinish={onFinish}
            autoComplete="off"
          >
            <Form.Item
              label={"Emails"}
              name="emails"
              rules={[
                {
                  message: "At least one email required.",
                  type: "array",
                  validator: (_, value) => {
                    if (!value) {
                      return Promise.reject("At least one email required."); // Custom error message for invalid emails
                    }
                    const emailArray = value.map((email) => email.trim());
                    const invalidEmails = emailArray.filter(
                      (email) => !validateEmail(email)
                    );
                    if (invalidEmails.length > 0) {
                      return Promise.reject("Some emails are not valid"); // Custom error message for invalid emails
                    }
                    if (new Set(emailArray).size !== emailArray.length) {
                      return Promise.reject("Duplicate emails."); // Custom error message for invalid emails
                    }
                    return Promise.resolve();
                  },
                },
              ]}
            >
              <Select
                placeholder="Input emails separated by semicolon."
                mode="tags"
                tokenSeparators={[";"]}
              />
            </Form.Item>
            {role === USER_ROLE.Admin.id}
            <Form.Item
              name="role"
              label="Role"
              initialValue={3}
              rules={[
                {
                  required: true,
                  message: "Please select role!",
                },
              ]}
            >
              <Select placeholder="Select role" style={{ width: "100%" }}>
                {listRole &&
                  listRole.map((item, idx) => {
                    return (
                      <Select.Option key={idx} value={item.id}>
                        <Typography.Text>{item.role}</Typography.Text>
                      </Select.Option>
                    );
                  })}
              </Select>
            </Form.Item>
            {role != USER_ROLE.Basic.id}
            <Form.Item label={"Department"} name="fsu">
              <Select
                placeholder="Input/select department."
                style={{ width: "100%" }}
                onChange={(val) => setSelectedBu(val)}
                filterOption={(inputValue, option) =>
                  option.key.toLowerCase().indexOf(inputValue.toLowerCase()) >=
                  0
                }
                showSearch
              >
                {bus &&
                  bus.map((item) => {
                    return (
                      <Select.Option key={item.code} value={item.code}>
                        <LaptopOutlined /> {item.code}
                        <br />
                        <small>
                          <b>{item.title}</b> - {item.description}
                        </small>
                      </Select.Option>
                    );
                  })}
              </Select>
            </Form.Item>
          </Form>
        </Spin>
      </Modal>

      <Modal
        className="invite-user-layout"
        title={<span>Update Users</span>}
        visible={modalEditVisible}
        onCancel={() => onEditCancel()}
        destroyOnClose={true}
        maskClosable={false}
        footer={[
          <Button key="back" onClick={() => onEditCancel()} disabled={loading}>
            Cancel
          </Button>,
          <Button
            key="submit"
            type="primary"
            onClick={handleUpdate}
            disabled={loading}
          >
            Update
          </Button>,
        ]}
      >
        <Spin spinning={loading}>
          <Form
            className="invite-user-form"
            preserve={false}
            layout="vertical"
            form={editForm}
            onFinish={onEditFinish}
            autoComplete="off"
          >
            <Form.Item
              name="role"
              label="Role"
              initialValue={3}
              rules={[
                {
                  required: true,
                  message: "Please select role!",
                },
              ]}
            >
              <Select placeholder="Select role" style={{ width: "100%" }}>
                {listRole &&
                  listRole.map((item, idx) => {
                    return (
                      <Select.Option key={idx} value={item.id}>
                        <Typography.Text>{item.role}</Typography.Text>
                      </Select.Option>
                    );
                  })}
              </Select>
            </Form.Item>
            <Form.Item label={"Department"} name="fsu">
              <Select
                placeholder="Input/select department"
                style={{ width: "100%" }}
                onChange={(val) => setSelectedBu(val)}
                filterOption={(inputValue, option) =>
                  option.key.toLowerCase().indexOf(inputValue.toLowerCase()) >=
                  0
                }
                showSearch
              >
                {bus &&
                  bus.map((item) => {
                    return (
                      <Select.Option key={item.code} value={item.code}>
                        <LaptopOutlined /> {item.code}
                        <br />
                        <small>
                          <b>{item.title}</b> - {item.description}
                        </small>
                      </Select.Option>
                    );
                  })}
              </Select>
            </Form.Item>
          </Form>
        </Spin>
      </Modal>

      <Modal
        title={
          <>
            <DollarOutlined /> Update Credit
          </>
        }
        visible={editBudgetModalVisible}
        onCancel={handleEditBudgetCancel}
        onOk={handleEditBudgetOk}
        okButtonProps={{
          disabled: currentBudgetChange == 0 || !currentBudgetChange,
        }}
        okText={"Update"}
      >
        <Form
          //onFinish={handleEditBudgetSubmit}
          layout="vertical"
          form={editBudgetForm}
          initialValues={current}
        >
          <Row gutter={[20, 20]}>
            <Col span={12}>
              <Form.Item
                name="budget"
                label={
                  <>
                    <FontAwesomeIcon icon={faUsd} /> Budget
                  </>
                }
                rules={[
                  { required: true, message: "Please input budget" },
                  { validator:(rule, value) => validateRangeNumber(rule, value, 0, 10000, "Budget must be between 0 and 10000!" ) },
                ]}
              >
                <Input
                  prefix="$"
                  defaultValue={null}
                  formatter={(value) => `$ ${(+value).toLocaleString()}`}
                  parser={(value) => value.replace(/\$\s?|,/g, "")}
                  style={{
                    width: "100%",
                    margin: 0,
                    padding: "0 8px",
                    borderRadius: "8px",
                  }}
                  className={"money"}
                  onChange={(e) => handleOnChangeBudget(e)}
                  onKeyPress={(e) => {
                    if (!/^\d$/.test(e.key)) {
                      e.preventDefault();
                    }
                  }}
                  min={0}
                  max={10000}
                />
              </Form.Item>
            </Col>
            <Col span={12}>
              <Statistic
                valueStyle={valueStyleBudgetUpdate}
                prefix={`${
                  valueStyleBudgetUpdate.color == "red" ? "-" : "+"
                } $`}
                title={
                  <div style={{height: "24px"}}/>
                    
                  
                }
                value={currentBudgetChange}
              />
            </Col>
          </Row>
        </Form>
      </Modal>
    </>
  );
}

export default User;