import {
  Row,
  Col,
  Card,
  Table,
  Space,
  Tooltip,
  Input,
  Modal,
  Form,
  Button,
  message,
} from "antd";
import { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import moment from "moment";
import { restoreDocs } from "../services/Recycle";
import { getBU, addBU, removeBU, updateBU } from "../services/User";
import trashIcon from "./../assets/images/trashBtn.svg";
import buIcon from "../assets/images/bu.svg";

import {
  InfoCircleOutlined,
  LaptopOutlined,
  EditOutlined,
  PlusOutlined,
  SearchOutlined,
} from "@ant-design/icons";
import { APP_NAME, PageTitle, SLOGAN } from "../auth";
import ic_departments from "../assets/icons/ic-department.png";

function BusinessUnit() {
  let { id } = useParams();
  const [data, setData] = useState();
  const [refresh, setRefresh] = useState(false);
  const [loading, setLoading] = useState(false);
  const [search, setSearch] = useState();

  useEffect(() => {
    document.title = `${PageTitle.Team} - ${APP_NAME}`;
  }, []);

  const handleConfirm = (id) => {
    Modal.confirm({
      title: "Delete department permanently?",
      content: `Are you sure you want to delete this department forever?`,
      onOk: async () => {
        const subscription = removeBU(id).subscribe((res) => {
          if (!data?.message) {
            setRefresh(!refresh);
            message.success("delete successful!");
            setLoading(false);
            return () => {
              subscription.unsubscribe();
            };
          }
        });
      },
      onCancel: () => {
        // Handle cancellation if needed
        console.log("Action canceled");
      },
    });
  };

  const [form] = Form.useForm();
  const [editForm] = Form.useForm();
  const [addVisible, setAddVisible] = useState(false);
  const [editVisible, setEditVisible] = useState(false);
  const handleAdd = () => {
    setAddVisible(true);
  };

  const handleAddCancel = () => {
    setAddVisible(false);
    form.resetFields();
  };

  const handleAddOk = async () => {
    try {
      await form.validateFields();
      onSubmit(form.getFieldsValue());
    } catch (error) {
      console.error("Validation Error:", error);
    }
  };

  const [initialValues, setInitialValues] = useState();
  const handleEditOk = async () => {
    try {
      await editForm.validateFields();
      onEditSubmit(editForm.getFieldsValue());
    } catch (error) {
      console.error("Validation Error:", error);
    }
  };

  const onEditCancel = () => {
    editForm.resetFields();
    setInitialValues();
    setEditVisible(false);
  };

  const onEditSubmit = (formValues) => {
    formValues.id = initialValues.id;
    const subscription = updateBU(formValues).subscribe((res) => {
      if (!res?.message) {
        message.success("Update successful!");
        editForm.resetFields();
        setRefresh(!refresh);
        setEditVisible(false);
        setLoading(false);
        setInitialValues();
        return () => {
          subscription.unsubscribe();
        };
      }
    });
  };

  const onSubmit = (formValues) => {
    const subscription = addBU(formValues).subscribe((res) => {
      if (!res?.message) {
        message.success("Create successful!");
        form.resetFields();
        setRefresh(!refresh);
        setAddVisible(false);
        setLoading(false);
        return () => {
          subscription.unsubscribe();
        };
      }
    });
  };

  const handleAddSubmit = (values) => {
    setAddVisible(false);
  };

  useEffect(() => {
    setLoading(true);

    const subscription = getBU(search).subscribe((res) => {
      setData(res.items);
      setLoading(false);
      return () => {
        subscription.unsubscribe();
      };
    });
  }, [refresh, search]);

  useEffect(() => {
    if (initialValues) {
      setEditVisible(true);
    }
  }, [initialValues]);
  const columns = [
    {
      title: "Code",
      dataIndex: "code",
      key: "code",
      render: (code, record) => (
        <span>
          <LaptopOutlined /> {code}
          <Button
            type="link"
            onClick={() => {
              editForm.setFieldsValue(record);
              setInitialValues(record);
            }}
            icon={<EditOutlined style={{ color: "gray", fontSize: 11 }} />}
            size={"large"}
            ghost
          ></Button>
        </span>
      ),
    },
    {
      title: "Title",
      dataIndex: "title",
      key: "title",
    },
    {
      title: "Description",
      dataIndex: "description",
      key: "description",
    },
    {
      title: "Last Modified",
      dataIndex: "lastModified",
      key: "lastModified",
      render: (lastModified, record) => (
        <span>{moment.utc(lastModified).local().fromNow()}</span>
      ),
      sorter: (a, b) =>
        moment.utc(a.lastModified).local() - moment.utc(b.lastModified).local(),
    },
    {
      title: "Action",
      key: "action",
      render: (text, record) => (
        <Button type="link" danger onClick={() => handleConfirm(record.id)}>
          <Tooltip title={"PERMANENT DELETE"}>
            {<img src={trashIcon} width={25} />}
          </Tooltip>
        </Button>
      ),
    },
  ];
  const [selectedRowKeys, setSelectedRowKeys] = useState([]);

  const onSelectChange = (newSelectedRowKeys) => {
    setSelectedRowKeys(newSelectedRowKeys);
  };
  const rowSelection = {
    selectedRowKeys,
    onChange: onSelectChange,
  };

  return (
    <>
      <Card
        className="tabled"
        title={
          <>
            {<img src={ic_departments} style={{ width: 30 }} />}{" "}
            <b style={{ verticalAlign: "top" }}>{PageTitle.Department}</b>
          </>
        }
        extra={
          data && (
            <Space>
              <Input
                placeholder=""
                prefix={<SearchOutlined />}
                size="small"
                onChange={(event) => setSearch(event.target.value)}
              />
              <Button
                type={"primary"}
                icon={<PlusOutlined />}
                onClick={() => handleAdd()}
              >
                Add Department
              </Button>
            </Space>
          )
        }
      >
        <Row gutter={[24, 0]} style={{ margin: 0 }}>
          <Col xs="24" xl={24}>
            <Row gutter={[24, 0]}>
              <Col xs="24" xl={24}>
                <div className="table-responsive">
                  {data && (
                    <Table
                      rowKey={"id"}
                      columns={columns}
                      dataSource={data}
                      className="ant-border-space"
                      pagination={{
                        defaultPageSize: 10,
                        showSizeChanger: true,
                        total: data.length,
                        showTotal: (total, [x, y]) => (
                          <span>{`${x} - ${y} of ${total}`}</span>
                        ),
                      }}
                      loading={loading}
                    />
                  )}
                </div>
              </Col>
            </Row>
          </Col>
        </Row>
      </Card>

      <Modal
        title="Create Department"
        visible={addVisible}
        onCancel={handleAddCancel}
        onOk={handleAddOk}
      >
        <Form onFinish={handleAddSubmit} layout="vertical" form={form}>
          <Form.Item
            label={"Code"}
            name="code"
            rules={[
              {
                required: true,
                message: "Code is required",
              },
            ]}
          >
            <Input placeholder="Input Department Code." />
          </Form.Item>
          <Form.Item
            label={"Title"}
            name="title"
            rules={[
              {
                required: true,
                message: "Title is required",
              },
            ]}
          >
            <Input placeholder="Input Department Title." />
          </Form.Item>
          <Form.Item
            label={"Description"}
            name="description"
          >
            <Input placeholder="Input Department Description." />
          </Form.Item>
        </Form>
      </Modal>
      <Modal
        title="Edit Department"
        visible={editVisible}
        onCancel={onEditCancel}
        onOk={handleEditOk}
      >
        <Form form={editForm} layout="vertical" initialValues={initialValues}>
          <Form.Item
            label={"Code"}
            name="code"
            rules={[
              {
                required: true,
                message: "Code is required",
              },
            ]}
          >
            <Input placeholder="Input Department Code." />
          </Form.Item>
          <Form.Item
            label={"Title"}
            name="title"
            rules={[
              {
                required: true,
                message: "Title is required",
              },
            ]}
          >
            <Input placeholder="Input Department Title." />
          </Form.Item>
          <Form.Item
            label={"Description"}
            name="description"
          >
            <Input placeholder="Input Department Description." />
          </Form.Item>
        </Form>
      </Modal>
    </>
  );
}

export default BusinessUnit;
