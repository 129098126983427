import { from } from "rxjs";
import { map } from "rxjs/operators";
import { keycloakLogIn, keycloakLogOut, keycloakCheckSSO, checkExpiredToken, refrehToken } from "./keycloak";
import { SSO_ACCESS_TOKEN, SSO_REFRESH_TOKEN } from "./constants";
import { tokenIsExpired } from "./token";

function generateRandomData(len) {
  // use web crypto APIs if possible
  let array = null;
  let crypto = window.crypto;
  if (crypto && crypto.getRandomValues && window.Uint8Array) {
    array = new Uint8Array(len);
    crypto.getRandomValues(array);

    return array;
  }

  // fallback to Math random
  array = new Array(len);
  for (var j = 0; j < array.length; j++) {
    const crypto = window.crypto || window.msCrypto;
    var arr = new Uint32Array(1);
    array[j] = Math.floor(256 * crypto.getRandomValues(arr));
  }

  return array;
}

function generateRandomString(len) {
  const alphabet =
    "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789";
  const randomData = generateRandomData(len);
  const chars = new Array(len);
  for (var i = 0; i < len; i++) {
    chars[i] = alphabet.charCodeAt(randomData[i] % alphabet.length);
  }

  return String.fromCharCode.apply(null, chars);
}

export function checkReloadRequire() {
  const storageKeys = Object.keys(localStorage);

  return storageKeys.some((k) => k.indexOf("kc-callback-") >= 0);
}

export function checkRetryLoginRequire() {
  const storageKeys = Object.keys(localStorage);

  return storageKeys.some((k) => k.indexOf("akc_retry_login") >= 0);
}

export function checkRetryLogout() {
  const storageKeys = Object.keys(localStorage);

  return storageKeys.some((k) => k.indexOf("akc_logout") >= 0);
}

export function retryLogin() {
  localStorage.setItem("akc_retry_login", generateRandomString(16));
  return logOut();
}

export function authenticationInit() {
  
  return from(checkSSO()).pipe(
    map((auth) => {
      if (!auth) {
        return login();
      }
    })
  );
}

export function login() {
  return keycloakLogIn();
}

export function checkSSO() {
  return keycloakCheckSSO();
}

export function logOut() {
  localStorage.setItem("akc_logout", generateRandomString(16));
  return keycloakLogOut();
}
export function getAccessToken() {
  let access_token = localStorage.getItem(SSO_ACCESS_TOKEN)
  let refresh_token = localStorage.getItem(SSO_REFRESH_TOKEN)
  if (checkExpiredToken(access_token) == true) {
    //console.log("access token is expired")
    if (checkExpiredToken(refresh_token) == true) {
      login();
      return localStorage.getItem(SSO_ACCESS_TOKEN); 
    } else {
      refrehToken();
      return localStorage.getItem(SSO_ACCESS_TOKEN);
    }
  } else {
    return localStorage.getItem(SSO_ACCESS_TOKEN);
  }
  
}

export * from "./constants";
export * from "./guards";
